<template>
  <div class="p-4 rounded-lg" v-if="user">
    <h2 class="text-2xl font-semibold mb-4">Správa předplatného</h2>
    <p class="text-gray-600 mb-6">
      Zde můžete spravovat své předplatné a sledovat jeho stav. Své předplatné můžete kdykoliv zrušit.
    </p>

    <!-- Předplatné je aktivní -->
    <div v-if="userActiveSub" class="p-6 bg-green-100 border border-green-400 rounded-lg shadow-lg">
      <h3 class="text-xl font-semibold mb-2 text-green-700">Předplatné je aktivní</h3>
      <p class="text-gray-600 mb-4">Vaše předplatné je aktivní do: <strong>{{ userActiveDateSub }}</strong></p>
      <p class="text-gray-600 mb-4"> Automatické obnovení: <strong v-if="user.subscription_renewal"> aktivní </strong>
        <strong
          v-else> deaktivováno </strong>
      </p>
      <button @click="openManageSubscriptionModal(true)"
              v-if="user.subscription_renewal"
              class="bg-green-600 text-white px-4 py-2 rounded-lg shadow hover:bg-green-500">
        Zrušit předplatné
      </button>
    </div>

    <ModalApp :is-open="manageModal" size="sm" title="Správa předplatného" @close="openManageSubscriptionModal(false)">
      <div class="p-6 space-y-6">
        <!-- Úvodní krok -->
        <template v-if="step === 1">

          <div class="text-center flex justify-center">
            <img src="/images/sad.png" class="w-20 text-center" alt="sad">
          </div>

          <h3 class="text-lg font-medium text-gray-700 text-center uppercase">
            Děkujeme Vám, že jste využili našich služeb!
          </h3>

          <h2 v-if="user.is_trial" class="text-lg text-center">
            Po zrušení předplatného ztratíte okamžitý přístup do aplikace.
          </h2>

          <h2 v-else class="text-xl text-center font-bold">
            Po zrušení předplatného budete mít přístup do aplikace až do {{ userActiveDateSub }}
          </h2>

          <button @click="step = 2"
                  class="w-full mt-6 bg-sky-500 text-white font-semibold py-2 rounded hover:bg-sky-600">
            Pokračovat
          </button>
        </template>

        <!-- Krok s důvodem zrušení -->
        <template v-else-if="step === 2">
          <h3 class="text-lg font-semibold text-gray-900">Proč chcete zrušit předplatné?</h3>
          <div class="space-y-4 mt-4">
            <label class="flex items-center space-x-3">
              <input type="radio" name="reason" value="Nechci již aplikaci využívat"
                     v-model="selectedReason" class="text-sky-500 focus:ring-sky-500">
              <span class="text-gray-800">Nechci již aplikaci využívat</span>
            </label>
            <label class="flex items-center space-x-3">
              <input type="radio" name="reason" value="Aplikace je příliš drahá"
                     v-model="selectedReason" class="text-sky-500 focus:ring-sky-500">
              <span class="text-gray-800">Aplikace je příliš drahá</span>
            </label>
            <label class="flex items-center space-x-3">
              <input type="radio" name="reason" value="Nepoužívám aplikaci dostatečně"
                     v-model="selectedReason" class="text-sky-500 focus:ring-sky-500">
              <span class="text-gray-800">Nepoužívám aplikaci dostatečně</span>
            </label>
            <label class="flex items-center space-x-3">
              <input type="radio" name="reason" value="Nenašel jsem očekávané funkce"
                     v-model="selectedReason" class="text-sky-500 focus:ring-sky-500">
              <span class="text-gray-800">Nenašel jsem očekávané funkce</span>
            </label>
            <label class="flex items-center space-x-3">
              <input type="radio" name="reason" value="Přecházím na jinou aplikaci"
                     v-model="selectedReason" class="text-sky-500 focus:ring-sky-500">
              <span class="text-gray-800">Přecházím na jinou aplikaci</span>
            </label>
          </div>

          <div class="mt-6 flex justify-between">
            <button @click="step = 1" class="text-sky-500 font-semibold hover:underline">
              Zpět
            </button>
            <button @click="step = 3" class="bg-sky-500 text-white font-semibold py-2 px-4 rounded hover:bg-sky-600">
              Další krok
            </button>
          </div>
        </template>

        <!-- Krok s vlastní odpovědí -->
        <template v-else-if="step === 3">
          <h3 class="text-lg font-semibold text-gray-900">Váš názor je pro nás důležitý</h3>
          <p class="text-gray-600 mt-2">
            Pokud nám chcete poskytnout více informací, napište prosím svůj důvod níže. Pomůžete nám
            tak lépe porozumět, co vám chybí nebo co můžeme zlepšit.
          </p>
          <textarea v-model="customReason" rows="4"
                    class="w-full mt-4 p-2 border rounded focus:outline-none focus:ring-2 focus:ring-sky-500"></textarea>

          <div class="mt-6 flex justify-between">
            <button @click="step = 2" class="text-sky-500 font-semibold hover:underline">
              Zpět
            </button>
            <button @click="manageSubscription"
                    class="bg-red-500 text-white font-semibold py-2 px-4 rounded hover:bg-red-600">
              Zrušit předplatné
            </button>
          </div>
          <p class="text-center text-sm">Pokud máte zlevněné předplatné, slevu již nebude možné obnovit. Kliknutím
            na tlačítko "Zrušit předplatné" ukončujete předplatné u aplikace Rilo.cz</p>

        </template>
      </div>
    </ModalApp>
  </div>
</template>

<script>
import {useUserStore} from "@/pinia/userStore";
import moment from "moment";
import ModalApp from "@/components/ModalApp.vue";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";

export default {
  name: "SubscriptionSetting",
  components: {FontAwesomeIcon, ModalApp},

  data() {
    return {
      loading: false,
      manageModal: false,
      step: 1,
      customReason: '',
      selectedReason: 'Nechci již aplikaci využívat'
    }
  },

  computed: {
    user() {
      return useUserStore().user;
    },
    userActiveSub() {
      if (useUserStore().user) {
        return useUserStore().user.active_subscription;
      } else {
        return false;
      }
    },
    userActiveDateSub() {
      if (this.user) {
        return moment(this.user.subscription_created).format('DD.MM.YYYY');
      } else {
        return "";
      }
    }
  },

  methods: {
    async manageSubscription() {
      try {
        const response = await this.$axios.post('/gopay/cancel-subscription', {
          cancel_reason: this.selectedReason,
          cancel_message: this.customReason
        });
        window.location.href = response.data.gw_url;
        this.manageModal = false;
        this.user.fetchUserData();
      } catch (error) {
        console.error('Error redirecting to customer portal:', error);
      }
    },

    openManageSubscriptionModal(condition) {
      this.manageModal = condition;
    }

  },
};
</script>
