<template>
  <div class="w-full">
    <div v-for="(item, index) in localFinancingDetails" :key="index" class="w-full mt-5">
      <div class="flex items-center">
        <h3 class="text-xl pl-2 font-semibold text-gray-800 mr-5" v-if="index === 0 || isMultipleFinancing">
          <font-awesome-icon icon="piggy-bank" size="xs"/>
          {{ item.name }}
        </h3>

        <!-- Přepínač pro více financování -->
        <label class="inline-flex items-center cursor-pointer mr-3" v-if="index === 0">
          <input type="checkbox" :checked="isMultipleFinancing" @change="toggleMultipleFinancing" class="sr-only peer">
          <div
            class="relative w-11 h-6 bg-gray-200 rounded-full peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-sky-600"></div>
        </label>

        <!-- Přidat financování tlačítko -->
        <button
          v-if="index === 0 && isMultipleFinancing"
          @click="addFinancingDetail"
          type="button"
          class="bg-sky-700 hover:bg-sky-800 text-xs flex content-center text-white px-2 py-1 rounded-md"
        >
          Přidat financování
        </button>

        <!-- Odebrat financování tlačítko -->
        <button
          v-else-if="isMultipleFinancing"
          @click="removeFinancingDetail(index)"
          type="button"
          class="text-red-500 hover:text-red-700"
        >
          <font-awesome-icon icon="xmark" size="xs"/>
        </button>
      </div>

      <!-- Obsah financování -->
      <div v-if="isMultipleFinancing"
           class="bg-gray-100 p-4 rounded-lg space-y-4 mt-4">
        <div class="grid grid-cols-2 gap-4">
          <!-- Název financování -->
          <div class="flex space-x-4 items-center w-full" v-if="index !== 0">
            <label class="block text-gray-700 font-semibold w-full">Název financování:
              <input
                v-model="item.name"
                type="text"
                placeholder="Název financování"
                class="mt-1 block font-normal w-full border border-gray-300 rounded-md p-2 focus:ring focus:ring-blue-200"
              >
            </label>
          </div>

          <!-- Celková částka hypotéky -->
          <div v-if="type !== 'template'">
            <label class="block text-gray-700 font-semibold">Hodnota pro banku (Kč):</label>
            <div class="relative">
              <input
                v-model="item.amount"
                @input="onFinancingAmountInput($event, index)"
                type="text"
                placeholder="Např. 2 000 000"
                class="mt-1 block w-full border border-gray-300 rounded-md p-2 pr-12 focus:ring focus:ring-blue-200"
              >
              <span class="absolute inset-y-0 right-0 flex items-center pr-3 text-gray-500">Kč</span>
            </div>
          </div>
          <div v-else>
            <label class="block text-gray-700 font-semibold">Výše úvěru z hodnoty (%):</label>
            <select v-model="item.amount_type"
                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5">
              <option :value="0" selected>Kupní cena nemovitosti</option>
              <option :value="1">Kupní cena + Náklady na pořízení</option>
              <option :value="2">Kupní cena + Pořizovací náklady + Rekonstrukce</option>
              <option :value="3">Hodnota po rekonstrukci</option>
            </select>
          </div>

          <!-- Podíl vlastní investice -->
          <div>
            <label class="block text-gray-700 font-semibold">Kolik si půjčíte (%):</label>
            <div class="relative">
              <input
                v-model="item.investment_percentage"
                type="number"
                placeholder="Např. 20"
                class="mt-1 block w-full border border-gray-300 rounded-md p-2 pr-12 focus:ring focus:ring-blue-200"
              >
              <span class="absolute inset-y-0 right-0 flex items-center pr-3 text-gray-500">%</span>
            </div>
          </div>

          <!-- Doba splácení -->
          <div>
            <label class="block text-gray-700 font-semibold">Doba splácení (roky):</label>
            <div class="relative">
              <input
                v-model="item.loan_term"
                type="number"
                placeholder="Např. 20"
                class="mt-1 block w-full border border-gray-300 rounded-md p-2 pr-12 focus:ring focus:ring-blue-200"
              >
              <span class="absolute inset-y-0 right-0 flex items-center pr-3 text-gray-500">let</span>
            </div>
          </div>

          <!-- Úroková sazba -->
          <div>
            <label class="block text-gray-700 font-semibold">Úroková sazba (%):</label>
            <div class="relative">
              <input
                v-model="item.interest_rate"
                type="number"
                placeholder="Např. 2.5"
                class="mt-1 block w-full border border-gray-300 rounded-md p-2 pr-12 focus:ring focus:ring-blue-200"
              >
              <span class="absolute inset-y-0 right-0 flex items-center pr-3 text-gray-500">%</span>
            </div>
          </div>

          <!-- Doba fixace -->
          <div>
            <label class="block text-gray-700 font-semibold">Doba fixace úrokové sazby</label>
            <div class="relative">
              <input
                v-model="item.change_interest_rate_year"
                type="number"
                placeholder="Např. 5"
                class="mt-1 block w-full border border-gray-300 rounded-md p-2 pr-12 focus:ring focus:ring-blue-200"
              >
              <span class="absolute inset-y-0 right-0 flex items-center pr-3 text-gray-500">let</span>
            </div>
          </div>

          <!-- Úroková sazba po fixaci -->
          <div>
            <label class="block text-gray-700 font-semibold">Úroková sazba po fixaci (%):</label>
            <div class="relative">
              <input
                v-model="item.change_interest_rate"
                type="number"
                placeholder="Např. 2.5"
                class="mt-1 block w-full border border-gray-300 rounded-md p-2 pr-12 focus:ring focus:ring-blue-200"
              >
              <span class="absolute inset-y-0 right-0 flex items-center pr-3 text-gray-500">%</span>
            </div>
          </div>

          <!-- Měsíční pojištění -->
          <div>
            <label class="block text-gray-700 font-semibold">Měsíční pojištění úvěru (%):</label>
            <div class="relative">
              <input
                v-model="item.monthly_insurance"
                type="text"
                placeholder="Např. 3"
                class="mt-1 block w-full border border-gray-300 rounded-md p-2 pr-12 focus:ring focus:ring-blue-200"
              >
              <span class="absolute inset-y-0 right-0 flex items-center pr-3 text-gray-500">%</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FinancingItems',
  props: {
    modelValue: {
      type: Array,
      required: true,
    },
    isMultipleFinancing: {
      type: Boolean,
      required: true
    },
    type: {
      type: String,
      default: "normal",
    }
  },
  data() {
    return {
      localFinancingDetails: [...this.modelValue]
    };
  },
  watch: {
    localFinancingDetails: {
      handler(newVal, oldVal) {
        if (JSON.stringify(newVal) !== JSON.stringify(oldVal)) {
          this.$emit('update:modelValue', newVal);
        }
      },
      deep: true
    },
    modelValue: {
      handler(newVal, oldVal) {
        if (JSON.stringify(newVal) !== JSON.stringify(oldVal)) {
          this.localFinancingDetails = [...newVal];
        }
      },
      deep: true
    }
  },
  methods: {
    toggleMultipleFinancing() {
      this.$emit('update:isMultipleFinancing', !this.isMultipleFinancing);
    },

    addFinancingDetail() {
      this.localFinancingDetails.push({
        name: 'Další financování',
        amount: 0,
        investment_percentage: 0,
        loan_term: 0,
        interest_rate: 0,
        change_interest_rate_year: 0,
        change_interest_rate: 0,
        monthly_insurance: 0
      });
      this.$emit('update:modelValue', [...this.localFinancingDetails]);
    },

    removeFinancingDetail(index) {
      if (this.localFinancingDetails.length > 1) {
        this.localFinancingDetails.splice(index, 1);
        this.$emit('update:modelValue', [...this.localFinancingDetails]);
      }
    },

    onFinancingAmountInput(event, index) {
      const value = event.target.value;
      this.updateLoanAmount(index, value);
      this.$emit('financingAmountEdited');
    },

    updateLoanAmount(index, value) {
      const numericValue = parseInt(value.replace(/\s/g, ''), 10) || 0;
      this.localFinancingDetails[index].amount = numericValue;
      this.$emit('update:modelValue', [...this.localFinancingDetails]);
    },
  }
};
</script>
