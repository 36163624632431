<template>
  <div class="p-4 rounded-lg shadow-md">
    <div class="flex items-center mb-2 min-h-[60px]">
      <h3 class="text-lg font-bold">
        {{ title }}
      </h3>
      <span v-if="info" class="ml-2 text-sky-700 cursor-pointer relative" @click="toggleTooltip"
            @mouseleave="hideTooltip">
        <font-awesome-icon icon="question-circle"/>
        <span v-if="showTooltip"
              class="absolute left-0 bottom-full mb-2 w-48 p-2 bg-gray-800 text-white text-sm rounded-md z-10">
          {{ info }}
        </span>
      </span>
    </div>
    <p class="text-3xl font-semibold">{{ value }}</p>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      required: true,
    },
    info: {
      type: String,
      required: true,
    }
  },
  data() {
    return {
      showTooltip: false,
    };
  },
  methods: {
    toggleTooltip() {
      this.showTooltip = !this.showTooltip;
    },
    hideTooltip() {
      this.showTooltip = false;
    }
  }
}
</script>

<style scoped>
/* Vlastní styly pro komponentu, pokud je potřeba */
</style>
