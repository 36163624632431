<template>
  <div class="p-4 rounded-lg">
    <h2 class="text-2xl font-semibold mb-4">Doklady</h2>
    <p class="text-gray-600 mb-6">
      Zde naleznete přehled všech Vašich dokladů. V případě, že nějaký doklad chybí, kontaktuje nás na podpora@rilo.cz
    </p>
    <div v-if="receipts.length" class="space-y-4">
      <div
        v-for="receipt in receipts"
        :key="receipt.uuid"
        @click="openReceipt(receipt.url)"
        class="flex items-center justify-between p-8 border border-gray-200 rounded-lg hover:bg-gray-100 cursor-pointer transition"
      >
        <div class="flex items-center space-x-4">
          <font-awesome-icon icon="file-invoice" class="text-sky-500 text-2xl mr-5"/>
          <div>
            <h3 class="font-medium text-gray-800">{{ receipt.subject }}</h3>
            <p class="text-sm text-gray-500">Číslo dokladu: {{ receipt.document_number }}</p>
            <p class="text-xs text-gray-400">{{ formatDate(receipt.created_at) }}</p>
          </div>
        </div>
        <font-awesome-icon icon="external-link-alt" class="text-gray-400"/>
      </div>
    </div>
    <div v-else class="text-center text-gray-500">
      <p>Žádné doklady k dispozici</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "DocumentsSetting",
  data() {
    return {
      receipts: []
    };
  },
  methods: {
    async loadReceipts() {
      try {
        const response = await this.$axios.get('/idoklad-receipts');
        this.receipts = response.data.data;
      } catch (error) {
        console.error("Chyba při načítání dokladů:", error);
      }
    },
    openReceipt(url) {
      window.open("https://rilo.b-cdn.net" + url, "_blank");
    },
    formatDate(date) {
      return new Date(date).toLocaleDateString("cs-CZ", {
        year: "numeric",
        month: "long",
        day: "numeric",
      });
    }
  },
  mounted() {
    this.loadReceipts();
  }
};
</script>
