<template>
  <div style="height: 95%; padding-top: 10px;">
    <canvas ref="chartCanvas"></canvas>
  </div>
</template>

<script>
import {
  Chart,
  BarElement,      // Prvek pro zobrazení sloupců
  LinearScale,     // Registrace lineární osy
  CategoryScale,   // Registrace osy x (category)
  Tooltip,
  BarController,// Registrace tooltipu
} from 'chart.js';

Chart.register(
    BarElement,
    BarController,    // Registrace sloupcového grafu
    LinearScale,
    CategoryScale,
    Tooltip
);

export default {
  name: 'MySmallChart',
  props: {
    chartData: {
      type: Object,
      required: true
    },
    chartOptions: {
      type: Object,
      default: () => ({})
    }
  },
  mounted() {
    this.createChart();
  },
  methods: {
    createChart() {
      const ctx = this.$refs.chartCanvas.getContext('2d');
      new Chart(ctx, {
        type: 'bar',
        data: this.chartData,
        options: {
          ...this.chartOptions,
          plugins: {
            tooltip: {
              callbacks: {
                label: function (context) {
                  let value = context.raw;
                  return new Intl.NumberFormat('cs-CZ', {style: 'currency', currency: 'CZK'}).format(value);
                }
              }
            },
            legend: {
              display: false // Skryje legendu
            }
          },
          scales: {
            x: {
              display: false // Zobrazení osy X s popisky
            },
            y: {
              display: false, // Zobrazení osy Y
              ticks: {
                callback: function (value) {
                  return new Intl.NumberFormat('cs-CZ', {style: 'currency', currency: 'CZK'}).format(value);
                }
              }
            }
          },
          elements: {
            bar: {
              borderWidth: 2,
              borderColor: '#0C4A6E',
              backgroundColor: '#0C4A6E', // Barva sloupců
            }
          },
          responsive: true,
          maintainAspectRatio: false,
        }
      });
    }
  }
};
</script>
