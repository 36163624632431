import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import './assets/tailwind.css'
import './assets/app.scss'
import axios from './axios';
import flashMessagePlugin from '@/plugins/flashMessage';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import filter from "@/plugins/filter";
import { createPinia } from 'pinia';

library.add(fas);

const pinia = createPinia();

const app = createApp(App)

app.component('font-awesome-icon', FontAwesomeIcon);

app.use(router)
app.use(flashMessagePlugin)
app.use(filter);
app.use(pinia);

app.mount('#app')

app.config.globalProperties.$axios = axios;
