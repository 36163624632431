<template>
  <div class="p-4 rounded-lg">
    <h2 class="text-2xl font-semibold mb-4">Výpis faktur</h2>
    <p class="text-gray-600 mb-6">
      Zde naleznete výpis všech vašich faktur spojených s využíváním AI služeb.
      Kliknutím na fakturu zobrazíte její detaily.
      <strong> Fakturu vystavujeme nejpozději do tří dnů od uskutečnění platby.</strong>
    </p>

    <div class="space-y-4">
      <!-- Faktura 1 -->
      <a href="/invoice/12345" class="block p-4 bg-white rounded-lg shadow-lg border border-gray-200 hover:bg-gray-50 transition">
        <div class="flex justify-between items-center">
          <div>
            <p class="text-lg font-semibold">Faktura č. 12345</p>
            <p class="text-sm text-gray-500">Vytvořeno: 2023-09-01</p>
            <p class="text-sm text-gray-500">Období: 2023-09-01 - 2023-10-01</p>
          </div>
          <div>
            <p class="text-sm font-semibold"><span class="text-green-600">Zaplaceno</span></p>
            <p class="text-xl font-bold">300 Kč</p>
          </div>
        </div>
      </a>

      <!-- Faktura 2 -->
      <a href="/invoice/12346" class="block p-4 bg-white rounded-lg shadow-lg border border-gray-200 hover:bg-gray-50 transition">
        <div class="flex justify-between items-center">
          <div>
            <p class="text-lg font-semibold">Faktura č. 12346</p>
            <p class="text-sm text-gray-500">Vytvořeno: 2023-08-01</p>
            <p class="text-sm text-gray-500">Období: 2023-08-01 - 2023-09-01</p>
          </div>
          <div>
            <p class="text-sm font-semibold"><span class="text-yellow-600">Nezaplaceno</span></p>
            <p class="text-xl font-bold">600 Kč</p>
          </div>
        </div>
      </a>

      <!-- Faktura 3 -->
      <a href="/invoice/12347" class="block p-4 bg-white rounded-lg shadow-lg border border-gray-200 hover:bg-gray-50 transition">
        <div class="flex justify-between items-center">
          <div>
            <p class="text-lg font-semibold">Faktura č. 12347</p>
            <p class="text-sm text-gray-500">Vytvořeno: 2023-07-01</p>
            <p class="text-sm text-gray-500">Období: 2023-07-01 - 2023-08-01</p>
          </div>
          <div>
            <p class="text-sm font-semibold"><span class="text-red-600">Stornováno</span></p>
            <p class="text-xl font-bold">500 Kč</p>
          </div>
        </div>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: "InvoiceSetting"
}
</script>
