<template>
  <!-- Komponenta se vykreslí pouze tehdy, když nejsou data načítána -->
  <div v-if="!isFetchingData" class="p-2">
    <div v-if="!isLoading && templates.length > 0">
      <p class="mb-6 text-gray-600">
        Vyber si šablonu, kterou chceš použít pro rychlé vyplnění detailů o nemovitosti.
        Stačí zvolit jednu z dostupných šablon a kliknout na tlačítko níže.
      </p>

      <div class="mb-4">
        <label for="templateSelect" class="block mb-2 text-sm font-medium text-gray-900">Vyber šablonu:</label>
        <select id="templateSelect" v-model="selectedTemplateId"
                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5">
          <option value="" disabled selected>Vyber šablonu</option>
          <option v-for="template in templates" :key="template.uuid" :value="template.uuid">
            {{ template.name }}
          </option>
        </select>
      </div>

      <button
        type="button"
        @click.prevent="fillWithTemplate"
        class="text-white bg-sky-500 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-4 py-2"
        :disabled="!selectedTemplateId"
      >
        Vyplnit pomocí šablony
      </button>
    </div>

    <div v-else-if="isLoading" class="flex flex-col items-center justify-center">
      <font-awesome-icon icon="spinner" size="2xl" class="animate-spin text-sky-500 mb-4"/>
      <p class="text-lg font-medium text-gray-700">Vyplňuji údaje...</p>
    </div>

    <div v-else class="text-center">
      <h3 class="text-lg font-semibold text-gray-900 mb-4">Nemáte vytvořené žádné šablony</h3>
      <p class="text-gray-600 mb-6">Pro rychlé vyplňování informací o nemovitostech si vytvořte šablony.</p>

      <router-link
        :to="{ name: 'TemplateSetting' }"
        class="text-white bg-sky-500 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-4 py-2"
      >
        Přejít do nastavení a vytvořit šablonu
      </router-link>
    </div>
  </div>
</template>

<script>
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";

export default {
  name: 'fetchTemplates',
  components: {FontAwesomeIcon},
  data() {
    return {
      templates: [],
      selectedTemplateId: null,
      isLoading: false,       // Stav načítání při vyplňování pomocí šablony
      isFetchingData: true,   // Stav pro načítání šablon, komponenta se vykreslí až po načtení
    };
  },
  mounted() {
    this.fetchTemplates();
  },
  methods: {
    fetchTemplates() {
      this.isFetchingData = true;
      this.$axios.get('/template-properties')
        .then(response => {
          this.templates = response.data;
        })
        .catch(error => {
          console.error('Error fetching templates:', error);
        })
        .finally(() => {
          this.isFetchingData = false;  // Nastaví na false po načtení nebo chybě
        });
    },
    fillWithTemplate() {
      if (this.selectedTemplateId) {
        const selectedTemplate = this.templates.find(template => template.uuid === this.selectedTemplateId);
        if (selectedTemplate) {
          this.isLoading = true;
          this.$emit('fill', selectedTemplate);
          setTimeout(() => {
            this.isLoading = false;
            this.$emit('close');
          }, 2000);
        }
      }
    }
  }
};
</script>
