<template>
  <div>
    <canvas ref="chartCanvas"></canvas>
  </div>
</template>

<script>
import {
  Chart,
  LineElement,
  PointElement,
  LineController,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend,
  Filler
} from 'chart.js';

Chart.register(
    LineElement,
    PointElement,
    LineController,
    CategoryScale,
    LinearScale,
    Tooltip,
    Legend,
    Filler
);

export default {
  name: 'ChartExpectedDevelop',
  props: {
    chartData: {
      type: Object,
      required: true
    },
    chartOptions: {
      type: Object,
      default: () => ({})
    }
  },
  mounted() {
    this.createChart();
  },
  methods: {
    createChart() {
      const ctx = this.$refs.chartCanvas.getContext('2d');
      new Chart(ctx, {
        type: 'line',
        data: this.chartData,
        options: {
          ...this.chartOptions,
          plugins: {
            tooltip: {
              mode: 'index', // Zobrazí hodnoty všech datasetů
              intersect: false, // Zobrazí tooltip i mimo bod
              callbacks: {
                label: function (context) {
                  let value = context.raw;
                  return new Intl.NumberFormat('cs-CZ', {style: 'currency', currency: 'CZK'}).format(value);
                }
              }
            },
            legend: {
              display: false
            }
          },
          scales: {
            x: {
              title: {
                display: true,
                text: 'Roky'
              }
            },
            y: {
              position: 'right',
              ticks: {
                callback: function (value) {
                  return new Intl.NumberFormat('cs-CZ', {style: 'currency', currency: 'CZK'}).format(value);
                }
              }
            }
          },
          elements: {
            line: {
              tension: 0.3
            },
            point: {
              radius: 0
            }
          },
          fill: true,
          responsive: true,
          maintainAspectRatio: false,
        }
      });
    }
  }
};
</script>

<style>
canvas {
  max-width: 100%;
  height: 400px;
}
</style>
