<template>
  <div class="mx-auto p-6 pt-12 bg-gray-100 min-h-screen rounded-lg">
    <div v-if="isLoading" class="text-center text-xl font-bold">
      Načítám data...
    </div>
    <div v-else>
      <!-- Select boxes -->
      <div class="grid grid-cols-1 md:grid-cols-2 gap-6">
        <!-- First select box -->
        <div>
          <h2 class="text-3xl font-extrabold text-gray-800 mb-4">
            Vyberte první nemovitost
          </h2>
          <SelectProperties
              :properties="properties"
              placeholder="Vyberte první nemovitost"
              @property-selected="handleFirstProperty"
          />
        </div>
        <!-- Second select box -->
        <div>
          <h2 class="text-3xl font-extrabold text-gray-800 mb-4">
            Vyberte druhou nemovitost
          </h2>
          <SelectProperties
              :properties="properties"
              placeholder="Vyberte druhou nemovitost"
              @property-selected="handleSecondProperty"
          />
        </div>
      </div>

      <!-- Responsive Horizontal Menu -->
      <div class="mt-6 overflow-x-auto">
        <div class="flex min-w-full space-x-4 px-4 lg:justify-center">
          <div
              v-for="(step, index) in steps"
              :key="index"
              @click="setActiveStep(index)"
              :class="[
              'flex-shrink-0 flex items-center p-4 bg-white shadow cursor-pointer hover:bg-blue-100 transition-colors duration-200',
              { 'border-b-4 border-sky-500': activeStep === index }
            ]"
          >
            <font-awesome-icon :icon="step.icon" class="mr-2" size="lg" />
            <span class="text-lg font-semibold text-gray-700">{{ step.name }}</span>
          </div>
        </div>
      </div>

      <!-- Analysis Components -->
      <div class="grid grid-cols-1 md:grid-cols-2 gap-6 mt-6">
        <!-- First property analysis -->
        <div>
          <div v-if="isLoadingPropertyOne" class="text-center text-lg">
            Načítám první nemovitost...
          </div>
          <div v-else-if="propertyOne">
            <component
                :is="steps[activeStep].component"
                v-model="propertyOne"
                :is-compare="true"
            />
          </div>
        </div>
        <!-- Second property analysis -->
        <div>
          <div v-if="isLoadingPropertyTwo" class="text-center text-lg">
            Načítám druhou nemovitost...
          </div>
          <div v-else-if="propertyTwo">
            <component
                :is="steps[activeStep].component"
                v-model="propertyTwo"
                :is-compare="true"
            />
          </div>
        </div>
      </div>

      <div v-if="error" class="text-red-500 mt-4">{{ error }}</div>
    </div>
  </div>
</template>


<script>
import SelectProperties from "@/components/share/SelectProperties.vue";
import CashFlowAnalysis from "@/components/analysis/CashFlowAnalysis.vue";
import MainAnalysis from "@/components/analysis/MainAnalysis.vue";
import DetailAnalysis from "@/components/analysis/DetailAnalysis.vue";
import ReturnAnalysis from "@/components/analysis/ReturnAnalysis.vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

export default {
  name: "PropertyCompare",
  components: {
    ReturnAnalysis,
    DetailAnalysis,
    MainAnalysis,
    CashFlowAnalysis,
    SelectProperties,
    FontAwesomeIcon,
  },
  data() {
    return {
      properties: [],
      selectedFirstProperty: null,
      selectedSecondProperty: null,
      isLoading: false,
      isLoadingPropertyOne: false,
      isLoadingPropertyTwo: false,
      error: null,
      propertyOne: null,
      propertyTwo: null,
      activeStep: 0,
      steps: [
        { name: "Základní data", component: "MainAnalysis", icon: "chart-pie" },
        {
          name: "Detailní analýza",
          component: "DetailAnalysis",
          icon: "magnifying-glass-chart",
        },
        {
          name: "Vizualizace času",
          component: "ReturnAnalysis",
          icon: "stopwatch",
        },
        { name: "Projekce", component: "CashFlowAnalysis", icon: "calendar" },
      ],
    };
  },
  created() {
    this.fetchProperties();
  },
  methods: {
    async fetchProperties() {
      this.isLoading = true;
      this.error = null;
      try {
        const response = await this.$axios.get("/planned-properties-all");
        this.properties = response.data;
      } catch (error) {
        console.error("Error fetching properties:", error);
        this.error = "Nepodařilo se načíst data.";
      } finally {
        this.isLoading = false;
      }
    },
    async handleFirstProperty(property) {
      this.selectedFirstProperty = property;
      this.isLoadingPropertyOne = true;
      try {
        const response = await this.$axios.get(
            `/planned-properties/${property.uuid}`
        );
        this.propertyOne = response.data;
      } catch (error) {
        console.error("Error loading property data:", error);
        this.error = "Chyba při načítání údajů o první nemovitosti.";
      } finally {
        this.isLoadingPropertyOne = false;
      }
    },
    async handleSecondProperty(property) {
      this.selectedSecondProperty = property.uuid;
      this.isLoadingPropertyTwo = true;
      try {
        const response = await this.$axios.get(
            `/planned-properties/${property.uuid}`
        );
        this.propertyTwo = response.data;
      } catch (error) {
        console.error("Error loading property data:", error);
        this.error = "Chyba při načítání údajů o druhé nemovitosti.";
      } finally {
        this.isLoadingPropertyTwo = false;
      }
    },
    setActiveStep(index) {
      this.activeStep = index;
    },
  },
};
</script>

