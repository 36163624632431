import { defineStore } from 'pinia';
import $axios from "@/axios";
export const useUserStore = defineStore('user', {
    state: () => ({
        user: null,
        credits: 0,
    }),

    actions: {
        async fetchUserData() {
            try {
                const response = await $axios.get('/user');
                this.user = response.data;
            } catch (error) {
                console.error("Error fetching user data:", error);
            }
        },

        async fetchUserCredits() {
            try {
                const response = await $axios.get('/credits');
                this.credits = response.data.credits;  // Assuming the API returns { credits: number }
            } catch (error) {
                console.error("Error fetching user credits:", error);
            }
        },

        async refreshUserCredits() {
            // You can reuse the `fetchUserCredits` method to refresh credits
            await this.fetchUserCredits();
        },
    },
});
