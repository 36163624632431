<template>
  <div class="flex flex-col justify-center items-center min-h-screen w-full bg-white fixed top-0 left-0 z-50 p-8">

    <div class="w-full flex items-center justify-between absolute top-0 px-5 h-16">

      <div v-if="step > 1">
        <font-awesome-icon
            icon="arrow-left"
            class="text-gray-500 hover:text-gray-700 cursor-pointer"
            @click="prevStep"
        />
      </div>
      <div v-else></div>

      <ol class="flex items-center justify-center flex-1 mx-4" style="max-width: 50%;"  :class="{'fade-up show': titleCompleted, 'fade-up': !titleCompleted}">
        <li v-for="(s, index) in steps" :key="index" :class="index < steps.length - 1 ? 'flex items-center flex-1' : 'flex items-center'">
          <div :class="[
                step === index + 1 ? 'bg-sky-500 text-white' : 'bg-gray-100 text-sky-500',
                'flex items-center justify-center w-10 h-10 rounded-full lg:h-12 lg:w-12 shrink-0'
              ]">
            <font-awesome-icon :icon="s.icon" class="w-4 h-4 lg:w-6 lg:h-6" />
          </div>
          <div v-if="index < steps.length - 1" class="flex-auto h-1 border-b-4"
               :class="step > index + 1 ? 'border-sky-500' : 'border-gray-100'"></div>
        </li>
      </ol>


      <button
          :class="{'fade-up show': titleCompleted, 'fade-up': !titleCompleted}"
          class="text-gray-500 hover:text-gray-700 underline"
          @click="saveGuideInfo"
      >
        Přeskočit
      </button>

    </div>

    <div v-if="step === 1" class="w-full max-w-4xl text-center mt-20">
      <transition name="fade">
        <div class="w-full">
          <h1 class="text-7xl font-bold text-gray-900 guide-title mb-10"> {{ typedTitle }}</h1>
          <p class="text-2xl text-gray-500 mb-12 mt-4" :class="{'fade-up show': titleCompleted, 'fade-up': !titleCompleted}">
            Nastavte si Rilo v 9 krocích pro <strong> SUPER </strong> efektivní analýzu!
          </p>
        </div>
      </transition>
    </div>

    <div v-if="step === 2" class="w-full max-w-4xl text-center mt-20">
      <transition name="fade">
       <ProfilePictureSelector />
      </transition>
    </div>

    <div v-if="step === 3" class="w-full max-w-lg text-center mt-20">
      <transition name="fade">
        <EmailStep />
      </transition>
    </div>

    <div v-if="step === 4" class="w-full max-w-xl text-center mt-20">
        <h1 class="text-3xl font-bold text-gray-900 mb-10"> V následujícíh 3. krocích nastavíme Vaše investiční kritéria </h1>
        <p class="text-xl text-gray-500 mb-12 mt-4">
          Upravit je můžete kdykoliv v nastavení!
        </p>
        <div class="w-full">
          <img src="/images/guide-rilo-app.png" alt="rilo-app">
        </div>
    </div>

    <div v-if="step === 5" class="w-full max-w-xl text-center mt-20">
      <div class="mb-8 relative">
        <label for="maxCash" class="block text-left text-xl font-semibold text-gray-700 mb-2">
          Jakou maximální hotovost chcete do nemovitosti investovat?
        </label>
        <p class="text-sm text-gray-500 mb-4 text-left">Zadejte maximální částku, kterou chcete investovat ze svých úspor:</p>

        <div class="relative">
          <input
              id="maxCash"
              v-model="limitsData.cash_value"
              @input="formatAndSetCash('cash_value')"
              type="text"
              placeholder="Např. 3 000 000"
              class="w-full px-4 py-3 pr-12 border border-gray-300 rounded-md shadow-sm text-lg focus:outline-none focus:ring-2 focus:ring-sky-500 focus:border-sky-500"
          />
          <span class="absolute inset-y-0 right-0 flex items-center pr-3 text-gray-500">Kč</span>
        </div>
      </div>
    </div>

    <div v-if="step === 6" class="w-full max-w-xl text-center mt-20">
      <div class="mb-8 relative">
        <label for="maxCash" class="block text-left text-xl font-semibold text-gray-700 mb-2">
          Jaké minimální CashFlow očekáváte v 1. roce investice?
        </label>
        <p class="text-sm text-gray-500 mb-4 text-left">Zadejte minimální CashFlow pro první rok. Pro záporný údaj zadejte znaménko mínus:</p>

        <div class="relative">
          <input
              id="minCashFlow"
              v-model="limitsData.cashflow_value"
              @input="formatAndSetCash('cashflow_value')"
              type="text"
              placeholder="Např. -20 000"
              class="w-full px-4 py-3 pr-12 border border-gray-300 rounded-md shadow-sm text-lg focus:outline-none focus:ring-2 focus:ring-sky-500 focus:border-sky-500"
          />
          <span class="absolute inset-y-0 right-0 flex items-center pr-3 text-gray-500">Kč</span>
        </div>
      </div>
    </div>

    <div v-if="step === 7" class="w-full max-w-xl text-center mt-20">
      <div class="mb-8 relative">
        <label for="maxCash" class="block text-left text-xl font-semibold text-gray-700 mb-2">
          Jakou očekáváte průměrnou roční výnosnost (ROI) své investice za dobu držení?
        </label>
        <p class="text-sm text-gray-500 mb-4 text-left">Např. index S&P500 měl průměrný historický roční výnos 10 %</p>

        <div class="relative">
          <input
              id="maxCash"
              v-model="limitsData.roi_value"
              type="text"
              placeholder="Např. 12"
              class="w-full px-4 py-3 pr-12 border border-gray-300 rounded-md shadow-sm text-lg focus:outline-none focus:ring-2 focus:ring-sky-500 focus:border-sky-500"
          />
          <span class="absolute inset-y-0 right-0 flex items-center pr-3 text-gray-500">%</span>
        </div>
      </div>
    </div>

    <div v-if="step === 8" class="w-full max-w-xl text-center mt-20">
      <h1 class="text-3xl font-bold text-gray-900 mb-10">
        Nainstalujte si Rilo Plugin pro efektivní práci s inzertními portály.
      </h1>
      <a target="_blank" href="https://chromewebstore.google.com/detail/adblock-nejlep%C5%A1%C3%AD-blok%C3%A1tor/gighmmpiobklfepjocnamgkkbiglidom?pli=1" class="cursor-pointer bg-sky-500 max-w-md m-auto text-white px-6 py-3 rounded-lg block text-center hover:bg-sky-700 mb-10">
       <font-awesome-icon icon="square-plus" class="mr-1" />
        Aktivovat plugin
      </a>
      <div class="w-full">
        <img src="/images/guide-rilo-app.png" alt="rilo-app">
      </div>
    </div>

    <div v-if="step === 9" class="w-full max-w-xl text-center mt-20">
      <h1 class="text-3xl font-bold text-gray-900 mb-10">
        Pro efektivní zadávání nemovitostí využijte šablony
      </h1>
      <p class="text-xl text-gray-500 mb-12 mt-4">
        Vytvářet a upravovat je můžete kdykoliv v nastavení!
      </p>
      <div class="w-full">
        <img src="/images/guide-rilo-template.png" alt="rilo-app">
      </div>
    </div>

    <div class="w-full fixed bottom-8 flex justify-center">
      <button
          v-if="step < steps.length"
          @click="nextStep"
          class="bg-sky-500 text-white font-bold py-4 px-8 rounded-lg  transform -translate-x-1/2 hover:bg-sky-700 transition duration-300 w-10/12 max-w-lg"
          :class="{'fade-up show': titleCompleted, 'fade-up': !titleCompleted}"
      >
        Pokračovat
      </button>

      <button
          v-else-if="titleCompleted"
          @click="saveAndContinue"
          class="bg-sky-500 text-white font-bold py-4 px-8 rounded-lg  transform hover:bg-sky-700 transition duration-300 w-10/12 max-w-lg"
      >
        Uložit a vstoupit do aplikace
      </button>
    </div>

  </div>
</template>

<script>
import ProfilePictureSelector from "@/components/setting/ProfilePictureSelector.vue";
import EmailStep from "@/components/guide/steps/EmailStep.vue";
import { useUserStore } from "@/pinia/userStore";

export default {
  data() {
    return {
      step: 1,
      typedTitle: '',
      fullTitle: 'Vítejte v aplikaci Rilo',
      typingSpeed: 50,
      titleCompleted: false,
      limitsData: {
        is_cash_allow: true,
        cash_value: 500000,
        is_cashflow_allow: true,
        cashflow_value: -30000,
        is_roi_allow: true,
        roi_value: 12,
      },
      steps: [
        { icon: 'hands-clapping' },
        { icon: 'user' },
        { icon: 'envelope' },
        { icon: 'not-equal' },
        { icon: 'money-bill' },
        { icon: 'sack-dollar' },
        { icon: 'chart-line' },
        { icon: 'puzzle-piece' },
        { icon: 'file-lines' },
      ],
    };
  },

  mounted() {
    this.typeTitle();
  },

  methods: {
    typeTitle() {
      let index = 0;
      const interval = setInterval(() => {
        if (index < this.fullTitle.length) {
          this.typedTitle += this.fullTitle.charAt(index);
          index++;
        } else {
          clearInterval(interval);
          this.titleCompleted = true;
        }
      }, this.typingSpeed);
    },

    nextStep() {
      if (this.step < this.steps.length) {
        this.step++;
      }
    },

    prevStep() {
      if (this.step > 1) {
        this.step--;
      }
    },

    async saveAndContinue() {
      try {
        await this.saveLimits();
        await this.saveGuideInfo();
      } catch (error) {
        console.error("Chyba při ukládání:", error);
      }
    },

    async saveLimits() {
      const parseNumber = (value) => parseFloat(value.toString().replace(/\s+/g, '').replace(',', '.'));

      const cashValue = parseNumber(this.limitsData.cash_value);
      const cashflowValue = parseNumber(this.limitsData.cashflow_value);
      const roiValue = parseNumber(this.limitsData.roi_value);

      if (isNaN(cashValue) || isNaN(cashflowValue) || isNaN(roiValue)) {
        this.$flashMessage.error("Prosím, zadejte platné číselné hodnoty.");
        return;
      }

      const payload = {
        is_cash_allow: this.limitsData.is_cash_allow,
        cash_value: cashValue,
        is_cashflow_allow: this.limitsData.is_cashflow_allow,
        cashflow_value: cashflowValue,
        is_roi_allow: this.limitsData.is_roi_allow,
        roi_value: roiValue,
      };

      try {
        await this.$axios.put("/criteria", payload);
        this.$flashMessage.success("Nastavení bylo úspěšně uloženo");
      } catch (error) {
        this.$flashMessage.error("Při ukládání nastala chyba");
        console.error("Chyba při ukládání kritérií:", error);
      }
    },

    async saveGuideInfo() {
      try {
        await this.$axios.post("/complete-guide");
        useUserStore().fetchUserData();
      } catch (error) {
        this.$flashMessage.error("Při ukládání nastala chyba");
        console.error("Chyba při ukládání průvodce:", error);
      }
    },

    formatAndSetCash(field) {
      let value = this.limitsData[field].toString().replace(/\s+/g, '');
      const isNegative = value.startsWith('-');
      value = value.replace(/[^0-9.,]/g, '').replace(',', '.');

      if (isNegative) {
        value = '-' + value;
      }

      const numericValue = isNaN(parseFloat(value)) ? '' : parseFloat(value);
      this.limitsData[field] = numericValue !== '' ? this.formatNumber(numericValue) : '';
    },

    formatNumber(value) {
      return new Intl.NumberFormat('cs-CZ', { maximumFractionDigits: 2 }).format(value);
    },
  },

  components: {
    ProfilePictureSelector,
    EmailStep,
  },
};
</script>

<style scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s ease, transform 0.5s ease;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
  transform: translateY(20px);
}

@media (max-width: 640px) {
  .text-5xl {
    font-size: 2.25rem;
  }
  .text-4xl {
    font-size: 1.875rem;
  }
  .w-12 {
    width: 2.5rem;
    height: 2.5rem;
  }
}

.fade-up {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.5s ease, transform 0.5s ease;
}

.fade-up.show {
  opacity: 1;
  transform: translateY(0);
}

</style>
