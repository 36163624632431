<template>
  <div>
    <label class="block text-gray-700 font-semibold">Ostatní náklady spojené s pořízením (Kč):</label>
    <div class="relative">
      <input
        :value="formattedValue"
        @input="updateValue"
        type="text"
        placeholder="Např. 200 000"
        class="mt-1 block w-full border border-gray-300 rounded-md p-2 pr-12 focus:ring focus:ring-blue-200"
      >
      <span class="absolute inset-y-0 right-0 flex items-center pr-3 text-gray-500">Kč</span>
    </div>
    <p class="text-sm text-gray-500">Např. parkovací místo, poplatky...</p>
  </div>
</template>

<script>
export default {
  name: 'AcquisitionCosts',

  props: {
    modelValue: {
      type: Number,
      required: true
    }
  },
  computed: {
    formattedValue() {
      return this.modelValue != null ? this.modelValue.toLocaleString('cs-CZ') : '';
    }
  },
  methods: {
    updateValue(event) {
      const rawValue = event.target.value.replace(/\s/g, '');
      const numericValue = parseInt(rawValue, 10) || 0;
      this.$emit('update:modelValue', numericValue);
    }
  }
};
</script>
