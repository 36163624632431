<template>
  <!-- Náklady na rekonstrukci -->
  <div class="w-full">
    <div class="flex items-center mt-5 mb-2">
      <h3 class="text-xl pl-2 font-semibold text-gray-800 mr-5">
        <font-awesome-icon icon="trowel-bricks" size="xs"/>
        Náklady na rekonstrukci
      </h3>
      <button
        @click="toggleRehabCosts"
        type="button"
        class="bg-sky-700 hover:bg-sky-800 text-xs flex content-center text-white px-2 py-1 rounded-md"
      >
        {{ isMultipleRepair ? 'Nerozepisovat náklady' : 'Rozepsat náklady' }}
      </button>
    </div>
    <div v-if="isMultipleRepair" class="bg-gray-100 p-4 rounded-lg space-y-4">
      <div class="space-y-4">
        <div v-for="(item, index) in localRehabCosts" :key="index" class="flex space-x-4 items-center">
          <input
            v-model="item.name"
            type="text"
            placeholder="Název nákladu"
            class="block w-2/3 border border-gray-300 rounded-md p-2 focus:ring focus:ring-blue-200"
          >
          <div class="relative w-1/3">
            <input
              :value="formatCurrency(item.amount)"
              @input="(event) => updateRehabCost(index, event.target.value)"
              type="text"
              placeholder="Např. 50 000"
              class="mt-1 block w-full border border-gray-300 rounded-md p-2 pr-12 focus:ring focus:ring-blue-200"
            >
            <span class="absolute inset-y-0 right-0 flex items-center pr-3 text-gray-500">Kč</span>
          </div>
          <button
            @click="removeRehabCost(index)"
            type="button"
            class="text-red-500 hover:text-red-700"
          >
            <font-awesome-icon icon="xmark" size="xs"/>
          </button>
        </div>
      </div>
      <button
        @click="addRehabCost"
        type="button"
        class="bg-sky-700 text-sm flex content-center text-white px-4 py-2 rounded-md mt-2"
      >
        <font-awesome-icon icon="plus" size="xs"/>
      </button>
    </div>
    <div v-else>
      <div class="bg-gray-100 p-4 rounded-lg space-y-4">
        <div class="relative">
          <label class="block text-gray-700 font-semibold">Celkové náklady na rekonstrukci:</label>
          <div class="relative">
            <input
              v-model="rehabCostSingle"
              @input="updateRehabCostSingle"
              type="text"
              placeholder="Např. 100 000"
              class="mt-1 block w-full border border-gray-300 rounded-md p-2 pr-12 focus:ring focus:ring-blue-200"
            >
            <span class="absolute inset-y-0 right-0 flex items-center pr-3 text-gray-500">Kč</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RehabCosts',
  props: {
    isMultipleRepair: {
      type: Boolean,
      required: true
    },
    modelValue: {
      type: Array,
      required: true,
      default: () => []
    }
  },
  data() {
    return {
      localRehabCosts: this.modelValue.slice()
    };
  },
  watch: {
    value(newVal) {
      this.localRehabCosts = newVal.slice();
    },
    localRehabCosts: {
      handler(newVal) {
        this.$emit('update:modelValue', newVal);
      },
      deep: true
    }
  },
  computed: {
    rehabCostSingle: {
      get() {
        return this.localRehabCosts[0].amount != null ? this.localRehabCosts[0].amount.toLocaleString('cs-CZ') : '';
      },
      set(value) {
        const numericValue = parseInt(value.replace(/\s/g, ''), 10) || 0;
        this.localRehabCosts[0].amount = numericValue;
      }
    }
  },
  methods: {
    toggleRehabCosts() {
      this.$emit('toggleRehabCosts');
    },
    addRehabCost() {
      this.localRehabCosts.push({name: '', amount: null});
      this.$emit('update:modelValue', [...this.localRehabCosts]);
    },
    removeRehabCost(index) {
      if (this.localRehabCosts.length > 1) {
        this.localRehabCosts.splice(index, 1);
        this.$emit('update:modelValue', [...this.localRehabCosts]); // Emit the entire array after removing a cost
      }
    },
    formatCurrency(value) {
      return value != null ? value.toLocaleString('cs-CZ') : '';
    },
    updateRehabCost(index, value) {
      const numericValue = parseInt(value.replace(/\s/g, ''), 10) || 0;
      this.localRehabCosts[index].amount = numericValue;
      this.$emit('update:modelValue', [...this.localRehabCosts]); // Emit the entire array after updating a cost
    },
    updateRehabCostSingle(event) {
      const value = event.target.value.replace(/\s/g, '');
      this.localRehabCosts[0].amount = parseInt(value, 10) || 0;
      this.$emit('update:modelValue', [...this.localRehabCosts]); // Emit the entire array after updating the single cost
    }
  }
}
</script>
