<template>
  <div class="p-0">
    <div class="mt-2 bg-white p-6 rounded-lg">
      <h2 class="text-2xl font-bold text-sky-700  mb-6">Finanční poměry při nákupu</h2>
      <!-- Finanční poměry (3x2 boxy) -->
      <div :class="isCompare ? 'grid grid-cols-1 sm:grid-cols-2 gap-6' : 'grid grid-cols-1 md:grid-cols-3 gap-6'">
        <detail-box-item
          v-for="(item, index) in detailItems"
          :key="index"
          :title="item.title"
          :value="item.value"
          :info="item.info"
        />
      </div>
    </div>

    <div class="mt-12 bg-white p-6 rounded-lg">
      <h2 class="text-2xl font-bold text-sky-700 mb-6">Nákup a rekonstrukce</h2>
      <!-- Kupní cena -->
      <CostItem
        label="Kupní cena"
        :value="Number(data.purchase_price)"
        :isPositive="true"
        tooltip="Kupní cena je pořizovací cena nemovitosti."
      />

      <!-- Financovaná částka -->
      <CostItem
        label="Financovaná částka"
        :value="Number(financingSum)"
        :isPositive="false"
        tooltip="Toto je částka, kterou financuje banka."
      />

      <!-- Ostatní náklady -->
      <CostItem
        label="Ostatní náklady"
        :value="Number(data.other_initial_costs)"
        :isPositive="true"
        :editable="false"
        tooltip="Ostatní náklady mohou zahrnovat poplatky za právníky nebo např. nákup parkovacího místa."
      />

      <!-- Náklady na rekonstrukci s detaily -->
      <CostItem
        label="Náklady na rekonstrukci"
        :value="Number(allCostsForReconstruction)"
        :isPositive="true"
        :details="data.repair_costs"
        :editable="false"
        tooltip="Součet všech nákladů na rekonstrukci"
      />

      <!-- Celková potřebná hotovost -->
      <CostItem
        label="Celková potřebná hotovost"
        :value="Number(allCashNeeded)"
        :isPositive="true"
        :isFinalSum="true"
        tooltip="Toto je celková hotovost potřebná pro tento projekt."
      />
    </div>

    <div class="mt-12 bg-white p-6 rounded-lg">
      <!-- Peněžní tok (1. rok) -->
      <h2 class="text-2xl font-bold text-sky-700 mb-6">Cash Flow za 1. rok</h2>

      <!-- Hrubý nájem -->
      <CostItem
        label="Hrubý nájem"
        :value="rent"
        :isPositive="true"
        tooltip="Hrubý nájem za celý rok."
      />

      <!-- Neobsazenost -->
      <CostItem
        label="Neobsazenost"
        :value="vacancyLostCash"
        :isPositive="false"
        tooltip="Neobsazenost ovlivňuje ztracené příjmy."
      />

      <!-- Ostatní příjmy -->
      <CostItem
        label="Ostatní příjmy"
        :details="data.other_income"
        :value="otherIncomeSum"
        :isPositive="true"
      />

      <!-- Provozní příjem -->
      <CostItem
        label="Provozní příjem"
        :value="operationalIncome"
        :isPositive="true"
        :isFinalSum="true"
        tooltip="Celkový provozní příjem po zohlednění všech příjmů."
      />

      <!-- Provozní náklady -->
      <CostItem
        label="Provozní náklady"
        :value="costsOutsideFinancing"
        :isPositive="false"
        tooltip="Provozní náklady představují 25,3% z příjmu."
      />

      <!-- Čistý provozní příjem -->
      <CostItem
        label="Čistý provozní příjem"
        :value="operationalIncomeClear"
        :isFinalSum="true"
        tooltip="Čistý provozní příjem po odečtení nákladů."
      />

      <!-- Splátky půjčky -->
      <CostItem
        label="Splátky půjčky"
        :value="financingAllPaymentYear"
        :isPositive="false"
        tooltip="Splátky půjčky na celý rok."
      />

      <!-- Peněžní tok -->
      <CostItem
        label="Peněžní tok"
        :is-final-sum="true"
        :value="cashFlow"
        tooltip="Celkový peněžní tok před zdaněním."
      />

      <!-- Peněžní toky po zdanění -->
      <CostItem
        label="Peněžní toky po zdanění"
        :value="cashFlowWithoutTaxes"
        :isPositive="false"
        tooltip="Peněžní toky po odečtení daní."
      />
    </div>

    <div class="mt-12 bg-white p-6 rounded-lg">
      <h2 class="text-2xl font-bold text-sky-700 mb-6">Financování</h2>

      <div v-if="data.financing && data.financing.length > 0 && data.is_multiple_financing">
        <FinancingItem v-for="finance in data.financing" :key="finance.id" :financing="finance"/>
      </div>
      <div v-else>
        <p class="text-gray-500">Žádné financování není dostupné.</p>
      </div>
    </div>
  </div>
</template>

<script>
import CostItem from "@/components/analysis/CostItem.vue";
import FinancingItem from "@/components/analysis/FinancingItem.vue";
import {useAiDataStore} from "@/pinia/useAiDataStore";
import {InvestmentService} from '@/functions/investmentService.js';
import DetailBoxItem from "@/components/analysis/DetailBoxItem.vue";

export default {
  name: "DetailAnalysis",

  components: {
    FinancingItem,
    CostItem,
    DetailBoxItem
  },

  props: {
    modelValue: {
      type: Object,
      required: true,
    },
    isCompare: {
      type: Boolean,
      default: () => false,
    }
  },

  mounted() {
    const propertyStore = useAiDataStore();

    try {
      propertyStore.saveDataToCategory(
        'analysisCategoryTwo',
        this.data.id,
        'DSCR:' + this.debtCoverageRatio.toFixed(2) + ';' +
        'LTV:' + this.LTV.toFixed(0) + '%;' +
        'GRM:' + this.grossRentMultiplier.toFixed(2) + ';' +
        'Financovaná částka:' + this.financingSum.toFixed(1) + 'Kč;' +
        'Ostatní náklady:' + this.data.other_initial_costs.toFixed(1) + 'Kč;' +
        'Náklady na rekonstrukci:' + this.allCostsForReconstruction.toFixed(1) + 'Kč;' +
        'Celková potřebná hotovost:' + this.allCashNeeded + 'Kč;' +
        'Neobsazenost:' + this.vacancyLostCash.toFixed(1) + 'Kč;' +
        'Ostatní příjmy:' + this.otherIncomeSum.toFixed(1) + 'Kč;' +
        'Provozní příjem:' + this.operationalIncome.toFixed(1) + 'Kč;' +
        'Provozní náklady:' + this.costsOutsideFinancing.toFixed(1) + 'Kč;' +
        'Čistý provozní příjem:' + this.operationalIncomeClear.toFixed(1) + 'Kč;' +
        'Splátky půjčky:' + this.financingAllPaymentYear.toFixed(1) + 'Kč;' +
        'Peněžní tok:' + this.cashFlow.toFixed(1) + 'Kč;' +
        'Peněžní tok po zdanění:' + (this.cashFlow - this.dan()) + 'Kč;'
      );
    } catch (e) {
      console.log(e);
    }
  },

  computed: {
    data() {
      return this.modelValue;
    },

    rent() {
      return InvestmentService.calculateGrossRentForYear(this.data, 1);
    },

    debtCoverageRatio() {
      const financingPayment = this.financingAllPaymentYear;
      if (financingPayment === 0) return 0;
      return this.operationalIncomeClear / financingPayment;
    },

    LTV() {
      if (!this.data.is_multiple_financing) return 0;
      return (this.financingSum / this.data.purchase_price) * 100;
    },

    IRR() {
      if (this.data) {
        return InvestmentService.calculatePropertyIRR(this.data, 1);
      } else {
        return 0;
      }
    },

    vynosDluh() {
      if (this.data) {
        const loanBalance = InvestmentService.calculateLoanBalanceForYear(this.data, 0);
        if (loanBalance === 0) return 0;

        const value = (this.operationalIncomeClear / loanBalance) * 100;
        return parseFloat(value.toFixed(1));
      } else {
        return 0;
      }
    },


    BER() {
      if (this.data) {
        return InvestmentService.calculateBER(this.data, 1);
      } else {
        return 0;
      }
    },


    grossRentMultiplier() {
      return this.data.purchase_price / this.rent;
    },

    financingSum() {
      if (!this.data.is_multiple_financing || !this.data.financing) return 0;

      const financing = this.data.financing;
      let sum = 0;
      financing.forEach(item => {
        sum += (item.amount / 100) * item.investment_percentage;
      });
      return sum;
    },

    costsOutsideFinancing() {
      if (this.data) {
        return InvestmentService.calculateOperatingExpenses(this.data, 1);
      } else {
        return 0;
      }
    },

    operationalIncome() {
      if (this.data) {
        return InvestmentService.calculateTotalIncome(this.data, 1);
      } else {
        return 0;
      }
    },

    operationalIncomeClear() {
      return this.operationalIncome - this.costsOutsideFinancing;
    },

    otherIncomeSum() {
      const otherIncome = this.data.other_income;
      let sum = 0;
      otherIncome.forEach(item => {
        sum += item.amount;
      });
      return sum * 12;
    },

    otherIncomeSumOneMonth() {
      const otherIncome = this.data.other_income;
      let sum = 0;
      otherIncome.forEach(item => {
        sum += item.amount;
      });
      return sum;
    },

    allCostsForReconstruction() {
      const data = this.data.repair_costs;
      let sum = 0;
      data.forEach(item => {
        sum += item.amount;
      });
      return sum;
    },

    allCashNeeded() {
      return this.data.purchase_price - this.financingSum + this.data.other_initial_costs + this.allCostsForReconstruction;
    },

    vacancyLostCash() {
      return (this.data.gross_rent * 12) * (this.data.vacancy_rate / 100);
    },

    financingAllPaymentYear() {
      if (this.data) {
        return InvestmentService.calculateLoanPayments(this.data, 1);
      } else {
        return 0;
      }
    },

    cashFlow() {
      if (this.data) {
        return this.data.cashflow;
      } else {
        return 0;
      }
    },

    cashFlowWithoutTaxes() {
      if (this.data) {
        return InvestmentService.calculateCashFlowAfterTax(this.data, 1);
      } else {
        return 0;
      }
    },

    detailItems() {
      return [
        {
          title: "Míra krytí dluhu (DSCR)",
          value: this.safeToFixed(this.debtCoverageRatio, 2),
          info: "Ukazatel krytí dluhu (DSCR). Čím vyšší hodnota, tím lépe nemovitost pokrývá dluhy. Hodnota nad 1 znamená, že příjem převyšuje splátky, pod 1 signalizuje nedostatek.",
        },
        {
          title: "Úvěr vůči hodnotě (LTV)",
          value: `${this.safeToFixed(this.LTV, 0)} %`,
          info: "Čím nižší LTV, tím menší riziko pro věřitele. Vyšší LTV signalizuje vyšší zadlužení nemovitosti vůči její hodnotě, což může zvýšit náklady na financování a riziko pro investora.",
        },
        {
          title: "Multiplikátor hrubého nájemného (GRM)",
          value: this.safeToFixed(this.grossRentMultiplier, 2),
          info: "Kolik let bude trvat, než se roční nájemné zaplatí cenu nemovitosti. Pouze hrubý ukazatel.",
        },
        {
          title: "Vnitřní míra návratnosti (IRR)",
          value: `${this.safeToFixed(this.IRR, 2)} %`,
          info: "Ukazuje očekávanou roční návratnost investice, zohledňující časovou hodnotu peněz. Bere v úvahu všechny příjmy a výdaje po celou dobu investice.",
        },
        {
          title: "Výnos z dluhu",
          value: `${this.safeToFixed(this.vynosDluh, 2)} %`,
          info: "Poměr, který srovnává roční čistý provozní příjem (NOI) s celkovou částkou úvěru. Tento ukazatel se často používá při posuzování schopnosti nemovitosti generovat příjmy v poměru k dluhu a je důležitý pro kvalifikaci úvěru.",
        },
        {
          title: "Break-Even Ratio (BER)",
          value: `${this.safeToFixed(this.BER, 2)} %`,
          info: "Ukazatel, který měří, jaká minimální obsazenost nemovitosti je potřebná k pokrytí všech provozních nákladů a splátek úvěru. Čím nižší BER, tím lepší finanční situace, protože menší část příjmů z nájemného je potřeba k pokrytí výdajů.",
        },
      ]
    }
  },

  methods: {
    /** Daň */
    dan() {
      let prijmy = this.rent + this.otherIncomeSum;

      // Výpočet, pokud se uplatňuje paušál
      if (this.data.is_flat_tax) {
        let pausalniNaklady = prijmy * 0.3;  // 30 % paušální výdaje
        let zakladDane = prijmy - pausalniNaklady;
        let dan = zakladDane * (this.data.property_tax_rate / 100);  // Sazba daně
        return dan;
      }
      // Výpočet, pokud se paušál neuplatňuje
      else {
        let skutecneNaklady = this.costsOutsideFinancing;  // Skutečné provozní náklady
        let zakladDane = prijmy - skutecneNaklady;  // Základ daně po odečtení skutečných nákladů
        let dan = zakladDane * (this.data.property_tax_rate / 100);  // Sazba daně
        return dan;
      }
    },

    safeToFixed(value, digits = 2) {
      if (value === null || value === undefined || isNaN(value)) {
        return "0.00";
      }
      return Number(value).toFixed(digits);
    },
  },
};
</script>

<style scoped>
/* Přidejte vlastní styly, pokud je potřeba */
</style>
