import { defineStore } from 'pinia';

export const useAiDataStore = defineStore('property', {
    state: () => ({
        analysisCategoryOne: [],
        analysisCategoryTwo: [],
        analysisCategoryThree: [],
        analysisCategoryFour: [],
        analysisCategoryFive: [],
        maxProperties: 5, // Limit pro maximální počet nemovitostí
        propertyQueue: [], // Fronta pro sledování pořadí přidání nemovitostí
    }),

    actions: {
        // Uloží nebo přepíše data nemovitosti v kategorii
        saveDataToCategory(category, id, data) {
            const propertyData = { id, data };

            // Pokud nemovitost není ve frontě, je to nová nemovitost
            if (!this.propertyExistsInQueue(id)) {
                if (this.propertyQueue.length >= this.maxProperties) {
                    // Pokud je překročen limit, odstraň nejstarší nemovitost
                    this.removeOldestProperty();
                }
                // Přidej ID nemovitosti do fronty (na konec fronty)
                this.propertyQueue.push(id);
            }

            // Ulož nebo přepiš data nemovitosti
            const index = this[category].findIndex(item => item.id === id);
            if (index !== -1) {
                // Přepiš existující data
                this[category][index] = propertyData;
            } else {
                // Ulož novou nemovitost
                this[category].push(propertyData);
            }
        },

        // Zjistí, jestli nemovitost existuje ve frontě (napříč kategoriemi)
        propertyExistsInQueue(id) {
            return this.propertyQueue.includes(id);
        },

        // Odstraní nejstarší nemovitost ze všech kategorií
        removeOldestProperty() {
            const oldestId = this.propertyQueue.shift(); // Odstraní první ID z fronty

            // Odstraní nemovitost s daným ID ze všech kategorií
            const categories = [
                this.analysisCategoryOne,
                this.analysisCategoryTwo,
                this.analysisCategoryThree,
                this.analysisCategoryFour,
                this.analysisCategoryFive,
            ];

            categories.forEach(category => {
                const index = category.findIndex(item => item.id === oldestId);
                if (index !== -1) {
                    category.splice(index, 1); // Odstraň nemovitost z kategorie
                }
            });
        },

        // Vrátí kombinovaná data o všech nemovitostech
        getAllCombinedData() {
            const categories = [
                this.analysisCategoryOne,
                this.analysisCategoryTwo,
                this.analysisCategoryThree,
                this.analysisCategoryFour,
                this.analysisCategoryFive,
            ];

            let allDataString = '';

            this.propertyQueue.forEach(id => {
                let propertyDataString = `Nemovitost ID: ${id}, Data: `;

                categories.forEach(category => {
                    const property = category.find(item => item.id === id);
                    if (property) {
                        propertyDataString += `${property.data}`;
                    }
                });

                allDataString += propertyDataString.trim() + ' /// ';
            });

            return allDataString.trim();
        },


        getCombinedDataForProperty(id) {
            const categories = [
                this.analysisCategoryOne,
                this.analysisCategoryTwo,
                this.analysisCategoryThree,
                this.analysisCategoryFour,
                this.analysisCategoryFive,
            ];

            let combinedData = '';
            categories.forEach(category => {
                const property = category.find(item => item.id === id);
                if (property) {
                    combinedData += property.data + ' ';
                }
            });

            return combinedData.trim();
        },

        // Zjistí, jestli nemovitost s daným ID existuje v konkrétní kategorii
        propertyExistsInCategory(category, id) {
            return this[category].some(item => item.id === id);
        },
    },
});
