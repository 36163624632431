<template>
  <div class="p-4 rounded-lg bg-white">
    <!-- Výběr profilového obrázku -->
    <h2 class="text-2xl font-semibold mb-4 text-gray-800">
      Vyberte si profilový obrázek
    </h2>

    <div class="flex items-center w-full mt-6">
      <!-- Tlačítko pro posun doleva -->
      <button
        @click="scrollLeft"
        :disabled="!canScrollLeft"
        class="p-2 focus:outline-none disabled:opacity-50"
        aria-label="Předchozí"
      >
        <svg
          class="w-6 h-6 text-gray-700"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M15 19l-7-7 7-7"
          />
        </svg>
      </button>

      <!-- Slider s avatary -->
      <div
        ref="carousel"
        @scroll="checkScroll"
        class="overflow-x-auto flex space-x-4 w-full px-4 no-scrollbar"
      >
        <div
          v-for="avatar in avatars"
          :key="avatar"
          @click="selectAvatar(avatar)"
          class="flex-shrink-0 cursor-pointer"
        >
          <img
            :src="`/images/avatars/${avatar}.svg`"
            :alt="`Avatar ${avatar}`"
            :class="[
              'w-40 h-40 object-contain rounded-full transition-transform duration-200',
              selectedAvatar === avatar ? 'ring-4 ring-sky-500 scale-95' : 'hover:opacity-75'
            ]"
          />
        </div>
      </div>

      <!-- Tlačítko pro posun doprava -->
      <button
        @click="scrollRight"
        :disabled="!canScrollRight"
        class="p-2 focus:outline-none disabled:opacity-50"
        aria-label="Další"
      >
        <svg
          class="w-6 h-6 text-gray-700"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M9 5l7 7-7 7"
          />
        </svg>
      </button>
    </div>
  </div>
</template>

<script>
import {useUserStore} from '@/pinia/userStore';

export default {
  data() {
    return {
      avatars: [],
      selectedAvatar: 'default',
      canScrollLeft: false,
      canScrollRight: false,
    };
  },

  computed: {
    user() {
      return useUserStore().user;
    }
  },

  async mounted() {
    await this.loadAvatars();
    this.$nextTick(() => {
      this.checkScroll();
    });

    if (this.user && this.user.image_name) {
      this.selectedAvatar = this.user.image_name;
    }
  },
  methods: {
    loadAvatars() {
      this.avatars = Array.from({length: 11}, (_, i) => `avatar-${i + 1}`);
    },

    async selectAvatar(avatar) {
      this.selectedAvatar = avatar;
      try {
        await this.updateUserData({image_name: this.selectedAvatar});
      } catch (error) {
        console.error('Chyba při ukládání avatara:', error);
      }
    },

    async updateUserData(updatedFields) {
      try {
        await this.$axios.put("/user/update", {
          ...this.user,
          ...updatedFields,
        });
        await useUserStore().fetchUserData();
        await this.fetchUser();
      } catch (error) {
        console.error("Chyba při aktualizaci uživatelských dat:", error);
        throw error;
      }
    },

    scrollLeft() {
      this.$refs.carousel.scrollBy({left: -250, behavior: 'smooth'});
    },
    // Posun vpravo
    scrollRight() {
      this.$refs.carousel.scrollBy({left: 250, behavior: 'smooth'});
    },
    // Zkontrolujeme, zda můžeme scrollovat
    checkScroll() {
      const carousel = this.$refs.carousel;
      this.canScrollLeft = carousel.scrollLeft > 0;
      this.canScrollRight = carousel.scrollLeft + carousel.clientWidth < carousel.scrollWidth;
    }
  }
};
</script>

<style scoped>
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
</style>
