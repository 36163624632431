<template>
  <div class="bg-white shadow-sky-400 shadow-sm rounded-lg p-6 mb-6">
    <div class="flex justify-between items-center">
      <h3 class="text-lg font-bold text-sky-500">{{ financing.name }}</h3>
      <span class="font-bold text-sky-500">{{ $formatCurrency(financing.amount * (financing.investment_percentage/100)) }}</span>
    </div>

    <div class="grid grid-cols-2 gap-4 mt-4">
      <div>
        <p class="font-semibold text-gray-700">Půjčka na cenu:</p>
        <p class="text-gray-800">{{ financing.investment_percentage }} %</p>
      </div>

      <div>
        <p class="font-semibold text-gray-700">Úroková sazba:</p>
        <p class="text-gray-800">{{ financing.interest_rate }} %</p>
      </div>

      <div>
        <p class="font-semibold text-gray-700">Typ půjčky:</p>
        <p class="text-gray-800">{{ financing.loan_term }} let</p>
      </div>

      <div>
        <p class="font-semibold text-gray-700">Pojištění hypotéky:</p>
        <p class="text-gray-800">{{ financing.monthly_insurance }} % měsíčně</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    financing: {
      type: Object,
      required: true,
    },
  }
};
</script>

<style scoped>
/* Přidejte případné vlastní styly */
</style>
