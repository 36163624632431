<template>
  <div class="space-y-4">
    <h3 class="text-xl pl-2 font-semibold text-gray-800">
      <font-awesome-icon icon="person-shelter" size="xs"/>
      Dlouhodobé projekce
    </h3>

    <div class="bg-gray-100 px-4 py-8 rounded-lg space-y-8">
      <div v-if="!isFlipCategory">
        <label class="block text-gray-700 font-semibold">Očekávaná doba držení nemovitosti:</label>
        <p class="text-sm text-gray-500">Kolik let chcete tuto nemovitost vlastnit a pronajímat?</p>

        <div class="relative">
          <input
            type="range"
            min="1"
            max="35"
            step="1"
            v-model.number="form.estimated_investment_time"
            class="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer"
          >
          <div class="flex justify-between text-sm text-gray-600 mt-2">
            <span>1 rok</span>
            <span>35 let</span>
          </div>
          <div class="text-center mt-0 text-sky-700 font-semibold text-xl" v-if="form.estimated_investment_time">
            {{ Math.round(form.estimated_investment_time) }} rok{{ getRokSuffix(form.estimated_investment_time) }}
          </div>
        </div>
      </div>

      <!-- Tento blok bude zobrazen pouze při flip strategii -->
      <div v-else>
        <label class="block text-gray-700 font-semibold">Očekávaná doba držení nemovitosti (v měsících):</label>
        <p class="text-sm text-gray-500">Kolik měsíců plánujete nemovitost vlastnit?</p>

        <div class="relative">
          <input
            type="range"
            min="1"
            max="12"
            step="1"
            v-model.number="estimatedInvestmentTimeMonths"
            class="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer"
          >
          <div class="flex justify-between text-sm text-gray-600 mt-2">
            <span>1 měsíc</span>
            <span>12 měsíců</span>
          </div>
          <div class="text-center mt-2 text-sky-700 font-semibold">
            {{ estimatedInvestmentTimeMonths }} měsíc{{ getMesicSuffix(estimatedInvestmentTimeMonths) }}
          </div>
        </div>
      </div>

      <div class="grid grid-cols-1 md:grid-cols-2 gap-8">
        <!-- Očekávaný roční růst hodnoty -->
        <div v-if="!isFlipCategory">
          <label class="block text-gray-700 font-semibold">Očekávaný roční růst hodnoty (%):</label>
          <div class="relative">
            <input
              v-model="form.property_appreciation"
              type="number"
              placeholder="Např. 3.5"
              class="mt-1 block w-full border border-gray-300 rounded-md p-2 pr-12 focus:ring focus:ring-sky-200">
            <span class="absolute inset-y-0 right-0 flex items-center pr-3 text-gray-500">%</span>
          </div>
          <p class="text-sm text-gray-500">Zadejte očekávaný roční růst hodnoty nemovitosti.</p>
        </div>

        <!-- Předpokládaný roční růst nájemného -->
        <div v-if="!isFlipCategory">
          <label class="block text-gray-700 font-semibold">Předpokládaný roční růst nájemného (%):</label>
          <div class="relative">
            <input
              v-model="form.income_growth"
              type="number"
              placeholder="Např. 2"
              class="mt-1 block w-full border border-gray-300 rounded-md p-2 pr-12 focus:ring focus:ring-sky-200">
            <span class="absolute inset-y-0 right-0 flex items-center pr-3 text-gray-500">%</span>
          </div>
          <p class="text-sm text-gray-500">Zadejte předpokládaný roční růst nájemného.</p>
        </div>
      </div>

      <div class="grid grid-cols-1 md:grid-cols-2 gap-8">
        <!-- Předpokládaný roční nárůst nákladů -->
        <div v-if="!isFlipCategory">
          <label class="block text-gray-700 font-semibold">Předpokládaný roční nárůst nákladů (%):</label>
          <div class="relative">
            <input
              v-model="form.expense_growth"
              type="number"
              placeholder="Např. 1.5"
              class="mt-1 block w-full border border-gray-300 rounded-md p-2 pr-12 focus:ring focus:ring-sky-200">
            <span class="absolute inset-y-0 right-0 flex items-center pr-3 text-gray-500">%</span>
          </div>
          <p class="text-sm text-gray-500">Zadejte předpokládaný roční nárůst nákladů.</p>
        </div>

        <!-- Prodejní náklady při prodeji -->
        <div v-if="!isFlipCategory">
          <label class="block text-gray-700 font-semibold">Prodejní náklady při prodeji (%):</label>
          <div class="relative">
            <input
              v-model="form.selling_costs"
              type="number"
              placeholder="Např. 6"
              class="mt-1 block w-full border border-gray-300 rounded-md p-2 pr-12 focus:ring focus:ring-sky-200">
            <span class="absolute inset-y-0 right-0 flex items-center pr-3 text-gray-500">%</span>
          </div>
          <p class="text-sm text-gray-500">Zadejte prodejní náklady při prodeji nemovitosti.</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LongTermProjections",
  props: {
    modelValue: {
      type: Object,
      required: true
    },
    propertyCategory: {
      type: String,
      required: true,
      default: 'rent'
    }
  },
  computed: {
    form: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      }
    },
    isFlipCategory() {
      return this.propertyCategory === 'flip';
    },
    estimatedInvestmentTimeMonths: {
      get() {
        return Math.round(this.form.estimated_investment_time * 12);
      },
      set(value) {
        this.form.estimated_investment_time = value / 12;
      }
    }
  },
  methods: {
    getRokSuffix(number) {
      if (number === 1) return '';
      if (number >= 2 && number <= 4) return 'y';
      return 'ů';
    },
    getMesicSuffix(number) {
      if (number === 1) return '';
      if (number >= 2 && number <= 4) return 'e';
      return 'ů';
    }
  }
}
</script>

<style>
input[type="range"] {
  --tw-ring-color: rgba(14, 165, 233, 0.5); /* sky-500 */
}

input[type="range"]:focus {
  outline: none;
}

input[type="range"]::-webkit-slider-thumb {
  appearance: none;
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background-color: #0ea5e9; /* sky-500 */
  cursor: pointer;
  margin-top: -5px;
}

input[type="range"]::-moz-range-thumb {
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background-color: #0ea5e9; /* sky-500 */
  cursor: pointer;
}

input[type="range"]::-webkit-slider-runnable-track {
  height: 6px;
  background-color: #0284c7; /* sky-700 */
  border-radius: 3px;
}

input[type="range"]::-moz-range-track {
  height: 6px;
  background-color: #0284c7; /* sky-700 */
  border-radius: 3px;
}
</style>
