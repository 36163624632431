<template>
  <div class="p-4 rounded-lg bg-white">
    <!-- Výběr profilového obrázku -->
    <h2 class="text-2xl font-semibold mb-4 text-gray-800">
      Vyberte si profilový obrázek
    </h2>

    <div class="flex items-center w-full mt-6">
      <!-- Tlačítko pro posun doleva -->
      <button
          @click="scrollLeft"
          :disabled="!canScrollLeft"
          class="p-2 focus:outline-none disabled:opacity-50"
          aria-label="Předchozí"
      >
        <svg
            class="w-6 h-6 text-gray-700"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
        >
          <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M15 19l-7-7 7-7"
          ></path>
        </svg>
      </button>

      <!-- Slider s avatary -->
      <div
          ref="carousel"
          @scroll="checkScroll"
          class="overflow-x-auto flex space-x-4 w-full px-4 no-scrollbar"
      >
        <div
            v-for="avatar in avatars"
            :key="avatar"
            @click="selectAvatar(avatar)"
            class="flex-shrink-0 cursor-pointer"
        >
          <img
              :src="`/images/avatars/${avatar}.svg`"
              :alt="`Avatar ${avatar}`"
              :class="[
              'w-40 h-40 object-contain rounded-full transition-transform duration-200',
              selectedAvatar === avatar ? 'ring-4 ring-sky-500 scale-95' : 'hover:opacity-75'
            ]"
          />
        </div>
      </div>

      <!-- Tlačítko pro posun doprava -->
      <button
          @click="scrollRight"
          :disabled="!canScrollRight"
          class="p-2 focus:outline-none disabled:opacity-50"
          aria-label="Další"
      >
        <svg
            class="w-6 h-6 text-gray-700"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
        >
          <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M9 5l7 7-7 7"
          ></path>
        </svg>
      </button>
    </div>

    <!-- Nastavení profilu -->
    <div class="mt-16">
      <h2 class="text-2xl font-semibold mb-4 text-gray-800">
        Nastavení profilu
      </h2>

      <div class="space-y-6">
        <!-- Uživatelské jméno -->
        <div class="flex items-center">
          <label for="username" class="w-1/3 block text-gray-700">
            Vaše uživatelské jméno
          </label>
          <div class="w-2/3 flex items-center">
            <input
                id="username"
                type="text"
                :value="user ? user.name : ''"
                disabled
                class="flex-grow bg-gray-100 border border-gray-300 rounded-md px-4 py-2 focus:outline-none"
            />
            <button @click="openUsernameModal" class="ml-4 text-sky-500 hover:underline">
              změnit
            </button>
          </div>
        </div>

        <!-- E-mail -->
        <div class="flex items-center">
          <label for="email" class="w-1/3 block text-gray-700">
            Váš e-mail
          </label>
          <div class="w-2/3 flex items-center">
            <input
                id="email"
                type="email"
                :value="user ? user.email : ''"
                disabled
                class="flex-grow bg-gray-100 border border-gray-300 rounded-md px-4 py-2 focus:outline-none"
            />
            <button @click="openEmailModal" class="ml-4 text-sky-500 hover:underline">
              změnit
            </button>
            <button
                v-if="user && !user.email_verified"
                @click="openVerificationModal"
                class="ml-4 text-red-500 hover:underline"
            >
              ověřit
            </button>
          </div>
        </div>

        <!-- Změna hesla -->
        <div class="flex items-center">
          <label class="w-1/3 block text-gray-700">Heslo</label>
          <div class="w-2/3">
            <button @click="openPasswordModal" class="text-sky-500 hover:underline">
              Změnit heslo k účtu
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Modální okno pro změnu uživatelského jména -->
    <modal-app
        :is-open="isUsernameModalOpen"
        size="md"
        title="Změna uživatelského jména"
        @close="closeUsernameModal"
    >
      <div class="p-4">
        <label class="block text-gray-700 mb-2">Nové uživatelské jméno</label>
        <input
            v-model="newUsername"
            type="text"
            class="w-full bg-white border border-gray-300 rounded-md px-4 py-2 focus:outline-none focus:ring-2 focus:ring-sky-500"
            :class="{ 'border-red-500': usernameError }"
        />
        <p v-if="usernameError" class="text-red-500 mt-1">{{ usernameError }}</p>
        <div class="mt-4 flex justify-end">
          <button @click="closeUsernameModal" class="mr-2 px-4 py-2 text-gray-700 hover:underline">
            Zrušit
          </button>
          <button
              @click="updateUsername"
              class="px-4 py-2 bg-sky-500 text-white rounded-md hover:bg-sky-600"
              :disabled="loading"
          >
            Uložit
          </button>
        </div>
      </div>
    </modal-app>

    <!-- Modální okno pro změnu e-mailu -->
    <modal-app
        :is-open="isEmailModalOpen"
        size="md"
        title="Změna e-mailu"
        @close="closeEmailModal"
    >
      <div class="p-4">
        <label class="block text-gray-700 mb-2">Nový e-mail</label>
        <input
            v-model="newEmail"
            type="email"
            class="w-full bg-white border border-gray-300 rounded-md px-4 py-2 focus:outline-none focus:ring-2 focus:ring-sky-500"
            :class="{ 'border-red-500': emailError }"
        />
        <p v-if="emailError" class="text-red-500 mt-1">{{ emailError }}</p>
        <div class="mt-4 flex justify-end">
          <button @click="closeEmailModal" class="mr-2 px-4 py-2 text-gray-700 hover:underline">
            Zrušit
          </button>
          <button
              @click="changeEmail"
              class="px-4 py-2 bg-sky-500 text-white rounded-md hover:bg-sky-600"
              :disabled="loading"
          >
            Uložit
          </button>
        </div>
        <p v-if="errorMessage" class="text-red-500 mt-4 text-center">{{ errorMessage }}</p>
        <p v-if="successMessage" class="text-green-500 mt-4 text-center">{{ successMessage }}</p>
      </div>
    </modal-app>

    <!-- Modální okno pro ověření e-mailu -->
    <modal-app
        :is-open="isVerificationModalOpen"
        size="md"
        title="Ověření e-mailu"
        @close="closeVerificationModal"
    >
      <div class="p-4">
        <h2 class="text-xl font-semibold mb-4 text-center">Ověřte svůj e-mail</h2>
        <p class="mb-4 text-center text-gray-600">
          Zadejte ověřovací kód, který jsme vám zaslali na <strong>{{ user.email }}</strong>.
        </p>

        <div class="flex space-x-2 mb-6 justify-center">
          <input
              v-for="(code, index) in codeInputs"
              :key="index"
              ref="inputs"
              type="text"
              maxlength="1"
              class="w-12 h-12 border border-gray-300 rounded-md text-center text-2xl focus:outline-none focus:ring-2 focus:ring-sky-500"
              v-model="codeInputs[index]"
              @input="onInput(index)"
              @keydown.backspace="onBackspace($event, index)"
              :aria-label="`Kód číslo ${index + 1}`"
          />
        </div>

        <div class="flex justify-center">
          <button
              @click="submitCode"
              class="px-4 py-2 bg-sky-500 text-white rounded-md hover:bg-sky-600"
              :disabled="!isCodeComplete || loading"
          >
            Odeslat kód
          </button>
        </div>

        <p v-if="errorMessage" class="text-red-500 mt-4 text-center">{{ errorMessage }}</p>
        <p v-if="successMessage" class="text-green-500 mt-4 text-center">{{ successMessage }}</p>

        <p class="mt-5 text-sm text-center text-gray-600">
          Nepřišel Vám e-mail?
          <a href="#" class="text-sky-600 underline" @click.prevent="resendCode"
          >Zkuste znovu odeslat kód.</a
          >
          <br />
          V případě přetrvávajících potíží nás kontaktujte na
          <a href="mailto:podpora@rilo.cz" class="text-sky-600 underline">podpora@rilo.cz</a>
        </p>
      </div>
    </modal-app>

    <!-- Modální okno pro změnu hesla -->
    <modal-app
        :is-open="isPasswordModalOpen"
        size="md"
        title="Změna hesla"
        @close="closePasswordModal"
    >
      <div class="p-4">
        <label class="block text-gray-700 mb-2">Původní heslo*</label>
        <input
            v-model="passwordForm.oldPassword"
            type="password"
            class="w-full bg-white border border-gray-300 rounded-md px-4 mb-5 py-2 focus:outline-none focus:ring-2 focus:ring-sky-500"
            :class="{ 'border-red-500': passwordError }"
        />
        <label class="block text-gray-700 mb-2">Nové heslo*</label>
        <input
            v-model="passwordForm.newPassword"
            type="password"
            class="w-full bg-white border border-gray-300 rounded-md px-4 mb-5 py-2 focus:outline-none focus:ring-2 focus:ring-sky-500"
            :class="{ 'border-red-500': passwordError }"
        />
        <label class="block text-gray-700 mb-2">Nové heslo znovu*</label>
        <input
            v-model="passwordForm.newPasswordAgain"
            type="password"
            class="w-full bg-white border border-gray-300 rounded-md px-4 mb-5 py-2 focus:outline-none focus:ring-2 focus:ring-sky-500"
            :class="{ 'border-red-500': passwordError }"
        />
        <p v-if="passwordError" class="text-red-500 mt-1">{{ passwordError }}</p>
        <div class="mt-4 flex justify-end">
          <button @click="closePasswordModal" class="mr-2 px-4 py-2 text-gray-700 hover:underline">
            Zrušit
          </button>
          <button
              @click="changePassword"
              class="px-4 py-2 bg-sky-500 text-white rounded-md hover:bg-sky-600"
              :disabled="loading"
          >
            Změnit heslo
          </button>
        </div>
        <p v-if="passwordSuccessMessage" class="text-green-500 mt-4 text-center">
          {{ passwordSuccessMessage }}
        </p>
      </div>
    </modal-app>
  </div>
</template>

<script>
import { useUserStore } from "@/pinia/userStore";
import ModalApp from "@/components/ModalApp.vue";

export default {
  name: "ProfileSetting",
  components: { ModalApp },
  data() {
    return {
      avatars: [],
      selectedAvatar: "",
      user: null,
      canScrollLeft: false,
      canScrollRight: false,
      isUsernameModalOpen: false,
      isEmailModalOpen: false,
      isVerificationModalOpen: false,
      isPasswordModalOpen: false,
      newUsername: "",
      newEmail: "",
      passwordForm: {
        oldPassword: "",
        newPassword: "",
        newPasswordAgain: "",
      },
      codeInputs: ["", "", "", "", "", ""],
      errorMessage: "",
      successMessage: "",
      usernameError: "",
      emailError: "",
      passwordError: "",
      passwordSuccessMessage: "",
      loading: false,
    };
  },
  computed: {
    isCodeComplete() {
      return this.codeInputs.every((input) => input !== "");
    },
    isValidEmail() {
      const re =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([^<>()[\]\\.,;:\s@"]+\.)+[^<>()[\]\\.,;:\s@"]{2,})$/i;
      return re.test(this.newEmail);
    },
  },
  mounted() {
    this.loadAvatars();
    this.fetchUser();
    this.$nextTick(() => {
      this.checkScroll();
    });
  },
  methods: {
    loadAvatars() {
      this.avatars = Array.from({ length: 11 }, (_, i) => `avatar-${i + 1}`);
    },
    async fetchUser() {
      try {
        const response = await this.$axios.get("/user");
        this.user = response.data;
        this.selectedAvatar = this.user.image_name || "default";
      } catch (error) {
        console.error("Chyba při načítání uživatele:", error);
      }
    },
    async selectAvatar(avatar) {
      this.selectedAvatar = avatar;
      try {
        await this.updateUserData({ image_name: this.selectedAvatar });
      } catch (error) {
        console.error("Chyba při aktualizaci avatara:", error);
      }
    },
    scrollLeft() {
      this.$refs.carousel.scrollBy({ left: -250, behavior: "smooth" });
    },
    scrollRight() {
      this.$refs.carousel.scrollBy({ left: 250, behavior: "smooth" });
    },
    checkScroll() {
      const carousel = this.$refs.carousel;
      this.canScrollLeft = carousel.scrollLeft > 0;
      this.canScrollRight = carousel.scrollLeft + carousel.clientWidth < carousel.scrollWidth;
    },
    // Modální okna
    openUsernameModal() {
      this.newUsername = this.user ? this.user.name : "";
      this.usernameError = "";
      this.isUsernameModalOpen = true;
    },
    closeUsernameModal() {
      this.isUsernameModalOpen = false;
    },
    async updateUsername() {
      if (!this.newUsername) {
        this.usernameError = "Prosím zadejte uživatelské jméno.";
        return;
      }
      this.loading = true;
      this.usernameError = "";
      try {
        await this.updateUserData({ name: this.newUsername });
        this.closeUsernameModal();
      } catch (error) {
        this.usernameError = "Chyba při aktualizaci uživatelského jména.";
      } finally {
        this.loading = false;
      }
    },
    openEmailModal() {
      this.newEmail = this.user ? this.user.email : "";
      this.emailError = "";
      this.errorMessage = "";
      this.successMessage = "";
      this.isEmailModalOpen = true;
    },
    closeEmailModal() {
      this.isEmailModalOpen = false;
    },
    async changeEmail() {
      if (!this.isValidEmail) {
        this.emailError = "Prosím zadejte platný e-mail.";
        return;
      }
      this.loading = true;
      this.emailError = "";
      this.errorMessage = "";
      this.successMessage = "";
      try {
        const response = await this.$axios.post("/change-email", {
          new_email: this.newEmail,
        });
        this.successMessage = response.data.message || "E-mail úspěšně změněn!";
        await useUserStore().fetchUserData();
        await this.fetchUser();
      } catch (error) {
        if (error.response && error.response.data && error.response.data.message) {
          this.errorMessage = error.response.data.message;
        } else {
          this.errorMessage = "Chyba při změně e-mailu. Zkuste to prosím znovu.";
        }
      } finally {
        this.loading = false;
      }
    },
    openVerificationModal() {
      this.codeInputs = ["", "", "", "", "", ""];
      this.errorMessage = "";
      this.successMessage = "";
      this.isVerificationModalOpen = true;
    },
    closeVerificationModal() {
      this.isVerificationModalOpen = false;
    },
    onInput(index) {
      const currentInput = this.codeInputs[index];
      if (!/^\d$/.test(currentInput)) {
        this.codeInputs[index] = currentInput.replace(/\D/g, "");
      }
      if (this.codeInputs[index] && index < this.codeInputs.length - 1) {
        this.$refs.inputs[index + 1].focus();
      }
    },
    onBackspace(event, index) {
      if (event.key === "Backspace" && !this.codeInputs[index] && index > 0) {
        this.$refs.inputs[index - 1].focus();
      }
    },
    async submitCode() {
      if (!this.isCodeComplete) return;
      this.loading = true;
      this.errorMessage = "";
      this.successMessage = "";
      const verificationCode = this.codeInputs.join("");
      try {
        await this.$axios.post("/verify-email", {
          verification_code: verificationCode,
        });
        this.successMessage = "E-mail úspěšně ověřen!";
        await useUserStore().fetchUserData();
        await this.fetchUser();
        this.closeVerificationModal();
      } catch (error) {
        if (error.response && error.response.data && error.response.data.message) {
          this.errorMessage = error.response.data.message;
        } else {
          this.errorMessage = "Chyba při ověřování kódu. Zkuste to prosím znovu.";
        }
      } finally {
        this.loading = false;
      }
    },
    async resendCode() {
      this.loading = true;
      this.errorMessage = "";
      this.successMessage = "";
      try {
        const response = await this.$axios.post("/resend-verification-code");
        this.successMessage = response.data.message || "Nový ověřovací kód byl odeslán na váš e-mail.";
      } catch (error) {
        if (error.response && error.response.data && error.response.data.message) {
          this.errorMessage = error.response.data.message;
        } else {
          this.errorMessage = "Chyba při odesílání kódu. Zkuste to prosím znovu.";
        }
      } finally {
        this.loading = false;
      }
    },
    openPasswordModal() {
      this.passwordForm = {
        oldPassword: "",
        newPassword: "",
        newPasswordAgain: "",
      };
      this.passwordError = "";
      this.passwordSuccessMessage = "";
      this.isPasswordModalOpen = true;
    },
    closePasswordModal() {
      this.isPasswordModalOpen = false;
    },
    async changePassword() {
      const { oldPassword, newPassword, newPasswordAgain } = this.passwordForm;

      if (!oldPassword || !newPassword || !newPasswordAgain) {
        this.passwordError = "Všechna pole jsou povinná.";
        return;
      }

      if (newPassword.length < 8) {
        this.passwordError = "Nové heslo musí mít alespoň 8 znaků.";
        return;
      }

      if (newPassword !== newPasswordAgain) {
        this.passwordError = "Nová hesla se neshodují.";
        return;
      }

      this.loading = true;
      this.passwordError = "";
      this.passwordSuccessMessage = "";

      try {
        const response = await this.$axios.post("/change-password", {
          old_password: oldPassword,
          new_password: newPassword,
          new_password_confirmation: newPasswordAgain,
        });
        this.passwordSuccessMessage = response.data.message || "Heslo úspěšně změněno!";
        this.passwordForm = {
          oldPassword: "",
          newPassword: "",
          newPasswordAgain: "",
        };
      } catch (error) {
        if (error.response && error.response.data && error.response.data.message) {
          this.passwordError = error.response.data.message;
        } else {
          this.passwordError = "Chyba při změně hesla. Zkuste to prosím znovu.";
        }
      } finally {
        this.loading = false;
      }
    },
    async updateUserData(updatedFields) {
      try {
        await this.$axios.put("/user/update", {
          ...this.user,
          ...updatedFields,
        });
        await useUserStore().fetchUserData();
        await this.fetchUser();
      } catch (error) {
        console.error("Chyba při aktualizaci uživatelských dat:", error);
        throw error;
      }
    },
  },
};
</script>

<style scoped>
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
</style>
