<template>
  <div class="mb-4">
    <div :class="['flex', 'justify-between', 'items-center', { 'border-t pt-4': isFinalSum }, {'border-b-1' : offSign}]">
      <div class="flex items-center relative">
        <!-- Tooltip Icon -->
        <span v-if="tooltip" class="mr-2 text-sky-700 cursor-pointer" @click="toggleTooltip" @mouseleave="hideTooltip">
          <font-awesome-icon icon="question-circle" />
          <div v-if="showTooltip" class="absolute left-0 bottom-full mb-2 w-48 p-2 bg-gray-800 text-white text-sm rounded-md">
            {{ tooltip }}
          </div>
        </span>

        <!-- Label -->
        <span :class="['font-semibold', isFinalSum ? 'text-sky-500' : 'text-gray-700']">
          {{ label }}:
        </span>
      </div>

      <div class="text-right flex items-center">
        <!-- Value with +/- -->
        <span :class="valueClass">
          <span v-if="!offSign">{{ positiveControl ? '+' : '-' }}</span> {{ formatCurrency(Math.abs(value)) }}
        </span>

        <!-- Edit Icon (pokud je editable) -->
        <span v-if="editable" class="ml-2 text-sky-700 cursor-pointer">
          <font-awesome-icon icon="edit" />
        </span>

        <!-- Expand/Collapse Icon (pokud jsou detaily) -->
        <span v-if="details && details.length > 1" @click="toggleDetails" class="ml-2 text-sky-700 cursor-pointer">
          <font-awesome-icon :icon="detailsVisible ? 'chevron-up' : 'chevron-down'" />
        </span>
      </div>
    </div>

    <!-- Detaily -->
    <div v-if="detailsVisible" class="ml-6 mt-2">
      <div v-for="(item, index) in details" :key="index" class="flex justify-between items-center text-gray-500 mb-2">
        <span>– {{ item.name }}</span>
        <span>{{ formatCurrency(item.amount) }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CostItem",
  props: {
    label: {
      type: String,
      required: true,
    },
    value: {
      type: Number,
      required: true,
    },
    isPositive: {
      type: Boolean,
      required: false,
      default: null,
    },
    tooltip: {
      type: String,
      default: '',
    },
    details: {
      type: Array,
      default: () => [],
    },
    isFinalSum: {
      type: Boolean,
      default: false,
    },
    editable: {
      type: Boolean,
      default: false,
    },
    offSign: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showTooltip: false,
      detailsVisible: false,
    };
  },
  computed: {
    positiveControl(){
      if(this.isPositive === null){
        return this.value >= 0;
      }else{
        return this.isPositive;
      }
    },
    valueClass() {
      if(!this.offSign){
        if (this.isFinalSum) {
          return ['font-bold', 'text-sky-500'];
        }
        return [
          'font-semibold',
          this.positiveControl ? 'text-gray-800' : 'text-red-500'
        ];
      }
      return 'font-semibold'
    },
  },
  methods: {
    toggleTooltip() {
      this.showTooltip = !this.showTooltip;
    },
    hideTooltip() {
      this.showTooltip = false;
    },
    toggleDetails() {
      this.detailsVisible = !this.detailsVisible;
    },
    formatCurrency(value) {
      return new Intl.NumberFormat('cs-CZ', {style: 'currency', currency: 'CZK'}).format(value);
    },
  },
};
</script>

<style scoped>
  .border-b-1{
    border-bottom-width: 1px;
  }
</style>
