<template>
  <div @click="this.$router.push({name: 'AnalysisPage', params:{ id: property.uuid }})"
       style="transition: none"
       class="cursor-pointer relative single-property bg-white rounded-lg shadow-md grid grid-cols-1 md:grid-cols-[1fr_2fr] items-stretch  hover:shadow-lg mt-4">
    <!-- Image Section -->
    <div class="relative w-full overflow-hidden bg-gray-100 rounded-l-lg" style="    aspect-ratio: 2 / 1;">
      <img v-if="property.image_url" :src="'https://rilo.b-cdn.net/' + property.image_url"
           alt="Property Image"
           width="500"
           height="500"
           class="w-full h-full object-cover rounded-l-lg"
      >
      <img v-else src="https://rilo.b-cdn.net/static/blank-img.png"
           alt="Property Image"
           class="w-full h-full object-cover rounded-l-lg">

      <div class="absolute top-2 left-2 flex flex-col space-y-1">
        <div class="flex items-center flex-wrap gap-1">
          <button @click.stop.prevent="toggleTagPopup"
                  class="focus:outline-none mr-1 bg-gray-50 hover:bg-gray-200 rounded-3xl">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                 class="scale-50">
              <g clip-path="url(#clip0_1_3)">
                <path
                  d="M10.773 21.585L9.405 23L-1 12.571V4H1V11.719L10.773 21.585ZM12.772 1H3V10.772L15.074 23L25 13.15L12.772 1ZM8 8C6.895 8 6 7.105 6 6C6 4.895 6.895 4 8 4C9.105 4 10 4.895 10 6C10 7.105 9.105 8 8 8Z"
                  fill="#000"/>
              </g>
              <defs>
                <clipPath id="clip0_1_3">
                  <rect width="24" height="24" fill="white"/>
                </clipPath>
              </defs>
            </svg>
          </button>
          <span
            v-for="tag in property.tags"
            :key="tag.uuid"
            :style="{ backgroundColor: tag.color }"
            class="bg-orange-500 text-white text-xs px-2 py-1 rounded-lg shadow cursor-pointer"
            @click.stop.prevent="$emit('select-tag', tag.uuid)"
          >
            {{ tag.name }}
          </span>
        </div>
      </div>
    </div>

    <!-- Property Data -->
    <div class="flex-1 flex flex-col px-4 w-full">
      <div class="flex justify-between items-center pb-2 border-b border-gray-200">
        <div class="flex justify-between lg:space-x-0 space-x-2 md:w-5/6 w-full pt-4">
          <div class="flex items-center ml-2">
            <template v-if="criteriaCashFlowAllow">
              <img src="/images/icons/check.png" alt="check" class="w-7 h-7 mr-2" v-if="criteriaCashFlow">
              <img src="/images/icons/not-check.png" alt="check" class="w-7 h-7 mr-2" v-else>
            </template>

            <div class="flex-1">
              <span class="font-semibold block leading-none md:text-lg text-sm" style="line-height: 1"
                    :class="{
                    'text-green-600': criteriaCashFlow && criteriaCashFlowAllow,
                    'text-red-600': !criteriaCashFlow && criteriaCashFlowAllow,
                    'text-sky-700': !criteriaCashFlowAllow
                  }"
                    v-if="property.cashflow"
              >{{ formatCurrency(safeToFixed(property.cashflow, 2)) }} Kč</span>
              <span class="md:text-lg text-sm font-semibold block leading-none text-sky-700" style="line-height: 1"
                    v-else
              > --- </span>
              <span class="text-xs text-gray-400 leading-none">Cash Flow pro 1. rok</span>
            </div>
          </div>

          <div class="flex items-center">
            <template v-if="criteriaCashAllow">
              <img src="/images/icons/check.png" alt="check" class="w-7 h-7 mr-2" v-if="criteriaCash">
              <img src="/images/icons/not-check.png" alt="check" class="w-7 h-7 mr-2" v-else>
            </template>

            <div class="flex-1">
            <span class="md:text-lg text-sm font-semibold text-gray-600 block leading-none" style="line-height: 1"
                  :class="{
                  'text-green-600': criteriaCash && criteriaCashAllow,
                  'text-red-600': !criteriaCash && criteriaCashAllow,
                  'text-sky-700': !criteriaCashAllow
                }"
                  v-if="property.cash_required"
            >{{ formatCurrency(safeToFixed(property.cash_required)) }} Kč </span>
              <span class="md:text-lg text-sm font-semibold block leading-none text-sky-700" style="line-height: 1"
                    v-else
              > --- </span>
              <span class="text-xs text-gray-400">Potřebná hotovost</span>
            </div>
          </div>

          <div class="flex items-center mr-2">
            <template v-if="criteriaRoiAllow">
              <img src="/images/icons/check.png" alt="check" class="w-7 h-7 mr-2" v-if="criteriaRoi">
              <img src="/images/icons/not-check.png" alt="check" class="w-7 h-7 mr-2" v-else>
            </template>
            <div class="flex-1">
            <span class="md:text-lg text-sm font-semibold text-gray-600 block leading-none" style="line-height: 1"
                  :class="{
                    'text-green-600': criteriaRoi && criteriaRoiAllow,
                    'text-red-600': !criteriaRoi && criteriaRoiAllow,
                    'text-sky-700': !criteriaRoiAllow
                  }"
                  v-if="property.average_annual_valuation"
            >{{ safeToFixed(property.average_annual_valuation) }} %</span>
              <span class="md:text-lg text-sm font-semibold block leading-none text-sky-700" style="line-height: 1"
                    v-else
              > --- </span>
              <span class="text-xs text-gray-400">⌀ zhodnocení p.a.</span>
            </div>
          </div>
        </div>
        <!-- Dropdown Menu for Actions -->
        <div class="absolute top-0 right-0">
          <div class="relative">
            <button @click.stop.prevent="toggleDropdown" ref="button"
                    class="rounded-3xl w-6 h-6 mt-2 ml-2 mr-2 shadow-lg hover:text-sky-600 text-gray-900">
              <font-awesome-icon icon="ellipsis" size="lg"/>
            </button>
            <!-- Dropdown content -->
            <div v-if="dropdownOpen" ref="dropdown"
                 class="absolute bottom-0 lg:left-0 right-0 w-48 bg-white border border-gray-200 rounded-lg shadow-lg z-50"
                 style="transform: translateY(100%);">
              <ul class="py-2">
                <li class="mb-1hover:bg-gray-100 transition-colors">
                  <router-link @click.stop :to="'/edit-plan/' + property.uuid"
                               class="flex items-center px-4 py-2 hover:bg-gray-100 transition-colors w-full h-full text-sm text-gray-700 hover:text-gray-500">
                    <font-awesome-icon icon="edit" class="mr-2"/>
                    Upravit
                  </router-link>
                </li>
                <li class=" hover:bg-gray-100 transition-colors">
                  <button @click.stop.prevent="openModalDeleteProperty()"
                          class="flex items-center px-4 py-2 hover:bg-gray-100 transition-colors w-full h-full text-sm text-gray-700 hover:text-gray-500">
                    <font-awesome-icon icon="trash" class="mr-2"/>
                    Odstranit
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>

      </div>

      <div class="flex">
        <div class="mt-2 pt-2 pb-4 lg:pl-5 w-2/3">
          <h3 class="md:text-lg font-semibold text-gray-800 text-xs">
            {{ property.name }}
            <span class="text-gray-400 mx-2">•</span>
            {{ property.disposition }}
            <template v-if="property.square_meters">
              <span class="text-gray-400 mx-2">•</span>
              {{ property.square_meters }} m<sup>2</sup>
            </template>
          </h3>
          <p class="text-sm text-gray-500 mt-1  lg:visible "> {{ property.address }}</p>
          <h4 class="lg:text-xl text-sm font-bold text-gray-800 mt-2">
            {{ formatCurrency(property.purchase_price) }} Kč

            <template v-if="property.linked_url">
              <a @click.stop
                 :href="property.linked_url" target="_blank" rel="noopener noreferrer"
                 class="ml-2 inline-flex items-center justify-center w-7 h-7 rounded-full bg-white text-gray-700 hover:text-gray-600 shadow hover:bg-gray-50 transition">
                <font-awesome-icon icon="link" size="xs"/>
              </a>
            </template>
          </h4>
        </div>
        <div class="w-1/3 max-h-full" style="max-height: 150px">
          <MySmallChart :chartData="chartData" :chartOptions="chartOptions"/>
        </div>
      </div>
    </div>

    <!-- Popup for Tags -->
    <div v-if="isTagPopupOpen" @click.stop ref="tagPopup"
         class="absolute left-0 top-6 mt-2 w-48 bg-white border rounded-lg shadow-lg z-50 p-4">
      <h3 class="text-lg font-bold mb-2">Přiřadit štítky</h3>
      <ul>
        <li v-for="tag in allTags" :key="tag.uuid" class="flex items-center justify-between mb-2">
        <span :style="{ backgroundColor: tag.color }" class="px-2 py-1 text-sm text-white rounded">
        {{ tag.name }}
      </span>
          <input
            type="checkbox"
            @click.stop
            class="w-4 h-4 text-gray-50 bg-sky-700 accent-sky-300	 border-gray-300 rounded"
            :checked="propertyTags.some(t => t.uuid === tag.uuid)"
            @change="toggleTag(tag.uuid)"
          />
        </li>
      </ul>
    </div>


  </div>
  <DeleteConfirm
    :is-visible="isModalDeleteOpen"
    @close="closeModalDeleteProperty"
    @confirm="confirmDelete"
    message="Opravdu si přejete odstranit tuto nemovitost?"
  />
</template>

<script>
import DeleteConfirm from "@/components/share/DeleteConfirm.vue";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import MySmallChart from '@/components/MySmallChart.vue';
import {usePropertyStore} from "@/pinia/usePropertyStore";

export default {
  name: "SingleProperty",

  components: {FontAwesomeIcon, DeleteConfirm, MySmallChart},

  props: {
    property: {
      type: Object,
      required: true
    },
    criteria: {
      type: Object,
      required: true,
    }
  },

  emits: ['delete', 'selectTag', 'tagChange'],

  computed: {
    propertyTags() {
      return this.property.tags;
    },

    criteriaCashAllow() {
      return this.criteria && Boolean(this.criteria.is_cash_allow) && this.criteria.cash_value !== null;
    },

    criteriaCashFlowAllow() {
      return this.criteria && Boolean(this.criteria.is_cashflow_allow) && this.criteria.cashflow_value !== null;
    },

    criteriaRoiAllow() {
      return this.criteria && Boolean(this.criteria.is_roi_allow) && this.criteria.roi_value !== null;
    },

    criteriaYieldAllow() {
      return this.criteria && Boolean(this.criteria.is_yield_allow) && this.criteria.yield_value !== null;
    },

    criteriaCash() {
      return (this.property.cash_required < this.criteria.cash_value);
    },

    criteriaCashFlow() {
      return (this.property.cashflow > this.criteria.cashflow_value);
    },

    criteriaRoi() {
      return (this.property.average_annual_valuation > this.criteria.roi_value);
    },
  },

  async mounted() {
    document.addEventListener('click', this.closePopups);
    this.chartData.datasets[0].data = this.property.data_cash_flow;
    this.showChart = true;
  },

  beforeUnmount() {
    document.removeEventListener('click', this.closePopups);
  },

  data() {
    return {
      allTags: [],
      dropdownOpen: false,
      isTagPopupOpen: false,
      isModalDeleteOpen: false,
      showChart: false,
      chartData: {
        labels: ['5.let', '10.let', '15.let', '20.let', '25.let', '30.let'],
        datasets: [{
          data: [0, 0, 0, 0, 0],
          borderColor: '#0C4A6E',
          backgroundColor: '#0C4A6E',
          fill: false
        }]
      },
      chartOptions: {
        responsive: true
      }
    };
  },

  methods: {
    formatCurrency(amount) {
      return new Intl.NumberFormat('cs-CZ').format(amount);
    },

    safeToFixed(value, digits = 2) {
      if (value === null || value === undefined || isNaN(value)) {
        return "0.00";
      }
      return Number(value).toFixed(digits);
    },

    closePopups(event) {
      if (this.isTagPopupOpen && this.$refs.tagPopup && !this.$refs.tagPopup.contains(event.target)) {
        this.isTagPopupOpen = false;
      }

      if (this.dropdownOpen && !this.$refs.dropdown.contains(event.target) && !this.$refs.button.contains(event.target)) {
        this.dropdownOpen = false;
      }
    },


    async toggleDropdown() {
      this.dropdownOpen = !this.dropdownOpen;
    },

    async toggleTagPopup(event) {
      event.stopPropagation();
      this.isTagPopupOpen = !this.isTagPopupOpen;
      if (this.isTagPopupOpen) {
        this.fetchTags();
      }
    },

    async fetchTags() {
      try {
        const response = await this.$axios.get('/tags');
        this.allTags = response.data;
      } catch (error) {
        console.error('Error fetching tags:', error);
      }
    },
    async toggleTag(tagId) {
      if (this.propertyTags.some(tag => tag.uuid === tagId)) {
        await this.detachTag(tagId);
      } else {
        await this.attachTag(tagId);
      }
      this.$emit('tagChange');
    },
    async attachTag(tagId) {
      try {
        await this.$axios.post('/tags/attach', {
          property_uuid: this.property.uuid,
          tag_uuid: tagId,
        });
      } catch (error) {
        console.error('Error attaching tag:', error);
      }
      this.$emit('tagChange');
    },
    async detachTag(tagId) {
      try {
        await this.$axios.post('/tags/detach', {
          property_uuid: this.property.uuid,
          tag_uuid: tagId,
        });
        this.propertyTags = this.propertyTags.filter(uuid => uuid !== tagId);
      } catch (error) {
        console.error('Error detaching tag:', error);
      }
    },

    openModalDeleteProperty() {
      this.isModalDeleteOpen = true;
    },

    closeModalDeleteProperty() {
      this.isModalDeleteOpen = false;
    },

    confirmDelete() {
      this.$emit('delete', this.property.uuid);
      this.isModalDeleteOpen = false;
    }

  }
};
</script>

<style scoped>
.single-property {
  background-color: #ffffff;
  transition-duration: 2s;


  img {
    transition-duration: 2s;

  }
}

.single-property:hover {
  background-color: rgba(0, 187, 255, 0.06); /* Slightly darker on hover */
}

.mx-2 {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

</style>
