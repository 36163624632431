<template>
  <div class="space-y-4">
    <h3 class="text-xl pl-2 font-semibold text-gray-800">Daně</h3>

    <label class="inline-flex items-center cursor-pointer p-2">
      <input type="checkbox" v-model="form.is_flat_tax" class="sr-only peer">
      <div class="relative w-11 h-6 bg-gray-200 rounded-full peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-sky-600"></div>
      <span class="ms-3 text-sm font-medium text-gray-900"> Paušální výdaje</span>
    </label>

    <div class="bg-gray-100 p-4 rounded-lg space-y-4">
      <div class="grid grid-cols-1 gap-4">
        <!-- Paušál při zapnutých paušálních výdajích -->
        <div v-if="form.is_flat_tax">
          <label class="block text-gray-700 font-semibold">Paušál (%):</label>
          <div class="relative">
            <input
                v-model="form.property_tax_rate"
                type="number"
                readonly
                class="mt-1 block w-full border border-gray-300 rounded-md p-2 pr-12 bg-gray-200 focus:ring focus:ring-blue-200">
            <span class="absolute inset-y-0 right-0 flex items-center pr-3 text-gray-500">%</span>
          </div>
          <p class="text-sm text-gray-500">Paušální výdaje jsou stanoveny ve výši 30&nbsp;% z příjmů.</p>
        </div>

        <!-- Odhadované výdaje při vypnutých paušálních výdajích -->
        <div v-else>
          <label class="block text-gray-700 font-semibold">Odhadované výdaje (%):</label>
          <div class="relative">
            <input
                v-model="form.property_tax_rate"
                type="number"
                placeholder="Např. 60"
                class="mt-1 block w-full border border-gray-300 rounded-md p-2 pr-12 focus:ring focus:ring-blue-200"
                min="0"
                max="100">
            <span class="absolute inset-y-0 right-0 flex items-center pr-3 text-gray-500">%</span>
          </div>
        </div>
      </div>

      <!-- Vysvětlení pro skutečné výdaje -->
      <div v-if="!form.is_flat_tax" class="mt-4">
        <h4 class="text-lg font-semibold text-gray-800">Uplatnění skutečných výdajů</h4>
        <p class="text-sm text-gray-600 mt-2">
          Zadejte odhadovaný procentuální podíl vašich skutečných výdajů z příjmů z pronájmu. Můžete sem zahrnout náklady na údržbu, opravy, pojištění, úroky z hypotéky a další daňově uznatelné náklady. Tento výpočet slouží pouze pro orientační účely a nemusí přesně odrážet vaši skutečnou daňovou povinnost.
        </p>
      </div>
    </div>

    <div class="bg-gray-100 p-4 rounded-lg space-y-4">
      <div class="grid grid-cols-1 gap-4">
        <!-- Paušál při zapnutých paušálních výdajích -->
        <div>
          <label class="block text-gray-700 font-semibold">Sazba daně (%):</label>
          <div class="relative">
            <input
                v-model="form.tax_rate"
                type="number"
                class="mt-1 block w-full border border-gray-300 rounded-md p-2 pr-12  focus:ring focus:ring-blue-200">
            <span class="absolute inset-y-0 right-0 flex items-center pr-3 text-gray-500">%</span>
          </div>
          <p class="text-sm text-gray-500">
            Jaká pro Vás platí sazba daně?
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PropertyTaxes",
  props: {
    modelValue: {
      type: Object,
      required: true
    },
    propertyCategory:{
      type: String,
      required: true,
      default: 'rent'
    }
  },
  computed: {
    form: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      }
    }
  },
  watch: {
    'form.is_flat_tax': {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          // Nastaví paušální sazbu na 30 %, když je paušální výdaj zapnutý
          this.form.property_tax_rate = 30;
        }
      }
    }
  },
  methods: {
    formatCurrency(value) {
      return value != null ? value.toLocaleString('cs-CZ') : '';
    },
    updateLandValue(event) {
      const value = event.target.value.replace(/\s/g, '');
      this.form.total_taxes_cost = parseInt(value, 10) || 0;
    }
  }
}
</script>
