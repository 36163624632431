<template>
  <div class="flex flex-col sm:flex-row justify-center items-center gap-4 p-4">
    <!-- Box pro přidání -->
    <div @click.prevent="pushTo('add')" class="flex  cursor-pointer flex-col items-center justify-center w-full sm:w-1/2 bg-white hover:bg-gray-100 border border-gray-300 rounded-lg p-6 shadow transition duration-300 ease-in-out">
      <font-awesome-icon icon="calendar-days" size="2xl" />
      <span class="mt-2 text-xl font-semibold text-center text-gray-800">Přidat plánovanou nemovitost</span>
    </div>

    <!-- Box pro import -->
    <div @click.prevent="pushTo('import')" class="flex cursor-pointer flex-col items-center justify-center w-full sm:w-1/2 bg-white hover:bg-gray-100 border border-gray-300 rounded-lg p-6 shadow transition duration-300 ease-in-out">
      <font-awesome-icon icon="house-flag" size="2xl"/>
      <span class="mt-2 text-xl font-semibold text-center text-gray-800">Přidat již pořízenou nemovitost</span>
    </div>
  </div>
</template>

<script>
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";

export default {
  name: "SignPost",
  components: {FontAwesomeIcon},

  methods:{
    pushTo(name){
      if(name === 'add'){
        this.$router.push({name: 'AddProperties'})
        this.$emit('close');
      }else if(name === 'import'){
        this.$router.push({name: 'ImportProperties'})
        this.$emit('close');
      }
      this.$emit('close');
    }
  }
};
</script>

<style scoped>
/* Případně můžete přidat další styly pro jemné doladění vzhledu */
</style>
