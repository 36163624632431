<template>
  <div class="mx-auto p-6 pt-12 bg-gray-100 min-h-screen rounded-lg">
    <div class="flex flex-col sm:flex-row items-start sm:items-center w-full mb-6 gap-3">

      <h2 class="text-3xl font-extrabold text-gray-800 w-full">
        <font-awesome-icon icon="chevron-left" size="xs" class="mr-2 cursor-pointer hover:text-sky-500"
                           @click="$router.go(-1)"/>
        <template v-if="!form.name">
          Nová nemovitost
        </template>
        <template v-else>
          {{ form.name }}
        </template>
      </h2>

      <div class="flex flex-col sm:flex-row w-full sm:w-auto gap-3">
        <button
          @click.prevent="toggleTemplateModalOpen(true)"
          type="button"
          class="flex rounded-md items-center justify-center text-white bg-sky-500 hover:bg-sky-700 font-medium text-sm px-3 py-3 min-w-44"
        >
          <font-awesome-icon icon="file-lines" class="mr-2"/>
          Použít šablonu
        </button>

        <button
          @click.prevent="saveProperty"
          type="button"
          class="flex rounded-md items-center justify-center text-white bg-green-700 hover:bg-green-800 font-medium text-sm px-3 py-3 min-w-44"
        >
          <svg class="mr-2 w-4 h-4 invert" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
               viewBox="0 0 24 24">
            <path
              d="M15.004 3h2.996v5h-2.996v-5zm8.996 1v20h-24v-24h20l4 4zm-19 5h14v-7h-14v7zm16 4h-18v9h18v-9zm-2 2h-14v1h14v-1zm0 2h-14v1h14v-1z"/>
          </svg>
          Uložit nemovitost
        </button>
      </div>
    </div>
    <div class="flex justify-end" v-if="hasValidationError">
      <p class="text-sm text-right mt-2 text-red-700"> {{ errorText }} </p>
    </div>

    <div class="flex flex-col md:flex-row">
      <!-- Kroková navigace -->
      <div class="w-full md:w-1/5 mb-6 md:mb-0 md:mr-6">
        <div class="md:block flex overflow-x-auto  z-30 top-20 sticky top-0 scrollbar-hidden">
          <div
            v-for="(step, index) in steps"
            :key="index"
            @click="setActiveStep(index)"
            :class="[
              'flex min-w-64 md:min-w-min mt-3 items-center p-4 bg-white shadow cursor-pointer hover:bg-blue-100 transition-colors duration-200',
              { 'border-l-4 border-sky-500': activeStep === index },
              { 'bg-red-200 hover:bg-red-100' : categoryValidationError[index].error}
            ]"
          >
            <img :src="`/images/icons/forms/` + step.icon + '.svg'" alt="icon" class="lg:w-8 lg:h-8 w-4 h-4 mr-4"/>
            <span class="text-lg font-semibold text-gray-700">  {{ step.name }}</span>
          </div>
        </div>
      </div>

      <!-- Aktivní formulář -->
      <div class="w-full md:w-4/5 bg-white pb-10 rounded-tl-lg rounded-bl-lg shadow-lg mt-2 relative transition">
        <div class="flex justify-end w-full">
          <button
            v-if="false"
            @click.prevent="toggleAiModalOpen(true)"
            type="button"
            class="flex sm:inline-flex items-center justify-center text-white bg-sky-500 hover:bg-sky-700 font-medium text-sm px-3 py-3"
          >
            <font-awesome-icon icon="brain" class="mr-2"/>
            Vyplnit pomocí AI
          </button>
        </div>
        <div class="p-8">
          <transition name="fade" mode="out-in">
            <component :is="steps[activeStep].component" v-model="form" v-bind="activeStepProps"
                       :propertyCategory="activeCategory"></component>
          </transition>
        </div>

        <div class="flex justify-end mt-5">
          <button
            v-if="activeStep < 4"
            @click="nextStep"
            type="button"
            class="flex absolute right-0 bottom-0 sm:inline-flex items-center justify-center text-white bg-sky-700 hover:bg-sky-800 focus:ring-4 focus:ring-sky-300 font-medium text-sm px-3 py-3"
          >
            Pokračovat na další krok

            <font-awesome-icon icon="chevron-right" class="ml-2"/>
          </button>
        </div>
      </div>
    </div>
    <ModalApp
      :is-open="isAiModalOpen"
      size="medium"
      title="Vyplňování pomocí AI"
      @close="toggleAiModalOpen(false)"
    >
      <AiFillWindow :data="form" @get-data="getAiData"/>
    </ModalApp>

    <ModalApp
      :is-open="templateModal"
      size="medium"
      title="Vyplnit pomocí šablony"
      @close="toggleTemplateModalOpen(false)"
    >
      <fetch-templates @fill="fillTemplate" @close="toggleTemplateModalOpen(false)"/>
    </ModalApp>
  </div>
</template>

<script>
import PropertyDescription from "@/components/forms/PropertyDescription.vue";
import PurchaseAndRehab from "@/components/forms/PurchaseAndRehab.vue";
import RentAndCosts from "@/components/forms/RentAndCosts.vue";
import LongTermProjections from "@/components/forms/LongTermProjections.vue";
import PropertyTaxes from "@/components/forms/PropertyTaxes.vue";
import ModalApp from "@/components/ModalApp.vue";
import SignPost from "@/components/share/SignPost.vue";
import AiFillWindow from "@/components/share/AiFillWindow.vue";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import {usePropertyStore} from "@/pinia/usePropertyStore";
import FetchTemplates from "@/components/templates/FetchTemplates.vue";

export default {
  name: "AddProperties",

  components: {
    FetchTemplates,
    FontAwesomeIcon,
    AiFillWindow,
    SignPost, ModalApp,
    PropertyDescription,
    PurchaseAndRehab,
    RentAndCosts,
    LongTermProjections,
    PropertyTaxes
  },

  computed: {
    activeCategory() {
      const propertyStore = usePropertyStore();
      return propertyStore.activeCategory;
    },

    hasValidationError() {
      return this.categoryValidationError.some(category => category.error);
    },

    activeStepProps() {
      if (this.steps[this.activeStep].component === 'PropertyDescription') {
        return {nameError: this.nameError};
      }
      return {};
    }
  },

  data() {
    return {
      activeStep: 0,
      isAiModalOpen: false,
      isLockScreenAi: false,
      steps: [
        {name: 'O nemovitosti', component: 'PropertyDescription', icon: 'first'},
        {name: 'Nákup a opravy', component: 'PurchaseAndRehab', icon: 'second'},
        {name: 'Příjmy a výdaje', component: 'RentAndCosts', icon: 'third'},
        {name: 'Budoucí vývoj', component: 'LongTermProjections', icon: 'fourth'},
        {name: 'Daně', component: 'PropertyTaxes', icon: 'fifth'},
      ],
      form: {
        category: 'rent',
        name: "",
        description: "",
        tags: "",
        street: "",
        city: "",
        state: "",
        postal_code: "",
        image_url: null,
        purchase_price: null,
        after_repair_value: null,
        repair_costs: [
          {name: 'Náklady na rekonstrukci', amount: null}
        ],
        financing: [
          {
            name: 'Financování',
            loan_amount: null,
            amount: null,
            investment_percentage: 90,
            loan_term: 30,
            interest_rate: 5.1,
            monthly_insurance: 0,
            change_interest_rate_year: 5,
            change_interest_rate: 5.1,
          }
        ],
        gross_rent: null,
        vacancy_rate: null,
        other_income: [
          {name: 'Další příjmy z nemovitosti', amount: 0}
        ],
        operating_expenses: [
          {name: 'Provozní náklady na nemovitost', amount: 0}
        ],
        property_appreciation: null,
        income_growth: null,
        expense_growth: null,
        selling_costs: null,
        property_tax_rate: null,
        depreciation_term: null,
        total_taxes_cost: null,
        square_meters: null,
        property_type: 'byt',
        parking_spaces_type: 'žádné',
        disposition: '1+kk',
        is_multiple_financing: true,
        is_multiple_repair: false,
        is_multiple_income: false,
        is_multiple_operating: false,
        is_flat_tax: true,
        estimated_investment_time: 10,
        tax_rate: 15,
      },
      isEditMode: false,
      propertyId: null,
      nameError: false,
      hasUnsavedChanges: false,
      templateModal: false,
      categoryValidationError: [
        {component: 'PropertyDescription', error: false},
        {component: 'PurchaseAndRehab', error: false},
        {component: 'RentAndCosts', error: false},
        {component: 'LongTermProjections', error: false},
        {component: 'PropertyTaxes', error: false},
      ],
      errorText: "",
    }
  },

  beforeRouteLeave(to, from, next) {
    if (this.hasUnsavedChanges) {
      const answer = window.confirm('Máte neuložené změny. Opravdu chcete opustit tuto stránku?');
      if (answer) {
        next(); // Pokračuje na další stránku
      } else {
        next(false); // Zůstane na aktuální stránce
      }
    } else {
      next(); // Pokračuje na další stránku
    }
  },

  async created() {
    this.propertyId = this.$route.params.id;
    const propertyStore = usePropertyStore();
    if (this.propertyId) {
      this.isEditMode = true;
      await this.loadPropertyData();
      if (this.form.category) {
        propertyStore.setActiveCategory(this.form.category);
      }
    }
    this.form.category = propertyStore.activeCategory;
    if (this.form.category === 'flip' && !this.propertyId) {
      this.form.estimated_investment_time = 0.833333;
    }

  },

  methods: {
    startAiLoading() {
      this.isLockScreenAi = true;
      this.isAiModalOpen = false;
    },
    fillTemplate(data) {
      Object.keys(this.form).forEach(key => {
        // Vynecháváme vyplňování "name"
        if (key === 'name') return;

        if (Object.prototype.hasOwnProperty.call(data, key) && data[key] !== null) {
          if (Array.isArray(this.form[key]) && Array.isArray(data[key])) {
            this.form[key] = data[key].map((item, index) => {
              return {...this.form[key][index], ...item};
            });

            // Zvláštní zpracování pro "financing" a výpočet částky
            if (key === 'financing') {
              this.form[key].forEach((financeItem, index) => {
                if (Object.prototype.hasOwnProperty.call(data[key][index], 'amount_type')) {
                  const amountType = data[key][index].amount_type;
                  let calculatedAmount = 0;

                  switch (amountType) {
                    case 0:
                      calculatedAmount = this.form.purchase_price || 0;
                      break;
                    case 1:
                      calculatedAmount = (this.form.purchase_price || 0) + (this.form.selling_costs || 0);
                      break;
                    case 2:
                      calculatedAmount = (this.form.purchase_price || 0) + (this.form.selling_costs || 0) + (this.getRepairCosts() || 0);
                      break;
                    case 3:
                      calculatedAmount = this.form.after_repair_value || 0;
                      break;
                    default:
                      calculatedAmount = null;
                  }

                  // Pokud calculatedAmount není null, nastavíme hodnotu, jinak necháme původní nebo null
                  if (calculatedAmount !== null) {
                    this.form[key][index].amount = calculatedAmount;
                  } else {
                    this.form[key][index].amount = 0;
                  }
                }
              });
            }
          } else if (typeof this.form[key] === 'object' && typeof data[key] === 'object' && this.form[key] !== null) {
            this.form[key] = {...this.form[key], ...data[key]};
          } else {
            this.form[key] = data[key];
          }
        }
      });
    },

    getRepairCosts() {
      return this.form.repair_costs.reduce((total, costItem) => {
        return total + (costItem.amount || 0);
      }, 0);
    },

    setActiveStep(index) {
      this.activeStep = index;
    },

    getAiData(updatedData) {
      this.isLockScreenAi = false;

      const updateForm = (form, data) => {
        if (!form) {
          form = {}; // Zajisti, že form vždy existuje
        }
        Object.keys(form).forEach(key => {
          if (Object.prototype.hasOwnProperty.call(data, key)) {
            const formValueType = typeof form[key];
            const dataValueType = typeof data[key];

            if (Array.isArray(form[key]) && Array.isArray(data[key])) {
              form[key] = data[key].map((item, index) => {
                if (typeof item === 'object' && item !== null) {
                  return {...form[key][index], ...item};
                }
                return item;
              });
            } else if (formValueType === 'object' && dataValueType === 'object' && form[key] !== null) {
              form[key] = updateForm(form[key], data[key]);
            } else if (formValueType === dataValueType) {
              form[key] = data[key];
            }
          }
        });
        return form;
      };
      this.form = updateForm(this.form, updatedData) || this.form;
      this.toggleAiModalOpen(false);
    },


    toggleAiModalOpen(condition) {
      this.isAiModalOpen = condition;
    },

    toggleTemplateModalOpen(condition) {
      this.templateModal = condition;
    },

    resetNameError() {
      this.nameError = false;
    },

    nextStep() {
      this.activeStep = this.activeStep + 1;
    },

    validate() {
      for (let i = 0; i < 4; i++) {
        this.categoryValidationError[i].error = false;
      }
      if (this.form.name.length < 4 || this.form.name.length > 45) {
        this.nameError = true;
        this.errorText = "Název musí být dlouhý 3 - 45 znaků.";
        this.categoryValidationError[0].error = true;
        return false;
      } else if (Number(this.form.square_meters) < 0) {
        this.errorText = "Podlahová plocha nemůže být menší než 0";
        this.categoryValidationError[0].error = true;
        return false;
      } else if (Number(this.form.purchase_price) < 1) {
        this.errorText = "Pořizovací cena nemovitosti nemůže být menší než 0";
        this.categoryValidationError[1].error = true;
        return false;
      } else if (Number(this.form.after_repair_value) < 1) {
        this.errorText = "Hodnota nemovitosti po rekonstrukci musí být kladné číslo";
        this.categoryValidationError[1].error = true;
        return false;
      }
      this.nameError = false;
      return true;
    },

    async saveProperty() {
      if (!this.validate()) {
        return;
      }

      try {
        let response;
        if (this.isEditMode) {
          response = await this.$axios.put(`/planned-properties/${this.propertyId}`, this.form);
          this.$router.push({name: 'PlannProperties'});
          this.$flashMessage.success('Nemovitost byla úspěšně upravena.');
        } else {
          response = await this.$axios.post('/planned-properties', this.form);
          this.$router.push({name: 'PlannProperties'});
          this.$flashMessage.success('Nemovitost byla úspěšně přidána.');
          this.resetForm();
        }
      } catch (error) {
        console.error('Error saving property:', error);
        this.$flashMessage.error('Chyba při ukládání nemovitosti.');
      }
    },

    async loadPropertyData() {
      try {
        const response = await this.$axios.get(`/planned-properties/${this.propertyId}`);
        this.form = response.data;
      } catch (error) {
        console.error('Error loading property data:', error);
        this.$flashMessage.error('Chyba při načítání údajů o nemovitosti.');
      }
    },

    resetForm() {
      this.form = {
        category: 'rent',
        name: "",
        description: "",
        tags: "",
        street: "",
        city: "",
        state: "",
        postal_code: "",
        image_url: null,
        purchase_price: 0,
        repair_costs: [
          {name: 'Náklady na rekonstrukci', amount: null}
        ],
        financing: [
          {
            name: 'Financování',
            loan_amount: 0,
            amount: null,
            investment_percentage: 0,
            loan_term: 0,
            interest_rate: 0,
            monthly_insurance: 0
          }
        ],
        gross_rent: 0,
        vacancy_rate: 0,
        other_income: [
          {name: 'Hrubý příjem z pronájmu', amount: 0}
        ],
        operating_expenses: [
          {name: 'Provozní náklady na nemovitost', amount: 0}
        ],
        property_appreciation: 0,
        income_growth: 0,
        expense_growth: 0,
        selling_costs: 0,
        property_tax_rate: 0,
        depreciation_term: 0,
        total_taxes_cost: 0,
        is_multiple_financing: true,
        is_multiple_repair: false,
        is_multiple_income: false,
        is_multiple_operating: false,
        is_flat_tax: true,
      };
      this.nameError = false;
      this.hasUnsavedChanges = false;
    }
  },

}
</script>

<style scoped>
.scrollbar-hidden::-webkit-scrollbar {
  display: none;
}

.scrollbar-hidden {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.cursor-pointer {
  transition: transform 0.2s ease-in-out;
}

/* Stín pro seznam kategorií */
.shadow-lg {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);
}

.shadow-sm {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

@keyframes write {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(150px);
  }
  100% {
    transform: translateX(0);
  }
}

</style>
