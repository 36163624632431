<template>
  <div
      v-if="isOpen"
      class="fixed top-0 right-0 left-0 z-50 flex justify-center items-center w-full h-full bg-black bg-opacity-50"
      @click.self="handleClose"
      tabindex="-1"
  >
    <div
        :class="modalSizeClass"
        :style="customSizeStyle"
        class="relative p-4 bg-white rounded-lg shadow w-full max-h-screen md:h-auto sm:p-5"
    >
      <div class="flex justify-between items-center pb-4 mb-4 border-b">
        <h3 class="text-lg font-semibold text-gray-900">
          {{ title }}
        </h3>
        <button
            type="button"
            class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
            @click="handleClose"
        >
          <svg aria-hidden="true" class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path>
          </svg>
          <span class="sr-only">Close modal</span>
        </button>
      </div>

      <!-- Scrollovatelný obsah -->
      <div class="overflow-y-auto max-h-[70vh]">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ModalApp",
  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
    title: {
      type: String,
      default: "Modal Title",
    },
    size: {
      type: String,
      default: 'large',
      validator(value) {
        return ['small', 'medium', 'large'].includes(value) || /(\d+)(px|%)?/.test(value);
      }
    }
  },
  computed: {
    modalSizeClass() {
      switch (this.size) {
        case 'small':
          return 'max-w-sm';
        case 'large':
          return 'max-w-4xl';
        case 'big':
          return 'max-w-6xl';
        case 'medium':
        default:
          return 'max-w-2xl';
      }
    },
    customSizeStyle() {
      // Pokud je velikost zadaná v pixelech nebo procentech
      if (/(\d+)(px|%)?/.test(this.size)) {
        return {
          width: this.size
        };
      }
      return null;
    }
  },
  methods: {
    handleClose() {
      this.$emit("close");
    },
    handleKeydown(event) {
      if (event.key === 'Escape') {
        this.handleClose();
      }
    }
  },
  watch: {
    isOpen(newVal) {
      if (newVal) {
        document.addEventListener('keydown', this.handleKeydown);
      } else {
        document.removeEventListener('keydown', this.handleKeydown);
      }
    }
  },
  mounted() {
    if (this.isOpen) {
      document.addEventListener('keydown', this.handleKeydown);
    }
  },
  beforeUnmount() {
    document.removeEventListener('keydown', this.handleKeydown);
  },
};
</script>

<style scoped>
/* Přidání dalších vlastních stylů pro modál, pokud je třeba */
</style>
