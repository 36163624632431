<template>
  <!-- Okno chatu -->
  <div class="fixed bottom-0 right-0 mr-4 mb-4 bg-white p-6 rounded-lg border border-[#e5e7eb] w-[440px] h-[634px] flex flex-col justify-between" style="box-shadow: 0 0 #0000, 0 0 #0000, 0 1px 2px 0 rgb(0 0 0 / 0.05);">
    <!-- Hlavička -->
    <div class="flex flex-col space-y-1.5 pb-6">
      <div class="flex justify-between items-center">
        <div>
          <h2 class="font-semibold text-lg tracking-tight">Rilo Intelligence (Beta)</h2>
          <p class="text-sm text-[#6b7280] leading-3">Umí vytvářet nemovitosti a konzultovat</p>
          <small class="text-[#6b7280] font-semibold"> Jeden dotaz odečítá 3 kredity.</small>
        </div>
        <button @click="closeChat" class="text-gray-400 hover:text-gray-600">
          <!-- Ikona pro zavření -->
          <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none"
               viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                  d="M6 18L18 6M6 6l12 12"/>
          </svg>
        </button>
      </div>
    </div>

    <!-- Kontejner pro zprávy -->
    <div class="h-[474px] overflow-y-auto pr-4 mb-2" ref="messagesContainer">
      <template v-if="messages.length > 0">
        <template v-for="(message, index) in messages" :key="index">
          <!-- Zpráva od AI -->
          <div v-if="message.sender === 'ai'" class="flex gap-3 my-4 text-gray-600 text-sm flex-1">
            <span class="relative flex shrink-0 overflow-hidden rounded-full w-8 h-8">
              <div class="rounded-full bg-gray-100 border p-1">
                <!-- Ikona AI -->
                <svg stroke="none" fill="black" stroke-width="1.5"
                     viewBox="0 0 24 24" aria-hidden="true" height="20" width="20" xmlns="http://www.w3.org/2000/svg">
                  <path stroke-linecap="round" stroke-linejoin="round"
                        d="M9.813 15.904L9 18.75l-.813-2.846a4.5 4.5 0 00-3.09-3.09L2.25 12l2.846-.813a4.5 4.5 0 003.09-3.09L9 5.25l.813 2.846a4.5 4.5 0 003.09 3.09L15.75 12l-2.846.813a4.5 4.5 0 00-3.09 3.09zM18.259 8.715L18 9.75l-.259-1.035a3.375 3.375 0 00-2.455-2.456L14.25 6l1.036-.259a3.375 3.375 0 002.455-2.456L18 2.25l.259 1.035a3.375 3.375 0 002.456 2.456L21.75 6l-1.035.259a3.375 3.375 0 00-2.456 2.456zM16.894 20.567L16.5 21.75l-.394-1.183a2.25 2.25 0 00-1.423-1.423L13.5 18.75l1.183-.394a2.25 2.25 0 001.423-1.423l.394-1.183.394 1.183a2.25 2.25 0 001.423 1.423l1.183.394-1.183.394a2.25 2.25 0 00-1.423 1.423z">
                  </path>
                </svg>
              </div>
            </span>
            <p class="leading-relaxed">
              <span class="block font-bold text-gray-700">AI </span>
              <span v-html="formatMessage(message.message)"></span>
            </p>
          </div>
          <!-- Zpráva od uživatele -->
          <div v-else-if="message.sender === 'user'" class="flex gap-3 my-4 text-gray-600 text-sm flex-1">
            <span class="relative flex shrink-0 overflow-hidden rounded-full w-8 h-8">
              <div class="rounded-full bg-gray-100 border p-1">
                <!-- Ikona uživatele -->
                <svg stroke="none" fill="black" stroke-width="0"
                     viewBox="0 0 16 16" height="20" width="20" xmlns="http://www.w3.org/2000/svg">
                  <path
                      d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0Zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4Zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10Z">
                  </path>
                </svg>
              </div>
            </span>
            <p class="leading-relaxed">
              <span class="block font-bold text-gray-700">Vy </span>
              <span v-html="formatMessage(message.message)"></span>
            </p>
          </div>
        </template>
      </template>
      <!-- Žádné zprávy -->
      <template v-else>
        <div class="text-center text-gray-500 pt-10">Nejsou evidovány žádné zprávy.</div>
      </template>

      <!-- Indikátor načítání -->
      <div v-if="loading" class="flex gap-3 my-4 text-gray-600 text-sm flex-1">
        <span class="relative flex shrink-0 overflow-hidden rounded-full w-8 h-8">
          <div class="rounded-full bg-gray-100 border p-1">
            <!-- Ikona AI -->
            <svg stroke="none" fill="black" stroke-width="1.5"
                 viewBox="0 0 24 24" aria-hidden="true" height="20" width="20" xmlns="http://www.w3.org/2000/svg">
              <path stroke-linecap="round" stroke-linejoin="round"
                    d="M9.813 15.904L9 18.75l-.813-2.846a4.5 4.5 0 00-3.09-3.09L2.25 12l2.846-.813a4.5 4.5 0 003.09-3.09L9 5.25l.813 2.846a4.5 4.5 0 003.09 3.09L15.75 12l-2.846.813a4.5 4.5 0 00-3.09 3.09zM18.259 8.715L18 9.75l-.259-1.035a3.375 3.375 0 00-2.455-2.456L14.25 6l1.036-.259a3.375 3.375 0 002.455-2.456L18 2.25l.259 1.035a3.375 3.375 0 002.456 2.456L21.75 6l-1.035.259a3.375 3.375 0 00-2.456 2.456zM16.894 20.567L16.5 21.75l-.394-1.183a2.25 2.25 0 00-1.423-1.423L13.5 18.75l1.183-.394a2.25 2.25 0 001.423-1.423l.394-1.183.394 1.183a2.25 2.25 0 001.423 1.423l1.183.394-1.183.394a2.25 2.25 0 00-1.423 1.423z">
              </path>
            </svg>
          </div>
        </span>
        <p class="leading-relaxed">
          <span class="block font-bold text-gray-700">AI </span>
          <span class="typing-indicator">
            <span></span><span></span><span></span>
          </span>
        </p>
      </div>
    </div>

    <!-- Vstupní pole -->
    <div class="flex flex-col items-center pt-0">
      <form class="flex items-center justify-center w-full space-x-2" @submit.prevent="sendMessage">
        <textarea
            v-model="message"
            @input="adjustHeight"
            @keydown.enter="checkEnter"
            placeholder="Zadejte zprávu"
            class="flex-grow w-full rounded-md border border-[#e5e7eb] px-3 py-2 text-sm placeholder-[#6b7280] focus:outline-none focus:ring-2 focus:ring-[#9ca3af] resize-none"
            rows="1"
            style="max-height: 350px; overflow-y: auto; height: auto;"
            ref="messageInput"
        ></textarea>
        <button type="submit"
                class="inline-flex items-center justify-center rounded-md text-sm font-medium text-[#f9fafb] disabled:pointer-events-none disabled:opacity-50 bg-black hover:bg-[#111827E6] h-10 px-4 py-2">
          Odeslat
        </button>
      </form>
      <button
          @click="deleteMessages"
          type="button"
          class="mt-1 mb-2 text-sm text-gray-500 hover:text-gray-700">
        Smazat chat
      </button>
    </div>
  </div>
</template>

<script>
import { marked } from "marked";
import { useAiDataStore } from "@/pinia/useAiDataStore";
import { useUserStore } from "@/pinia/userStore";

export default {
  name: "RiloIntelligence",
  data() {
    return {
      message: "",
      messages: [],
      loading: false,
    };
  },
  mounted() {
    this.loadMessages();
  },
  methods: {
    async loadMessages() {
      try {
        const response = await this.$axios.get("/rilo-intelligence/");
        this.messages = response.data;
        this.scrollToBottom();
      } catch (error) {
        console.error("Chyba při načítání zpráv:", error);
      }
    },

    adjustHeight() {
      const textarea = this.$refs.messageInput;
      textarea.style.height = "auto";
      const newHeight = Math.min(textarea.scrollHeight, 60); // Omezíme výšku na max 350px
      textarea.style.height = `${newHeight}px`;
    },

    async deleteMessages() {
      try {
        await this.$axios.delete("/rilo-intelligence/");
        await this.loadMessages();
        this.scrollToBottom();
      } catch (error) {
        console.error("Chyba při mazání zpráv:", error);
      }
    },

    formatMessage(message) {
      const messageWithNewLines = message.replace(/\n/g, '<br>');
      return marked(messageWithNewLines);
    },

    async sendMessage() {
      const propertyStore = useAiDataStore();
      if (this.message.trim() === "") return;

      const userMessage = { message: this.message, sender: "user" };
      this.messages.push(userMessage);
      this.message = "";
      this.scrollToBottom();

      this.loading = true;

      try {
        const response = await this.$axios.post("/rilo-intelligence", {
          message: userMessage.message,
          data: propertyStore.getAllCombinedData(),
        });

        const aiResponse = response.data.aiMessage.message;
        const aiMessage = { message: aiResponse, sender: "ai" };
        this.messages.push(aiMessage);
        this.loading = false;
        this.scrollToBottom();

        if (aiResponse.includes("Nemovitost byla úspěšně vytvořena")) {
          if (this.$route.path === "/planned") {
            this.$router.go(0);
          }
        }
      } catch (error) {
        console.error("Chyba při volání API intelligence:", error);
        this.loading = false;
      }

      useUserStore().fetchUserCredits();
    },

    scrollToBottom() {
      this.$nextTick(() => {
        const messagesContainer = this.$refs.messagesContainer;
        if (messagesContainer) {
          messagesContainer.scrollTop = messagesContainer.scrollHeight;
        }
      });
    },

    checkEnter(event) {
      if (event.key === "Enter" && !event.shiftKey) {
        event.preventDefault();
        this.sendMessage();
      }
    },

    closeChat() {
      this.$emit("closeChat");
    },
  },
};
</script>

<style scoped>
.typing-indicator {
  display: flex;
  justify-content: space-between;
  width: 24px;
}

.typing-indicator span {
  display: block;
  width: 6px;
  height: 6px;
  background-color: #888;
  border-radius: 50%;
  animation: blink 1.5s infinite ease-in-out;
}

.typing-indicator span:nth-child(1) {
  animation-delay: 0s;
}

.typing-indicator span:nth-child(2) {
  animation-delay: 0.2s;
}

.typing-indicator span:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes blink {
  0%,
  100% {
    opacity: 0.2;
  }
  50% {
    opacity: 1;
  }
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Skrytí scrollbarů pro IE, Edge a Firefox */
.no-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
</style>
