<template>
  <div class="max-w-lg mx-auto bg-white p-8 rounded-lg shadow-md text-center mt-12" style="overflow: hidden;">
    <div class="text-red-500 mb-4">
      <font-awesome-icon icon="heart-crack" class="text-5xl"/>
    </div>
    <h1 class="text-2xl font-bold text-gray-800 mb-4">
      Objednávku se nepodařilo zaplatit.
    </h1>
    <p class="text-gray-600 mb-6">
      Pravděpodobně došlo k chybě při placení a objednávku se nepodařilo zaplatit.
    </p>

    <h3 class="text-gray-700 mb-6">
      V případě, že z Vaší strany došlo k úhradě a aplikace vyhodnotila stav špatně,
      neprodleně nás prosím kontaktujte na e-mailu
      <span class="text-sky-600 font-semibold">podpora@rilo.cz</span>. Vše obratem napravíme k Vaší spokojenosti.
    </h3>

    <div class="flex flex-col space-y-4 md:flex-row md:space-y-0 md:space-x-4 items-center justify-center">
      <router-link
        :to="{ name: 'PlannProperties' }"
        class="flex items-center justify-center w-full md:w-auto px-6 py-3 bg-green-500 text-white rounded-lg hover:bg-green-600 transition shadow-md"
      >
        <font-awesome-icon icon="arrow-right" class="mr-2"/>
        Pokračovat do aplikace
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CancelSubscription',
}
</script>
