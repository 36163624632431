// stores/usePropertyStore.js
import { defineStore } from 'pinia';

export const usePropertyStore = defineStore('propertyStore', {
    state: () => ({
        properties: [],
        isLoading: false,
        error: null,
        criteria: null,
        activeCategory: 'rent',
    }),

    actions: {
        async fetchProperties(axios) {
            this.isLoading = true;
            this.error = null;

            try {
                const response = await axios.get('/planned-properties');
                this.properties = response.data.reduce((acc, category) => acc.concat(category.properties), [])
                if(this.criteria === null){
                    await this.fetchCriteria(axios);
                }else{
                    this.isLoading = false;
                }
            } catch (error) {
                console.error('Error fetching properties:', error);
                this.error = 'Nepodařilo se načíst data.';
            }
        },

        async fetchCriteria(axios) {
            this.isLoading = true;
            this.error = null;

            try {
                const response = await axios.get('/criteria');
                this.criteria = response.data;
            } catch (error) {
                console.error('Error fetching criteria:', error);
                this.error = 'Nepodařilo se načíst kritéria.';
            } finally {
                this.isLoading = false;
            }
        },

        async fetchPropertyById(id, axios) {
            this.isLoading = true;
            this.error = null;

            try {
                const response = await axios.get(`/planned-properties/${id}`);
                const property = response.data;
                const index = this.properties.findIndex(prop => prop.id === id);

                if (index !== -1) {
                    this.properties.splice(index, 1, property);
                } else {
                    this.properties.push(property);
                }
            } catch (error) {
                console.error('Error fetching property:', error);
                this.error = 'Nepodařilo se načíst data.';
            } finally {
                this.isLoading = false;
            }
        },

        setActiveCategory(category) {
            this.activeCategory = category;
        }
    }
});
