<template>
  <div class="p-4">
    <h2 class="text-2xl font-semibold mb-4">
      Šablony nemovitostí
    </h2>
    <p class="mt-4 mb-6">
      Díky šablonám můžeš rychle vyplňovat detaily pro nové nemovitosti, aniž bys musel zadávat stejné údaje znovu. Jednoduše si zvolíš vhodnou šablonu a všechny potřebné informace se automaticky nastaví podle tvých preferencí.
    </p>

    <div class="flex justify-end space-x-4">
      <router-link :to="{name: 'AddTemplateSetting'}" type="button" class="sm:inline-flex mb-3 hidden lg:visible items-center justify-center text-white bg-sky-500 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-3 py-2">
        <svg aria-hidden="true" class="mr-1 -ml-1 w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z" clip-rule="evenodd"></path></svg>
        Vytvořit novou šablonu
      </router-link>
    </div>

    <div v-if="templates.length">
      <SingleTemplate
          v-for="template in templates"
          :key="template.uuid"
          :template="template"
          @deleted="removeTemplate"
      />
    </div>
    <div v-else class="text-gray-500 w-full flex text-center p-10 justify-center">
      Žádné šablony nebyly nalezeny. Vytvořte si svou první šablonu.
    </div>
  </div>
</template>

<script>
import SingleTemplate from '@/components/setting/SingleTemplate.vue';

export default {
  name: 'TemplateSetting',
  components: {
    SingleTemplate,
  },
  data() {
    return {
      templates: [],
    };
  },

  mounted() {
    this.fetchTemplates();
  },

  methods: {
    fetchTemplates() {
      this.$axios.get('/template-properties')
          .then(response => {
            this.templates = response.data;
          })
          .catch(error => {
            console.error('Error fetching templates:', error);
          });
    },
    removeTemplate() {
      this.fetchTemplates();
    },
  },
};
</script>