<template>
  <div class="bg-gray-100 p-3">
    <div class="bg-gray-900 text-white rounded-2xl px-10 py-10">
      <div class="container mx-auto grid grid-cols-1 md:grid-cols-3 gap-8">
        <div>
          <img src="/logo.png" alt="Rilo Logo" class="mb-4 w-20">
          <p style="font-size: 13px">
            Analýzy v aplikaci Rilo slouží pouze jako orientační. Informace poskytované aplikací nejsou investičními
            doporučeními. Každý uživatel je povinen si veškeré údaje ověřit a rozhodovat se samostatně. Rilo nenese
            odpovědnost za žádná investiční rozhodnutí učiněná na základě použití této aplikace.
          </p>
        </div>

        <!-- Links Column 1 -->
        <div>

        </div>

        <!-- Links Column 2 -->
        <div>
          <h3 class="text-lg font-semibold mb-4">Informace</h3>
          <ul>
            <li class="mt-2">
              <a href="https://rilo.cz/vseobecne-obchodni-podminky.html" class="hover:underline text-sm">Všeobecné
                obchodní
                podmínky</a>
            </li>
            <li class="mt-2"><a href="https://rilo.cz/ochrana-osobnich-udaju.html" class="hover:underline text-sm">Ochrana
              osobních údajů</a></li>
            <li class="mt-2"><a href="https://rilo.cz/contact.html" class="hover:underline text-sm">Kontakt</a></li>
          </ul>
        </div>
      </div>
      <div class="flex justify-end">
        <img src="/images/gopay/gopay-logo.png" alt="gopaylogo" class="w-12 object-contain">
        <img src="/images/gopay/maestro-logo.png" alt="gopaylogo" class="w-12 object-contain">
        <img src="/images/gopay/mc-logo.png" alt="gopaylogo" class="w-12 object-contain">
        <img src="/images/gopay/mc-vrt-logo.png" alt="gopaylogo" class="w-12 object-contain">
        <img src="/images/gopay/mce-logo.png" alt="gopaylogo" class="w-12 object-contain">
        <img src="/images/gopay/visa-el-logo.png" alt="gopaylogo" class="w-12 object-contain">
        <img src="/images/gopay/visa-logo.png" alt="gopaylogo" class="w-12 object-contain">
        <img src="/images/gopay/visa-v-logo.png" alt="gopaylogo" class="w-12 object-contain">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FooterTemplate"
}
</script>

<style scoped>
.container {
  max-width: 1200px;
}
</style>
