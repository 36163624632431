<template>
  <div class="max-w-lg pt-10 mx-auto">
    <div class=" bg-white p-8 rounded-lg shadow-md text-center">
      <div class="text-red-500 mb-4">
        <font-awesome-icon icon="heart" class="text-5xl"/>
      </div>
      <h1 class="text-2xl font-bold text-gray-800 mb-4">
        Děkujeme za dokončení Vaší objednávky!
      </h1>
      <p class="text-gray-600 mb-6">
        Vážíme si Vaší důvěry v naše služby.
      </p>

      <h3 class="text-gray-700 mb-6">
        V případě, že budete potřebovat s čímkoliv pomoci či poradit, obraťte se na nás na e-mailu
        <a class="text-sky-600 underline font-semibold hover:text-sky-800"
           href="mailto:podpora@rilo.cz">podpora@rilo.cz</a>
      </h3>

      <div class="flex flex-col space-y-4 md:flex-row md:space-y-0 md:space-x-4">
        <router-link
          :to="{ name: 'DocumentsSetting' }"
          class="flex items-center justify-center w-full md:w-auto px-6 py-3 bg-sky-600 text-white rounded-lg hover:bg-sky-700 transition shadow-md"
        >
          <font-awesome-icon icon="file-invoice" class="mr-4"/>
          Doklad naleznete zde
        </router-link>
        <router-link
          :to="{ name: 'PlannProperties' }"
          class="flex items-center justify-center w-full md:w-auto px-6 py-3 bg-green-500 text-white rounded-lg hover:bg-green-600 transition shadow-md"
        >
          <font-awesome-icon icon="arrow-right" class="mr-2"/>
          Pokračovat do aplikace
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'PaidSubscription',
}
</script>

<style scoped>
/* Custom styling for responsive adjustments if needed */
</style>
