import {createRouter, createWebHistory} from 'vue-router';
import Cookies from 'js-cookie';
import Login from '@/pages/LoginPage.vue';
import Register from '@/pages/RegisterPage.vue';
import Application from '@/AppPage.vue';
import RentCalculator from '@/pages/Apps/RentCalculator.vue';
import PlannProperties from '@/pages/PlannProperties.vue';
import PropertyCompare from '@/pages/PropertyCompare.vue';
import AddProperties from '@/pages/AddProperties.vue';
import PurchaseSubscription from "@/pages/PurchaseSubscription.vue";
import PaidSubscription from "@/pages/Subscription/PaidSubscription.vue";
import CancelSubscription from "@/pages/Subscription/CancelSubscription.vue";
import SettingSubscription from "@/pages/Subscription/SettingSubscription.vue";
import AnalysisPage from "@/pages/AnalysisPage.vue";
import SettingPage from "@/pages/Settings/SettingPage.vue";
import ProfileSetting from "@/pages/Settings/ProfileSetting.vue";
import LimitsSetting from "@/pages/Settings/LimitsSetting.vue";
import TemplateSetting from "@/pages/Settings/TemplateSetting.vue";
import InvoiceSetting from "@/pages/Settings/InvoiceSetting.vue";
import SubscriptionSetting from "@/pages/Settings/SubscriptionSetting.vue";
import DocumentsSetting from "@/pages/Settings/DocumentsSetting.vue";
import CreditsSetting from "@/pages/Settings/CreditsSetting.vue";
import AddTemplateSetting from "@/pages/Settings/AddTemplateSetting.vue"
import ForgotPassword from "@/pages/ForgotPassword.vue";
import ResetPassword from "@/pages/ResetPassword.vue";
import TimeToRentCalculator from "@/pages/Apps/TimeToRentCalculator.vue";
import FairnessPrice from "@/pages/Apps/FairnessPrice.vue";
import PrivacyPrivacy from "@/pages/PrivacyPrivacy.vue";

const routes = [
  {
    path: '/',
    name: 'AppPage',
    component: Application,
    redirect: {name: 'PlannProperties'},
    children: [
      {
        path: 'rent-calculator',
        name: 'RentCalculator',
        component: RentCalculator,
        meta: {transition: 'fade', title: 'Kalkulačka výše nájemného'}
      },
      {
        path: 'time-to-rent-calculator',
        name: 'TimeToRentCalculator',
        component: TimeToRentCalculator,
        meta: {transition: 'fade', title: 'Kalkulačka výše nájemného'}
      },
      {
        path: 'fairness-price',
        name: 'FairnessPrice',
        component: FairnessPrice,
        meta: {transition: 'fade', title: 'Kalkulačka výše nájemného'}
      },
      {
        path: 'planned',
        name: 'PlannProperties',
        component: PlannProperties,
        meta: {transition: 'slide-left', title: 'Analyzované nemovitosti'}
      },
      {
        path: 'add-plan',
        name: 'AddProperties',
        component: AddProperties,
        meta: {transition: 'slide-right', title: 'Vytvoření nové nemovitosti'}
      },
      {
        path: 'edit-plan/:id',
        name: 'EditProperties',
        component: AddProperties,
        props: true,
        meta: {transition: 'slide-right', title: 'Úprava nemovitosti'}
      },
      {
        path: 'analysis/:id',
        name: 'AnalysisPage',
        component: AnalysisPage,
        props: true,
        meta: {transition: 'slide-right', title: 'Analýza nemovitosti'}
      },
      {
        path: 'import-plan',
        name: 'ImportProperties',
        component: AddProperties,
        props: {isImport: true},
        meta: {transition: 'slide-right', title: 'Import nemovitosti'}
      },
      {
        path: 'compare',
        name: 'PropertyCompare',
        component: PropertyCompare,
        meta: {transition: 'fade', title: 'Porovnat nemovitosti'}
      },
      {
        path: 'subscription',
        name: 'UserSubscription',
        component: PurchaseSubscription,
        meta: {transition: 'fade', title: 'Předplatné'}
      },
      {
        path: 'success',
        name: 'PaidSubscription',
        component: PaidSubscription,
        meta: {transition: 'fade', title: 'Platba proběhla úspěšně'},
      },
      {
        path: 'cancel',
        name: 'CancelSubscription',
        component: CancelSubscription,
        meta: {transition: 'fade', title: 'Došlo k chybě'}
      },
      {
        path: 'subscription-setting',
        name: 'SettingSubscription',
        component: SettingSubscription,
        meta: {transition: 'fade', title: 'Nastavení předplatného'}
      },
      {
        path: 'setting',
        name: 'SettingPage',
        component: SettingPage,
        redirect: {name: 'ProfileSetting'},
        meta: {transition: 'fade', title: 'Nastavení'},
        children: [
          {
            path: 'profile',
            name: 'ProfileSetting',
            component: ProfileSetting,
            meta: {transition: 'fade'}
          },
          {
            path: 'limits',
            name: 'LimitsSetting',
            component: LimitsSetting,
            meta: {transition: 'fade'}
          },
          {
            path: 'template',
            name: 'TemplateSetting',
            component: TemplateSetting,
            meta: {transition: 'fade'}
          },
          {
            path: 'template/add',
            name: 'AddTemplateSetting',
            component: AddTemplateSetting,
            meta: {transition: 'fade'}
          },
          {
            path: 'template/edit/:id',
            props: true,
            name: 'EditTemplateSetting',
            component: AddTemplateSetting,
            meta: {transition: 'fade'}
          },
          {
            path: 'invoices',
            name: 'InvoiceSetting',
            component: InvoiceSetting,
            meta: {transition: 'fade'}
          },
          {
            path: 'subscription',
            name: 'SubscriptionSetting',
            component: SubscriptionSetting,
            meta: {transition: 'fade'}
          },
          {
            path: 'documents',
            name: 'DocumentsSetting',
            component: DocumentsSetting,
            meta: {transition: 'fade'}
          },
          {
            path: 'credit',
            name: 'CreditsSetting',
            component: CreditsSetting,
            meta: {transition: 'fade'}
          },
        ]
      },
    ],
  },
  {
    path: '/login',
    name: 'LoginPage',
    component: Login
  },
  {
    path: '/reset-password',
    name: 'ResetPassword',
    component: ResetPassword,
    query: {token: ''},
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: ForgotPassword,
  },
  {
    path: '/register',
    name: 'RegisterPage',
    component: Register
  },
  {
    path: '/ochrana-osobnich-udaju',
    name: 'PrivacyPrivacy',
    component: PrivacyPrivacy
  },
  {
    path: '/:catchAll(.*)',
    redirect: '/planned',
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    return {top: 0};
  }
});

router.afterEach((to) => {
  const baseTitle = 'Rilo.cz';
  const pageTitle = to.meta.title ? ` | ${to.meta.title}` : '';
  document.title = baseTitle + pageTitle;
});


router.beforeEach((to, from, next) => {
  const token = Cookies.get('authToken');
  if (to.path === '/ochrana-osobnich-udaju') {
    next();
  } else if (token && (to.path === '/login' || to.path === '/register' || to.path === '/forgot-password' || to.path === '/reset-password')) {
    next('/');
  } else if (!token && to.path !== '/login' && to.path !== '/register' && to.path !== '/forgot-password' && to.path !== '/reset-password') {
    next('/login');
  } else {
    if (from.name === 'PlannProperties' && (to.name === 'AddProperties' || to.name === 'EditProperties' || to.name === 'AnalysisPage')) {
      to.meta.transition = 'slide-left';
    } else if ((from.name === 'AddProperties' || from.name === 'EditProperties' || from.name === 'AnalysisPage') && to.name === 'PlannProperties') {
      to.meta.transition = 'slide-right';
    } else {
      to.meta.transition = 'fade';
    }
    next();
  }
});

export default router;
