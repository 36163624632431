<template>
  <div class="mx-auto p-6 pt-12 bg-gray-100 min-h-screen rounded-lg" v-if="isLoad">
    <div class="flex flex-col sm:flex-row items-start sm:items-center w-full mb-6 gap-3">
      <h2 class="text-3xl font-extrabold text-gray-800 w-full">
        <font-awesome-icon icon="chevron-left" size="xs" class="mr-2 cursor-pointer hover:text-sky-500" @click="$router.go(-1)" />
        {{form.name}}
      </h2>

      <div class="flex flex-col sm:flex-row w-full sm:w-auto gap-3">
        <button
            @click.prevent="openEtfModal"
            type="button"
            class="flex rounded-md items-center justify-center text-white bg-sky-500 hover:bg-sky-700 font-medium text-sm px-3 py-3 min-w-44"
        >
          <font-awesome-icon icon="arrow-trend-up" class="mr-2" />
          Porovnání s ETF
        </button>

        <router-link
            :to="'/edit-plan/' + propertyId"
            type="button"
            class="flex rounded-md items-center justify-center text-white bg-sky-700 hover:bg-sky-900 font-medium text-sm px-3 py-3 min-w-44"
        >
          <font-awesome-icon icon="edit" class="mr-2" />
          Upravit údaje
        </router-link>
      </div>
    </div>
    <div class="flex flex-col md:flex-row">
      <div class="w-full md:w-1/5 mb-6 md:mb-0 md:mr-6">
        <div class="md:block flex overflow-x-auto z-30 top-20 sticky scrollbar-hidden">
          <div
              v-for="(step, index) in steps"
              :key="index"
              @click="setActiveStep(index)"
              :class="[
              'flex min-w-64 md:min-w-min mt-3 items-center p-4 bg-white shadow cursor-pointer hover:bg-blue-100 transition-colors duration-200',
              { 'border-l-4 border-sky-500': activeStep === index }
            ]"
          >
            <font-awesome-icon :icon="step.icon" class="mr-4" size="2xl" />
            <span class="text-lg font-semibold text-gray-700">  {{ step.name }}</span>
          </div>
        </div>
      </div>

      <div class="w-full md:w-4/5 pb-10 rounded-tl-lg rounded-bl-lg mt-2 relative transition">
        <div class="p-0">
          <transition name="fade" mode="out-in">
            <component :is="steps[activeStep].component" v-model="form" :data="data" :key="activeStep" />
          </transition>
        </div>
      </div>
    </div>
    <ModalApp
        :is-open="isEtfModalOpen"
        size="large"
        @close="openEtfModal"
        title="Porovnání zhodnocení s ETF"
    >
      <CompareEtfContent :property="form"/>
    </ModalApp>
  </div>
</template>

<script>
import MainAnalysis from "@/components/analysis/MainAnalysis.vue";
import DetailAnalysis from "@/components/analysis/DetailAnalysis.vue";
import ReturnAnalysis from "@/components/analysis/ReturnAnalysis.vue";
import CashFlowAnalysis from "@/components/analysis/CashFlowAnalysis.vue";
import ModalApp from "@/components/ModalApp.vue";
import CompareEtfContent from "@/components/analysis/CompareEtfContent.vue";
export default {
  name: "AnalysisPage",

  components: {
    ModalApp,
    MainAnalysis,
    DetailAnalysis,
    ReturnAnalysis,
    CashFlowAnalysis,
    CompareEtfContent
  },

  watch:{
    $route(newVal){
      this.propertyId = newVal.params.id;
      this.loadPropertyData();
    }
  },

  data() {
    return {
      isLoad: false,
      activeStep: 0,
      steps: [
        { name: 'Základní data', component: 'MainAnalysis', icon: 'chart-pie' },
        { name: 'Detailní analýza', component: 'DetailAnalysis', icon: 'magnifying-glass-chart' },
        { name: 'Vizualizace času', component: 'ReturnAnalysis', icon: 'stopwatch' },
        { name: 'Projekce', component: 'CashFlowAnalysis', icon: 'calendar' },
      ],
      propertyId: null,
      nameError: false,
      hasUnsavedChanges: false,
      form: {},
      data: {},
      isEtfModalOpen: false,
    };
  },

  async created() {
    this.propertyId = this.$route.params.id;
    if (this.propertyId) {
      await this.loadPropertyData();
    }
  },

  methods: {
    setActiveStep(index) {
      this.activeStep = index;
    },

    openEtfModal(){
      this.isEtfModalOpen = !this.isEtfModalOpen;
    },

    async loadPropertyData() {
      try {
        const response = await this.$axios.get(`/planned-properties/${this.propertyId}`);
        this.form = response.data;
        this.isLoad = true;
      } catch (error) {
        console.error('Error loading property data:', error);
        this.$flashMessage.error('Chyba při načítání údajů o nemovitosti.');
      }
    },
  },

}
</script>

<style scoped>
.scrollbar-hidden::-webkit-scrollbar {
  display: none;
}

.scrollbar-hidden {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.cursor-pointer {
  transition: transform 0.2s ease-in-out;
}

.shadow-lg {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);
}

@keyframes write {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(150px);
  }
  100% {
    transform: translateX(0);
  }
}

</style>