<template>
  <div class="p-4 rounded-lg">
    <h2 class="text-2xl font-semibold mb-4">
      Dobít kredity
    </h2>
    <p class="xl mt-4 mb-6">
      <strong> Každý měsíc Vám zdarma připíšeme 200 kreditů v případě, že v daném měsíci AI alespoň jednou
        využijete.</strong>
    </p>
    <p class="xl mt-1 mb-9">
      Pokud chcete služby umělé inteligence využívat opravdu naplno, můžete doplnit svůj stav kreditů objednávkou níže.
      Kredity Vám ihned připíšeme k Vašemu účtu a fakturu naleznete v sekci Předplatné.
    </p>


    <div class="grid grid-cols-1 md:grid-cols-3 gap-8">
      <a @click.prevent="orderCredits('27900')"
         class="cursor-pointer transform hover:scale-105 transition-transform duration-300 bg-white border border-gray-300 p-6 rounded-lg shadow-lg text-center">
        <h2 class="text-xl font-semibold mb-2 text-gray-700"> AI Průzkumník </h2>
        <h3 class="text-2xl font-bold mb-4">600 kreditů</h3>
        <img src="/images/icons/adventurer.png" alt="adventure" class="w-24 m-auto">
        <p class="text-2xl font-bold  mb-2 mt-4">279 Kč</p>
        <button class="bg-sky-600 text-white px-7 py-2 rounded-lg shadow mt-3">
          Objednat
        </button>
      </a>

      <a @click.prevent="orderCredits('47900')"
         class="cursor-pointer  transform hover:scale-105 transition-transform duration-300 bg-white border border-gray-300 p-6 rounded-lg shadow-lg text-center">
        <h2 class="text-xl font-semibold mb-2 text-gray-700"> AI Master </h2>
        <h3 class="text-2xl font-bold mb-4">1 200 kreditů</h3>
        <img src="/images/icons/nomad.png" alt="adventure" class="w-24 m-auto">
        <p class="text-2xl font-bold  mb-2 mt-4">479 Kč</p>
        <button class="bg-sky-600 text-white px-7 py-2 rounded-lg shadow mt-3">
          Objednat
        </button>
      </a>

      <a @click.prevent="orderCredits('120900')"
         class="cursor-pointer  transform hover:scale-105 transition-transform duration-300 bg-white border border-gray-300 p-6 rounded-lg shadow-lg text-center">
        <h2 class="text-xl font-semibold mb-2 text-gray-700"> AI Vládce </h2>
        <h3 class="text-2xl font-bold mb-4">5 000 kreditů</h3>
        <img src="/images/icons/prometheus.png" alt="adventure" class="w-24 m-auto">
        <p class="text-2xl font-bold  mb-2 mt-4">1 209 Kč</p>
        <button class="bg-sky-600 text-white px-7 py-2 rounded-lg shadow mt-3">
          Objednat
        </button>
      </a>
    </div>
    <p class="mt-6 text-center text-gray-800 text-sm"> Platba je jednorázová. Ceny jsou konečné. Po kliknutí budete
      přesměrováni do platební brány GoPay. <br> Doklad najdete o něco později v sekci Doklady. </p>
  </div>
</template>

<script>
export default {
  name: "CreditsSetting",

  methods: {
    async orderCredits(priceId) {
      try {
        const response = await this.$axios.post('/gopay/credits-order', {price_id: priceId});
        window.location.href = response.data.gw_url;
      } catch (error) {
        console.error('Chyba při pokusu objednat kredity:', error);
      }
    }
  }
}
</script>
