<template>
  <section class="bg-sky-500 md:bg-sky-700 h-screen flex">
    <div class="hidden md:flex md:w-1/2 justify-center items-center bg-sky-600">
      <div class="flex flex-col items-center">
        <img src="/images/big-single-logo-white.svg" alt="Valuo Logo" class="mb-4 w-36"/>
        <p class="text-white text-4xl font-bold mb-2">Analýza nemovitostí</p>
        <p class="text-white text-lg font-light">Na jedno kliknutí!</p>
      </div>
    </div>

    <div class="w-full md:w-1/2 flex justify-center items-center bg-white">
      <div class="w-full max-w-md p-8 space-y-6">
        <h1 class="text-2xl font-bold text-gray-900 text-center">Vytvořte si účet</h1>

        <p v-if="errorMessage" class="text-red-500 text-center">{{ errorMessage }}</p>

        <form @submit.prevent="registerUser" class="space-y-6">
          <div>
            <label for="name" class="block mb-2 text-sm font-medium text-gray-900">Uživatelské jméno</label>
            <input
              v-model="data.name"
              type="text"
              id="name"
              autocomplete="off"
              class="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
              placeholder="Josef Novák"
              required
            />
          </div>
          <div>
            <label for="email" class="block mb-2 text-sm font-medium text-gray-900">E-mail</label>
            <input
              v-model="data.email"
              type="email"
              id="email"
              autocomplete="email"
              class="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
              placeholder="jmeno@domena.cz"
              required
            />
          </div>
          <div>
            <label for="password" class="block mb-2 text-sm font-medium text-gray-900">Heslo</label>
            <input
              v-model="data.password"
              type="password"
              id="password"
              autocomplete="new-password"
              placeholder="••••••••"
              class="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
              required
            />
          </div>
          <div>
            <label for="confirm-password" class="block mb-2 text-sm font-medium text-gray-900">Heslo pro
              kontrolu</label>
            <input
              v-model="data.password_confirmation"
              type="password"
              id="confirm-password"
              autocomplete="new-password"
              placeholder="••••••••"
              class="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
              required
            />
          </div>
          <div class="flex items-start">
            <div class="flex items-center h-5">
              <input
                id="terms"
                type="checkbox"
                class="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-primary-300"
                required
              />
            </div>
            <div class="ml-3 text-sm">
              <label for="terms" class="font-light text-gray-500">
                Souhlasím s
                <a href="https://rilo.cz/vseobecne-obchodni-podminky" target="_blank"
                   class="font-medium text-primary-600 hover:underline">podmínkami
                  užívání</a>
                a se
                <a href="https://rilo.cz/ochrana-osobnich-udaju" target="_blank"
                   class="font-medium text-primary-600 hover:underline">zpracováním
                  osobních údajů</a>.
              </label>
            </div>

          </div>
          <button :disabled="loading" type="submit"
                  class="w-full text-white bg-sky-500 hover:bg-sky-600 focus:ring-4 focus:outline-none focus:ring-sky-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center flex justify-center items-center">
            <span v-if="loading" class="mr-2">
              <font-awesome-icon icon="spinner" spin/>
            </span>
            Vytvořit účet
          </button>
          <p class="text-sm font-light text-gray-500">
            Již máte účet?
            <router-link to="/login" class="font-medium text-primary-600 hover:underline">
              Přihlaste se zde
            </router-link>
          </p>
        </form>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";
import Cookies from "js-cookie";

export default {
  name: "RegisterPage",
  data() {
    return {
      data: {
        name: '',
        email: '',
        password: '',
        password_confirmation: '',
      },
      errorMessage: '',
      loading: false, // Indikace načítání
    };
  },
  methods: {
    registerUser() {
      this.loading = true; // Zobraz loader a deaktivuj tlačítko
      axios.post(`${process.env.VUE_APP_API_ADDRESS}api/register`, this.data)
        .then(response => {
          this.errorMessage = '';
          Cookies.set("authToken", response.data.token);
          window.location.reload();
        })
        .catch(error => {
          this.loading = false; // Znovu povol tlačítko po chybě
          if (error.response) {
            if (error.response.status === 400) {
              this.errorMessage = 'Špatné vstupní údaje, prosím zkontrolujte své údaje.';
            } else if (error.response.status === 401) {
              this.errorMessage = 'Tento e-mail je již zaregistrován. Zkuste jiný e-mail.';
            } else {
              this.errorMessage = 'Došlo k neočekávané chybě, zkuste to prosím znovu.';
            }
          } else {
            this.errorMessage = 'Došlo k problému s připojením, zkuste to prosím znovu.';
          }
        })
        .finally(() => {
          this.loading = false; // Když je request hotový, povol tlačítko znovu
        });
    }
  }
};
</script>
