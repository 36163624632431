<template>
  <div class="p-4">
    <h2 class="text-xl font-semibold mb-4 text-center">Ověřte svůj e-mail</h2>
    <p class="mb-4 text-center text-gray-600">
      Zadejte ověřovací kód, který jsme vám zaslali na <strong>{{ user.email }}</strong>.
    </p>

    <div class="flex space-x-2 mb-6 justify-center">
      <input
          v-for="(code, index) in codeInputs"
          :key="index"
          ref="inputs"
          type="text"
          maxlength="1"
          class="w-12 h-12 border border-gray-300 rounded-md text-center text-2xl focus:outline-none focus:ring-2 focus:ring-sky-500"
          v-model="codeInputs[index]"
          @input="onInput(index)"
          @keydown.backspace="onBackspace($event, index)"
          :aria-label="`Kód číslo ${index + 1}`"
      />
    </div>

    <div class="flex justify-center">
      <button
          @click="submitCode"
          class="px-4 py-2 cursor-pointer bg-sky-500 text-white rounded-md hover:bg-sky-600"
          :disabled="!isCodeComplete || loading"
      >
        Potvrdit e-mail
      </button>
    </div>

    <p v-if="errorMessage" class="text-red-500 mt-4 text-center">{{ errorMessage }}</p>
    <p v-if="successMessage" class="text-green-500 mt-4 text-center">{{ successMessage }}</p>

    <p class="mt-5 text-sm text-center text-gray-600">
      Nepřišel Vám e-mail?
      <a href="#" class="text-sky-600 underline" @click.prevent="resendCode">Zkuste znovu odeslat kód.</a>
      <br />
      <span class="pt-4 block">
        V případě přetrvávajících potíží nás kontaktujte na
        <a href="mailto:podpora@rilo.cz" class="text-sky-600 underline">podpora@rilo.cz</a>
      </span>
    </p>
  </div>
</template>

<script>
import { useUserStore } from '@/pinia/userStore';

export default {
  data() {
    return {
      codeInputs: ["", "", "", "", "", ""],
      errorMessage: "",
      successMessage: "",
      loading: false,
    };
  },
  computed: {
    user() {
      return useUserStore().user; // Získáme data o uživateli ze store
    },
    isCodeComplete() {
      return this.codeInputs.every(input => input !== "");
    },
  },
  methods: {
    // Při zadávání kódu se automaticky přesouváme na další pole
    onInput(index) {
      const currentInput = this.codeInputs[index];
      if (!/^\d$/.test(currentInput)) {
        this.codeInputs[index] = currentInput.replace(/\D/g, "");
      }
      if (this.codeInputs[index] && index < this.codeInputs.length - 1) {
        this.$refs.inputs[index + 1].focus();
      }
    },

    // Při mazání kódu zpět se posuneme zpět
    onBackspace(event, index) {
      if (event.key === "Backspace" && !this.codeInputs[index] && index > 0) {
        this.$refs.inputs[index - 1].focus();
      }
    },

    // Odeslání ověřovacího kódu
    async submitCode() {
      if (!this.isCodeComplete) return;
      this.loading = true;
      this.errorMessage = "";
      this.successMessage = "";
      const verificationCode = this.codeInputs.join(""); // Složíme kód

      try {
        // Odesíláme ověřovací kód na server
        await this.$axios.post("/verify-email", {
          verification_code: verificationCode,
        });
        this.successMessage = "E-mail úspěšně ověřen!";
        await useUserStore().fetchUserData(); // Aktualizujeme data o uživateli
      } catch (error) {
        if (error.response && error.response.data && error.response.data.message) {
          this.errorMessage = error.response.data.message;
        } else {
          this.errorMessage = "Chyba při ověřování kódu. Zkuste to prosím znovu.";
        }
      } finally {
        this.loading = false;
      }
    },

    // Znovu odešleme ověřovací kód
    async resendCode() {
      this.loading = true;
      this.errorMessage = "";
      this.successMessage = "";
      try {
        const response = await this.$axios.post("/resend-verification-code");
        this.successMessage = response.data.message || "Nový ověřovací kód byl odeslán na váš e-mail.";
      } catch (error) {
        if (error.response && error.response.data && error.response.data.message) {
          this.errorMessage = error.response.data.message;
        } else {
          this.errorMessage = "Chyba při odesílání kódu. Zkuste to prosím znovu.";
        }
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped>
.w-12 {
  width: 3rem;
  height: 3rem;
}

.focus\:outline-none:focus {
  outline: none;
}

.text-sky-600 {
  color: #0284c7;
}

.text-green-500 {
  color: #22c55e;
}

.text-red-500 {
  color: #ef4444;
}
</style>
