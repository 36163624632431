<template>
  <div class="space-y-6">
    <p class="text-center text-sm text-gray-500">
      Porovnáváme historický výnos ETF od roku 1993 - 2023. Historické výnosy nezaručují podobné výnosy v budoucnu!
      <strong> <br> Zdroj dat: curvo.eu </strong>
    </p>

    <!-- Sekce výběru ETF a nastavení -->
    <div class="bg-white mt-2 pl-6 pr-6 rounded-lg shadow-lg flex flex-col lg:flex-row lg:items-center lg:space-x-4">
      <div class="bg-white p-4">
        <h2 class="text-lg font-bold text-gray-800 mb-2">Celkově investovaná hotovost</h2>
        <p class="text-xl font-semibold text-sky-700">{{ formatCurrency(initialInvestment) }}</p>
      </div>

      <div class="flex-1 w-full">
        <label for="etf-select" class="block text-sm text-gray-700 font-semibold mb-2">Vyberte ETF k porovnání</label>
        <select
          id="etf-select"
          v-model="selectedEtf"
          @change="updateGraph"
          class="mt-1 block w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
        >
          <option v-for="etf in etfOptions" :key="etf.value" :value="etf.value">{{ etf.label }}</option>
        </select>
      </div>

      <!-- Šoupátko na roky -->
      <div class="flex-1 w-full mt-4 lg:mt-0">
        <label for="saleYear" class="block text-sm text-gray-700 font-semibold mb-2">Doba investování (roky)</label>
        <div class="flex items-center space-x-2">
          <input
            id="saleYear"
            type="range"
            :min="1"
            :max="30"
            v-model="saleYear"
            @change="updateGraph"
            class="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
          <span class="text-base font-bold text-sky-700">{{ saleYear }}</span>
        </div>
      </div>
    </div>

    <!-- Boxy s výnosy -->
    <div class="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4">
      <!-- Průměrné roční výnosy -->
      <div
        class="p-4 rounded-lg shadow-lg bg-gradient-to-r from-blue-500 to-blue-600 text-white text-center flex flex-col justify-center">
        <h3 class="text-sm font-medium mb-1">Průměrný roční výnos ETF</h3>
        <p class="text-2xl font-semibold">{{ etfYieldAverage }} %</p>
      </div>
      <div
        class="p-4 rounded-lg shadow-lg bg-gradient-to-r from-green-500 to-green-600 text-white text-center flex flex-col justify-center">
        <h3 class="text-sm font-medium mb-1">Průměrný roční výnos nemovitosti</h3>
        <p class="text-2xl font-semibold">{{ propertyYieldAverage }} %</p>
      </div>

      <!-- Kumulativní výnosy -->
      <div
        class="p-4 rounded-lg shadow-lg bg-gradient-to-r from-blue-500 to-blue-600 text-white text-center flex flex-col justify-center">
        <h3 class="text-sm font-medium mb-1">Kumulativní výnos ETF</h3>
        <p class="text-2xl font-semibold">{{ etfYield }} %</p>
      </div>
      <div
        class="p-4 rounded-lg shadow-lg bg-gradient-to-r from-green-500 to-green-600 text-white text-center flex flex-col justify-center">
        <h3 class="text-sm font-medium mb-1">Kumulativní výnos nemovitosti</h3>
        <p class="text-2xl font-semibold">{{ propertyYield }} %</p>
      </div>

      <!-- Konečná hodnota investice -->
      <div
        class="p-4 rounded-lg shadow-lg bg-gradient-to-r from-blue-500 to-blue-600 text-white text-center flex flex-col justify-center">
        <h3 class="text-sm font-medium mb-1">Konečná hodnota ETF investice</h3>
        <p class="text-2xl font-semibold">{{ formatCurrency(finalEtfValue) }}</p>
      </div>
      <div
        class="p-4 rounded-lg shadow-lg bg-gradient-to-r from-green-500 to-green-600 text-white text-center flex flex-col justify-center">
        <h3 class="text-sm font-medium mb-1">Konečná hodnota nemovitosti</h3>
        <p class="text-2xl font-semibold">{{ formatCurrency(finalPropertyValue) }}</p>
      </div>
    </div>

    <!-- Graf -->
    <div v-if="showGraph" style="height: 350px;">
      <ChartExpectedDevelop :chartData="chartData" :chartOptions="chartOptions"/>
    </div>
  </div>
</template>

<script>
import {InvestmentService} from "@/functions/investmentService";
import ChartExpectedDevelop from "@/components/graphs/ChartExpectedDevelop.vue";

export default {
  name: "CompareEtfContent",

  components: {
    ChartExpectedDevelop,
  },

  props: {
    property: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      saleYear: 20,
      selectedEtf: "sp500",
      showGraph: true,
      etfOptions: [
        {
          value: "sp500",
          label: "iShares Core S&P 500 UCITS ETF (Acc)",
          annualReturns: [
            0.2057720197316651, -0.05756257258872819, 0.30703719107144, 0.26139341122851334,
            0.4892669462145026, 0.21527673708950396, 0.4154847824890744, -0.019348491367248005,
            -0.07032334075258692, -0.3458289737342163, 0.06775545601829634, 0.027430004946561847,
            0.2104951987322311, 0.03650067009816125, -0.0568724459426965, -0.3340644811298396,
            0.22087661511690598, 0.23968222721860613, 0.05376532627982266, 0.136824631276807,
            0.2657056909025382, 0.29050491216129826, 0.12983054358671964, 0.15554930734276592,
            0.07006850749078197, 0.0008006285667985912, 0.3392293259892098, 0.08317650626409917,
            0.39349123617025067, -0.13105335842860602, 0.2181508500723533
          ]
        },
        {
          value: "msci",
          label: "iShares Core MSCI World UCITS ETF USD (Acc)",
          annualReturns: [
            0.34016440088665284, -0.023901358797854554, 0.1454033965094742, 0.1626286479666802,
            0.29107322147763914, 0.17368504810601182, 0.45917645789729955, -0.06452881048423974,
            -0.12358149772300202, -0.32813676909615885, 0.10302486187582041, 0.06162845002127622,
            0.26165971716682707, 0.07335233169621774, -0.026479388726178898, -0.374179590032255,
            0.2532941408762244, 0.20258432148533426, -0.026477162086352212, 0.13362778821238178,
            0.20954464460739625, 0.1896332344403991, 0.10327389256755717, 0.10819408781829107,
            0.07367555492007964, -0.045741689678722726, 0.2987033300818221, 0.05896328181647847,
            0.31723446482957185, -0.1325221727140903, 0.19250067961174902
          ]
        },
        {
          value: "euro",
          label: "iShares Core EURO STOXX 50 UCITS ETF (Acc)",
          annualReturns: [
            0.43431090527343225, -0.05070805496074317, 0.17845870298621228, 0.26340166230624096,
            0.39948792880099776, 0.3476455829776212, 0.4849457947483824, -0.018018490459963413,
            -0.19229652768972838, -0.3585068894374464, 0.1770348547315704, 0.09273024677894623,
            0.2418060989979001, 0.17929156341709052, 0.09483755532670762, -0.42458982454803296,
            0.2552634602744117, -0.029121215278931526, -0.14188423970833564, 0.17940132087218622,
            0.21386043109474248, 0.0390460729226642, 0.06309306917190553, 0.036116709095257264,
            0.09043939267841755, -0.12114580413423064, 0.28076014467464955, -0.03302405178047779,
            0.23222308295682645, -0.09581007735068343, 0.2210751655959669
          ]
        },
      ],
    };
  },

  computed: {
    initialInvestment() {
      return InvestmentService.calculateTotalInvestedCash(this.property, this.saleYear);
    },
    investmentsOverTime() {
      return InvestmentService.calculateTotalInvestedCashOverTime(this.property, this.saleYear);
    },
    propertyYieldAverage() {
      const years = this.saleYear;
      const totalProfit = InvestmentService.calculateTotalProfit(this.property, years);
      const cagr = Math.pow(1 + totalProfit / this.initialInvestment, 1 / years) - 1;
      return (cagr * 100).toFixed(2);
    },
    propertyYield() {
      const totalProfit = InvestmentService.calculateTotalProfit(this.property, this.saleYear);
      const totalReturn = totalProfit / this.initialInvestment;
      return (totalReturn * 100).toFixed(2);
    },
    selectedEtfData() {
      return this.etfOptions.find((etf) => etf.value === this.selectedEtf);
    },
    etfYieldAverage() {
      const finalValue = this.finalEtfValue;
      const cagr = Math.pow(finalValue / this.initialInvestment, 1 / this.saleYear) - 1;
      return (cagr * 100).toFixed(2);
    },
    etfYield() {
      const finalValue = this.finalEtfValue;
      const totalReturn = (finalValue - this.initialInvestment) / this.initialInvestment;
      return (totalReturn * 100).toFixed(2);
    },
    finalEtfValue() {
      const returns = this.selectedEtfData.annualReturns.slice(0, this.saleYear);
      let finalValue = 0;
      for (let i = 0; i < this.saleYear; i++) {
        const investment =
          i === 0
            ? this.investmentsOverTime[i]
            : this.investmentsOverTime[i] - this.investmentsOverTime[i - 1];
        let accumulatedValue = investment;
        for (let j = i; j < this.saleYear; j++) {
          accumulatedValue *= 1 + returns[j];
        }
        finalValue += accumulatedValue;
      }
      return finalValue.toFixed(2);
    },
    finalPropertyValue() {
      const totalProfit = InvestmentService.calculateTotalProfit(this.property, this.saleYear);
      const finalValue = this.initialInvestment + totalProfit;
      return finalValue.toFixed(2);
    },
    chartData() {
      const years = Array.from({length: this.saleYear}, (_, i) => i + 1);
      const labels = years.map((year) => `${year}. rok`);

      const returns = this.selectedEtfData.annualReturns.slice(0, this.saleYear);

      const etfData = [];
      const propertyData = [];
      const investedFundsData = [];

      for (let i = 0; i < this.saleYear; i++) {
        investedFundsData.push(this.investmentsOverTime[i]);

        let etfAccumulated = 0;
        for (let k = 0; k <= i; k++) {
          const investment =
            k === 0
              ? this.investmentsOverTime[k]
              : this.investmentsOverTime[k] - this.investmentsOverTime[k - 1];
          let accumulatedValue = investment;
          for (let j = k; j <= i; j++) {
            accumulatedValue *= 1 + returns[j];
          }
          etfAccumulated += accumulatedValue;
        }
        etfData.push(etfAccumulated.toFixed(2));

        const propertyValue =
          InvestmentService.calculateTotalInvestedCash(this.property, i + 1) +
          InvestmentService.calculateTotalProfit(this.property, i + 1);
        propertyData.push(propertyValue.toFixed(2));
      }

      return {
        labels: labels,
        datasets: [
          {
            label: "ETF Portfolio",
            data: etfData,
            borderColor: "rgba(59, 130, 246, 1)",
            backgroundColor: "rgba(59, 130, 246, 0.2)",
            fill: true,
          },
          {
            label: "Nemovitost",
            data: propertyData,
            borderColor: "rgba(34, 197, 94, 1)",
            backgroundColor: "rgba(34, 197, 94, 0.2)",
            fill: true,
          },
          {
            label: "Investované prostředky",
            data: investedFundsData,
            borderColor: "rgba(75, 85, 99, 1)",
            backgroundColor: "rgba(75, 85, 99, 0.2)",
            fill: true,
          },
        ],
      };
    },
    chartOptions() {
      return {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          y: {
            ticks: {
              callback: function (value) {
                return value.toLocaleString("cs-CZ", {
                  style: "currency",
                  currency: "CZK",
                  maximumFractionDigits: 0,
                });
              },
            },
          },
        },
      };
    },
  },

  methods: {
    updateGraph() {
      this.showGraph = false;
      setTimeout(() => {
        this.showGraph = true;
      }, 100);
    },
    formatCurrency(value) {
      return new Intl.NumberFormat("cs-CZ", {
        style: "currency",
        currency: "CZK",
      }).format(value);
    },
  },
};
</script>

<style scoped>
/* Vlastní styly pro moderní vzhled */
</style>
