<template>
  <div class="flex flex-col lg:flex-row gap-8 pt-20 ">
    <!-- Kalkulačka nájemného -->
    <div class="rent-calculator bg-white p-6 rounded-lg w-full lg:w-1/2">
      <div class="time-calculator__wrap-title mb-6">
        <h3 class="text-2xl font-bold mb-2">Kalkulačka nájemného</h3>
        <p class="text-gray-600">
          Potřebujete znát rozmezí nájemného pro Váš byt? <br />
          Zadejte pár parametrů a dozvíte se ideální výši.
        </p>
      </div>

      <form class="space-y-7" @submit.prevent="calculateRent">
        <!-- Adresa -->
        <div>
          <label for="address-input" class="block mb-2 text-sm font-medium text-gray-900">
            Zadejte adresu:
          </label>
          <div class="relative">
            <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
              <font-awesome-icon icon="map-marker-alt" class="text-gray-400" />
            </div>
            <input
                type="text"
                id="address-input"
                v-model="query"
                @input="fetchSuggestions"
                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-sky-500 focus:border-sky-500 block w-full pl-10 p-2.5"
                placeholder="Zadejte adresu"
                required
            />
            <!-- Suggestions -->
            <ul
                v-if="suggestions.length"
                class="absolute z-10 w-full bg-white border border-gray-300 rounded-lg mt-1 max-h-60 overflow-auto"
            >
              <li
                  v-for="(suggestion, index) in suggestions"
                  :key="index"
                  @click="selectSuggestion(suggestion)"
                  class="p-2 cursor-pointer hover:bg-gray-100"
              >
                {{ suggestion.display_name }}
              </li>
            </ul>
          </div>
        </div>

        <!-- Dispozice bytu a Užitková plocha bytu -->
        <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
          <!-- Dispozice bytu -->
          <div>
            <label for="disposition-select" class="block mb-2 text-sm font-medium text-gray-900">
              Dispozice bytu:
            </label>
            <select
                id="disposition-select"
                v-model="disposition"
                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-sky-500 focus:border-sky-500 block w-full p-2.5"
                required
            >
              <option disabled value="">Vyberte dispozici</option>
              <option value="1+kk">1+kk</option>
              <option value="1+1">1+1</option>
              <option value="2+kk">2+kk</option>
              <option value="2+1">2+1</option>
              <option value="3+kk">3+kk</option>
              <option value="3+1">3+1</option>
              <option value="4+kk">4+kk</option>
              <option value="4+1">4+1</option>
              <option value="5+kk">5+kk</option>
              <option value="5+1">5+1</option>
              <option value="6+1">6+1</option>
              <option value="atypicky">Atypický</option>
            </select>
          </div>
          <!-- Užitková plocha bytu -->
          <div>
            <label for="plocha-input" class="block mb-2 text-sm font-medium text-gray-900">
              Užitková plocha bytu (m²):
            </label>
            <input
                type="number"
                id="plocha-input"
                v-model="usableArea"
                min="1"
                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-sky-500 focus:border-sky-500 block w-full p-2.5"
                placeholder="Zadejte užitkovou plochu"
                required
            />
          </div>
        </div>

        <!-- Stav bytu -->
        <div>
          <label for="stav-select" class="block mb-2 text-sm font-medium text-gray-900">
            Stav bytu:
          </label>
          <select
              id="stav-select"
              v-model="condition"
              class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-sky-500 focus:border-sky-500 block w-full p-2.5"
              required
          >
            <option disabled value="">Vyberte stav bytu</option>
            <option value="Před rekonstrukcí">Před rekonstrukcí</option>
            <option value="Dobrý">Dobrý</option>
            <option value="Po rekonstrukci">Po rekonstrukci</option>
            <option value="Novostavba">Novostavba</option>
          </select>
        </div>

        <!-- Tlačítko Vypočítat -->
        <div class="text-center">
          <button
              type="submit"
              class="bg-sky-500 hover:bg-sky-600 text-white font-semibold py-2 mt-4 px-6 rounded-lg flex items-center justify-center"
              :disabled="isLoading"
          >
            <span v-if="isLoading">
              <font-awesome-icon icon="spinner" spin class="mr-2" /> Výpočet...
            </span>
            <span v-else>Vypočítat výši nájemného</span>
          </button>
        </div>
      </form>
    </div>

    <!-- Výsledek -->
    <div class="bg-white p-6 rounded-lg w-full lg:w-1/2">
      <template v-if="result">
        <h3 class="text-sm uppercase text-center font-semibold mb-4">
          Optimální nájemné je podle našich odhadů:
        </h3>
        <h2 class="text-5xl text-center font-semibold mb-4" v-if="result.average_rent">
          {{ roundToNearestFiveHundred(result.average_rent) }},- Kč
        </h2>
        <h2 class="text-4xl text-center font-semibold mb-4" v-else>
          Nemůžeme odhadnout 😔
        </h2>
        <div v-if="result">
          <!-- Pie Chart -->
          <div class="flex justify-center items-center">
            <div class="w-full md:w-1/2">
              <PriorityPieChart :flats="result.flats" />
            </div>
          </div>

          <!-- Example Flat Data -->
          <div class="bg-gray-50 p-6 rounded-lg shadow-lg mt-4 max-w-md mx-auto">
            <p class="text-lg font-semibold text-gray-800 mb-2">Mezi daty ve výpočtu byl např. byt:</p>
            <p class="text-sm text-gray-600">
              S užitnou plochou: <span class="font-medium">{{ result.flats[0].usable_area }} m²</span>
            </p>
            <p class="text-sm text-gray-600">
              Dispozicí: <span class="font-medium">{{ result.flats[0].disposition }}</span>
            </p>
            <p class="text-sm text-gray-600">
              V lokalitě: <span class="font-medium">{{ result.flats[0].locality }}</span>
            </p>
            <p class="text-sm text-gray-600">
              Který se pronajímal za:
              <span class="font-medium text-green-600">{{ result.flats[0].price }} Kč</span>
            </p>
          </div>

          <!-- Rent Range Chart -->
          <div class="flex justify-center items-center mt-6">
            <div class="w-full md:w-3/4">
              <RentRange
                  :minRent="roundToNearestFiveHundred(result.min_rent)"
                  :maxRent="roundToNearestFiveHundred(result.max_rent)"
                  :averageRent="roundToNearestFiveHundred(result.average_rent)"
              />
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="flex h-full justify-center items-center">
          <p>Vypočítejte si ideální výši nájmu</p>
        </div>
      </template>
    </div>
  </div>
</template>
<script>
import axios from 'axios';
import PriorityPieChart from "@/pages/Apps/Charts/PriorityPieChart.vue";
import RentRange from "@/pages/Apps/Charts/RentRange.vue";

export default {
  name: "RentCalculator",
  components: { RentRange, PriorityPieChart },
  data() {
    return {
      query: '',
      disposition: '',
      usableArea: '',
      condition: '',
      result: null,
      suggestions: [],
      isLoading: false,
    };
  },

  methods: {
    roundToNearestFiveHundred(value) {
      return Math.ceil(value / 500) * 500;
    },

    async fetchSuggestions() {
      if (this.query.length < 3) {
        this.suggestions = [];
        return;
      }

      try {
        const response = await axios.get('https://nominatim.openstreetmap.org/search', {
          params: {
            q: this.query,
            format: 'json',
            addressdetails: 1,
            limit: 5,
            countrycodes: 'cz'
          }
        });
        this.suggestions = response.data;
      } catch (error) {
        console.error('Error fetching suggestions:', error);
      }
    },
    selectSuggestion(suggestion) {
      this.query = suggestion.display_name;
      this.suggestions = [];
    },
    async calculateRent() {
      if (!this.query || !this.disposition || !this.usableArea || !this.condition) {
        alert('Prosím vyplňte všechna pole.');
        return;
      }

      this.isLoading = true;
      try {
        const response = await this.$axios.post('/rent-amount', {
          address: this.query,
          disposition: this.disposition,
          usable_area: this.usableArea,
          condition: this.condition,
        });

        this.result = response.data;
      } catch (error) {
        console.error('Error calculating rent:', error);
        this.result = null;
      } finally {
        this.isLoading = false;
      }
    }
  }
}
</script>
