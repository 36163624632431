<template>
  <div v-if="isLoading">
    <div class="flex justify-end">
      <button
        @click="saveData"
        type="button"
        class="flex rounded-lg  right-auto sm:inline-flex items-center justify-center text-white bg-green-600 hover:bg-green-700 font-medium text-sm px-3 py-3"
      >
        <svg class="mr-2 w-4 h-4 invert" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
          <path
            d="M15.004 3h2.996v5h-2.996v-5zm8.996 1v20h-24v-24h20l4 4zm-19 5h14v-7h-14v7zm16 4h-18v9h18v-9zm-2 2h-14v1h14v-1zm0 2h-14v1h14v-1z"/>
        </svg>
        Uložit šablonu
      </button>
    </div>
    <div class="p-4">


      <label class="block text-gray-700 font-semibold">Jméno pro šablonu:
        <input
          type="text"
          v-model="form.name"
          placeholder="Název šablony"
          class="mt-1 block font-normal w-full border border-gray-300 rounded-md p-2 focus:ring focus:ring-blue-200">
      </label>

      <div class="mt-10">
        <h3 class="text-xl text-center pb-3 mb-7 border-b-2 pl-2 font-semibold text-gray-800 mb-1">
          Nákup a opravy
        </h3>
        <h3 class="text-xl pl-2 font-semibold text-gray-800 mb-1">
          <font-awesome-icon icon="sack-dollar" size="xs"/>
          Základní údaje o nemovitosti
        </h3>
        <div class="bg-gray-100 p-4 rounded-lg space-y-4">
          <AcquisitionCosts v-model="form.other_initial_costs"/>
        </div>

        <br>
        <div class="mt-10">
          <rehab-costs
            v-model="form.repair_costs"
            :is-multiple-repair="Boolean(form.is_multiple_repair)"
            @toggleRehabCosts="toggleRehabCosts"
          />
        </div>

        <div class="mt-10">
          <financing-items
            type="template"
            v-model="form.financing"
            :isMultipleFinancing="Boolean(form.is_multiple_financing)"
            @update:isMultipleFinancing="form.is_multiple_financing = $event"
          />
        </div>


        <div class="mt-10">
          <h3 class="text-xl text-center pb-3 mb-7 border-b-2 pl-2 font-semibold text-gray-800">
            Příjmy a výdaje
          </h3>
          <rent-and-costs
            v-model="form"
            property-category="rent"
          />
        </div>

        <div class="mt-10">
          <h3 class="text-xl text-center pb-3 mb-7 border-b-2 pl-2 font-semibold text-gray-800">
            Budoucí vývoj
          </h3>
          <long-term-projections
            property-category="rent"
            v-model="form"
          />
        </div>

        <div class="mt-10">
          <h3 class="text-xl text-center pb-3 mb-7 border-b-2 pl-2 font-semibold text-gray-800">
            Daňová optimalizace
          </h3>
          <property-taxes
            property-category="rent"
            v-model="form"
          />
        </div>

      </div>

    </div>
  </div>
</template>
<script>
import AcquisitionCosts from "@/components/forms-items/AcquisitionCosts.vue";
import RehabCosts from "@/components/forms-items/RehabCosts.vue";
import FinancingItems from "@/components/forms-items/FinancingItems.vue";
import RentAndCosts from "@/components/forms/RentAndCosts.vue";
import LongTermProjections from "@/components/forms/LongTermProjections.vue";
import PropertyTaxes from "@/components/forms/PropertyTaxes.vue";

export default {
  components: {
    PropertyTaxes,
    LongTermProjections,
    RentAndCosts,
    FinancingItems,
    RehabCosts,
    AcquisitionCosts,
  },


  methods: {
    toggleRehabCosts() {
      this.form.is_multiple_repair = !this.form.is_multiple_repair;
    },

    saveData() {
      const id = this.$route.params.id
      if (!id) {
        this.$axios.post(`/template-properties`, this.form)
          .then(() => {
            this.$flashMessage.success("Šablona byla vytvořena");
            this.$router.push({name: 'TemplateSetting'})
          })
          .catch(error => {
            console.error('Error saving template:', error);
          });
      } else {
        this.$axios.put(`/template-properties/` + id, this.form)
          .then(() => {
            this.$flashMessage.success("Změny byly úspěšně uloženy");
            this.$router.push({name: 'TemplateSetting'})
          })
          .catch(error => {
            console.error('Error saving template:', error);
          });
      }
    },

    fetchData(id) {
      this.$axios.get(`/template-properties/${id}`)
        .then(response => {
          this.form = response.data;
          this.isLoading = true;
        })
        .catch(error => {
          console.error('Error fetching template:', error);
          this.$router.push({name: 'TemplateSetting'})
        });
    },

    checkForId() {
      const id = this.$route.params.id;
      if (id) {
        this.fetchData(id);
      } else {
        this.isLoading = true;
      }
    }
  },

  data() {
    return {
      isLoading: false,
      form: {
        category: 'rent',
        name: "",
        after_repair_value: null,
        other_initial_costs: 0,
        financing: [
          {
            name: 'Financování',
            loan_amount: null,
            amount_type: 0,
            amount: null,
            investment_percentage: 90,
            loan_term: 30,
            interest_rate: 5.1,
            monthly_insurance: 0,
            change_interest_rate_year: 5,
            change_interest_rate: 5.1,
          }
        ],
        gross_rent: null,
        vacancy_rate: null,
        other_income: [
          {name: 'Další příjmy z nemovitosti', amount: 0}
        ],
        operating_expenses: [
          {name: 'Provozní náklady na nemovitost', amount: 0}
        ],
        repair_costs: [
          {name: 'Náklady na rekonstrukci', amount: 0}
        ],
        property_appreciation: null,
        income_growth: null,
        expense_growth: null,
        selling_costs: null,
        property_tax_rate: null,
        depreciation_term: null,
        total_taxes_cost: null,
        is_multiple_financing: true,
        is_multiple_repair: false,
        is_multiple_income: false,
        is_multiple_operating: false,
        is_flat_tax: true,
        estimated_investment_time: 10,
        tax_rate: 15,
      },
      multiple: false,
    };
  },

  mounted() {
    this.checkForId();
  }
};
</script>
