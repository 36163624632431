<template>
  <section class="bg-sky-500 md:bg-sky-700 h-screen flex">
    <!-- Left side: Logo and Character -->
    <div class="hidden md:flex md:w-1/2 justify-center items-center bg-sky-600">
      <div class="flex flex-col items-center">
        <img src="/images/big-single-logo-white.svg" alt="Valuo Logo" class="mb-4 w-36" />
        <p class="text-white text-4xl font-bold mb-2">Analýza nemovitostí</p>
        <p class="text-white text-lg font-light">Na jedno kliknutí!</p>
      </div>
    </div>

    <!-- Right side: Login form -->
    <div class="w-full md:w-1/2 flex justify-center items-center bg-white">
      <div class="w-full max-w-md p-8 space-y-6">
        <h1 class="text-2xl font-bold text-gray-900 text-center">Přihlásit se</h1>

        <!-- Zde zobrazíme chybovou zprávu -->
        <p v-if="errorMessage" class="text-red-500 text-center">{{ errorMessage }}</p>

        <form @submit.prevent="loginUser" class="space-y-6">
          <div>
            <label for="email" class="block mb-2 text-sm font-medium text-gray-900">E-mail</label>
            <input
                v-model="data.email"
                type="email"
                id="email"
                class="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                placeholder="email@email.cz"
                required
            />
          </div>
          <div>
            <label for="password" class="block mb-2 text-sm font-medium text-gray-900">Heslo</label>
            <input
                v-model="data.password"
                type="password"
                id="password"
                placeholder="••••••••"
                class="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                required
            />
          </div>
          <div class="flex items-center justify-between">
            <div class="flex items-start">
              <div class="flex items-center h-5">
                <input
                    id="remember"
                    type="checkbox"
                    class="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-primary-300"
                    required
                />
              </div>
              <div class="ml-3 text-sm">
                <label for="remember" class="text-gray-500">Zapamatujte si mě</label>
              </div>
            </div>
            <router-link :to="{name: 'ForgotPassword'}" class="text-sm font-medium text-primary-600 hover:underline">Zapomenuté heslo?</router-link>
          </div>
          <button :disabled="loading" type="submit" class="w-full text-white bg-sky-500 hover:bg-sky-600 focus:ring-4 focus:outline-none focus:ring-sky-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center flex justify-center items-center">
            <span v-if="loading" class="mr-2">
              <font-awesome-icon icon="spinner" spin />
            </span>
            Přihlásit se
          </button>
          <p class="text-sm font-light text-gray-500">
            Ještě nemáte účet?
            <router-link :to="{name: 'RegisterPage'}" class="font-medium text-primary-600 hover:underline">
              Vytvořte si ho zde
            </router-link>
          </p>
        </form>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";
import Cookies from 'js-cookie';

export default {
  name: "LoginPage",
  data() {
    return {
      data: {
        email: '',
        password: '',
      },
      errorMessage: '',
      loading: false,
    };
  },
  methods: {
    loginUser() {
      this.loading = true;
      const rememberMe = document.getElementById('remember').checked;
      const cookieExpiration = rememberMe ? 30 : 1;

      axios.post(`${process.env.VUE_APP_API_ADDRESS}api/login`, this.data)
          .then(response => {
            this.errorMessage = '';
            Cookies.set('authToken', response.data.token, {expires: cookieExpiration});

            window.postMessage({
              type: 'SET_AUTH_TOKEN',
              token: response.data.token
            }, '*');

            window.location.reload();
          })
          .catch(error => {
            if (error.response && error.response.status === 401) {
              this.errorMessage = 'Zadané údaje nejsou správné';
            } else {
              this.errorMessage = 'Došlo k neočekávané chybě, zkuste to prosím znovu';
            }
          })
          .finally(() => {
            this.loading = false; // Když je request hotový, povol tlačítko znovu
          });
    }
  },
};
</script>
