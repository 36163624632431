<template>
  <div class="p-6 bg-white rounded-lg shadow-md">
    <h1 class="text-2xl font-bold mb-4">Zásady ochrany osobních údajů</h1>
    <p class="mb-4">Tyto zásady ochrany osobních údajů jsou platné od 19. 7. 2023 a vztahují se na používání aplikace Rilo.cz a rozšíření prohlížeče Rilo.cz.</p>

    <h2 class="text-xl font-semibold mt-4 mb-2">Provozovatel</h2>
    <p class="mb-4">Správcem osobních údajů je:</p>
    <ul class="list-disc ml-6 mb-4">
      <li>Jméno: Vojtěch Vaněk</li>
      <li>Sídlo: Tlumačov</li>
      <li>IČO: 19541791</li>
      <li>Email: vojtech@rilo.cz</li>
    </ul>

    <h2 class="text-xl font-semibold mt-4 mb-2">Úvodní ustanovení</h2>
    <p class="mb-4">
      Provozovatel jakožto správce osobních údajů podle čl. 4 bod 7 nařízení Evropského parlamentu a Rady (EU) 2016/679 o ochraně fyzických osob v souvislosti se zpracováním osobních údajů (dále jen: „GDPR“), zpracovává osobní údaje uživatelů za níže uvedených podmínek.
    </p>

    <h2 class="text-xl font-semibold mt-4 mb-2">Zdroje a kategorie zpracovávaných osobních údajů</h2>
    <ul class="list-disc ml-6 mb-4">
      <li>Správce zpracovává osobní údaje, které jste mu poskytl/a při registraci do aplikace Rilo.cz.</li>
      <li>Zpracovávány jsou identifikační a kontaktní údaje nezbytné pro plnění smlouvy.</li>
    </ul>

    <h2 class="text-xl font-semibold mt-4 mb-2">Zákonný důvod a účel zpracování osobních údajů</h2>
    <ul class="list-disc ml-6 mb-4">
      <li>Plnění smlouvy mezi Vámi a správcem podle čl. 6 odst. 1 písm. b) GDPR.</li>
      <li>Oprávněný zájem správce na poskytování přímého marketingu dle čl. 6 odst. 1 písm. f) GDPR.</li>
      <li>Váš souhlas se zpracováním osobních údajů pro účely marketingu dle čl. 6 odst. 1 písm. a) GDPR.</li>
    </ul>

    <h2 class="text-xl font-semibold mt-4 mb-2">Účel zpracování osobních údajů</h2>
    <ul class="list-disc ml-6 mb-4">
      <li>Vyřízení Vaší registrace a poskytování služeb aplikace Rilo.cz.</li>
      <li>Zasílání obchodních sdělení a marketingových nabídek.</li>
    </ul>

    <h2 class="text-xl font-semibold mt-4 mb-2">Doba uchovávání údajů</h2>
    <ul class="list-disc ml-6 mb-4">
      <li>Osobní údaje budou uchovávány po dobu trvání smluvního vztahu a dále po dobu 15 let od jeho ukončení.</li>
      <li>Pro marketingové účely budou údaje uchovávány do odvolání souhlasu, nejdéle 10 let.</li>
    </ul>

    <h2 class="text-xl font-semibold mt-4 mb-2">Příjemci osobních údajů</h2>
    <p class="mb-4">Osobní údaje mohou být předány třetím stranám, které zajišťují technické služby spojené s provozem aplikace Rilo.cz, jako jsou poskytovatelé IT služeb nebo platební brány.</p>

    <h2 class="text-xl font-semibold mt-4 mb-2">Práva uživatelů</h2>
    <ul class="list-disc ml-6 mb-4">
      <li>Právo na přístup k osobním údajům dle čl. 15 GDPR.</li>
      <li>Právo na opravu osobních údajů dle čl. 16 GDPR.</li>
      <li>Právo na výmaz osobních údajů dle čl. 17 GDPR.</li>
      <li>Právo na omezení zpracování dle čl. 18 GDPR.</li>
      <li>Právo vznést námitku proti zpracování dle čl. 21 GDPR.</li>
      <li>Právo na přenositelnost údajů dle čl. 20 GDPR.</li>
      <li>Právo odvolat souhlas se zpracováním údajů kdykoli písemně na adresu nebo e-mail provozovatele.</li>
    </ul>

    <h2 class="text-xl font-semibold mt-4 mb-2">Podmínky zabezpečení osobních údajů</h2>
    <ul class="list-disc ml-6 mb-4">
      <li>Správce přijal vhodná technická a organizační opatření k zabezpečení osobních údajů.</li>
      <li>Správce zajišťuje, že k osobním údajům mají přístup pouze pověřené osoby.</li>
    </ul>

    <h2 class="text-xl font-semibold mt-4 mb-2">Cookies</h2>
    <p class="mb-4">Při používání webové aplikace Rilo.cz dochází k ukládání souborů cookies do vašeho zařízení. Cookies slouží k zajištění základních funkcionalit webu a pro analytické účely.</p>

    <h2 class="text-xl font-semibold mt-4 mb-2">Závěrečná ustanovení</h2>
    <ul class="list-disc ml-6 mb-4">
      <li>Registrací na Rilo.cz potvrzujete, že jste seznámen/a s podmínkami ochrany osobních údajů a že je přijímáte.</li>
      <li>Správce může tyto podmínky změnit. Novou verzi podmínek zveřejní na svých webových stránkách a informuje vás prostřednictvím e-mailu.</li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "PrivacyPolicy"
}
</script>
