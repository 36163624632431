<template>
  <div class="w-full">

  <div class="max-w-xl mx-auto p-2 my-8 bg-white">
    <h1 class="text-3xl font-extrabold mb-6 text-gray-800">AI Vám pomůže s vyplněním</h1>
    <p class="text-lg mb-4 text-gray-600">Popište libovolným způsobem vše, co o nemovitosti víte. AI se postará o zbytek.</p>

    <div class="mb-4">
      <textarea
          v-model="userMessage"
          class="w-full h-64 p-4 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
          placeholder="Zadejte své informace zde..."
      ></textarea>
    </div>

    <button
        @click="sendRequest"
        class="w-full bg-sky-500 text-white font-semibold py-2 px-4 rounded-lg hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
    >
      Zpracovat informace
    </button>

    <div v-if="responseMessage" class="mt-6 p-4 bg-gray-100 rounded-lg text-gray-800">
      <h2 class="text-xl font-bold mb-2">Odpověď od AI:</h2>
      <pre class="whitespace-pre-wrap">{{ responseMessage }}</pre>
    </div>
  </div>
    <div class="flex justify-center items-center flex-col fixed top-0 left-0 w-full h-screen overlay-bg" v-if="loading">
      <font-awesome-icon icon="pencil" class="text-gray-50 mb-2 pencil-animation" size="2x" />
      <h2 class="text-gray-50 text-2xl font-bold">Zapisuji data do aplikace...</h2>
    </div>
  </div>
</template>

<script>
import {useUserStore} from "@/pinia/userStore";

export default {
  name: "AiFillWindow",

  data() {
    return {
      userMessage: "",
      responseMessage: "",
      loading: false,
    };
  },

  props:{
    data:{
      required: true,
      type: Object
    }
  },

  methods: {
    async sendRequest() {
      this.loading = true;
      this.$axios.post('/ai/fill', {
        userData: this.userMessage,
        userJson: JSON.stringify(this.data),
      })
      .then(response => {
        this.responseMessage = response.data;
        const parsedData = typeof this.responseMessage === 'string' ? JSON.parse(this.responseMessage) : this.responseMessage;
        this.$emit('getData', parsedData);
        useUserStore().fetchUserCredits();
        this.loading = false;
      })
      .catch(error => {
            this.responseMessage = "Nastala chyba při odesílání požadavku.";
            console.error('Request error:', error);
      })
    }

  },
};
</script>


<style scoped>

@keyframes write {
  0% {
    transform: translate(0, 0);
  }
  25% {
    transform: translate(150px, -18px);
  }
  50% {
    transform: translate(0, 2px);
  }
  75% {
    transform: translate(-150px, -3px);
  }
  100% {
    transform: translate(0, 0);
  }
}

.overlay-bg{
  background-color: rgba(0, 0, 0, 0.42);
}

.pencil-animation {
  animation: write 2s infinite ease-in-out;
}

</style>
