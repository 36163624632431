<template>
  <header class="antialiased sticky top-0 z-50">
    <nav class="bg-gray-900 border-gray-200 px-4 lg:px-6">
      <div class="flex flex-wrap justify-between items-center max-w-screen-xl m-auto">
        <div class="flex justify-start items-center">
          <button @click="toggleMenu" aria-expanded="true" aria-controls="sidebar" class="p-2 mr-2 text-gray-600 rounded-lg cursor-pointer lg:hidden hover:text-gray-900 hover:bg-gray-100 focus:bg-gray-100  focus:ring-2 focus:ring-gray-100    ">
            <svg class="w-[18px] h-[18px]" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14"><path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 1h15M1 7h15M1 13h15"/></svg>
            <span class="sr-only">Přepínač</span>
          </button>
          <router-link :to="{name: 'AppPage'}" class="flex mr-4">
            <img src="/logo.png" alt="logo" class="w-20">
          </router-link>
          <div :class="{
            'hidden lg:flex': !isMenuOpen,
            'block absolute top-full w-full bg-gray-700 left-0 lg:relative lg:flex': isMenuOpen
          }" class="lg:ml-10 lg:flex lg:w-auto">
            <router-link
                :to="{name: 'PlannProperties'}"
                href="#"
                active-class="active-link"
                class="block w-full non-active-link lg:w-auto text-gray-300 hover:bg-gray-800  border-gray-600 px-4 lg:border-none lg:px-3 lg:py-4 lg:inline-block whitespace-nowrap">
              Seznam nemovitostí <!-- Plánované nemovitosti -->
            </router-link>

            <router-link
                :to="{name: 'PropertyCompare'}"
                active-class="active-link"
                class="block w-full non-active-link lg:w-auto text-gray-300 hover:bg-gray-800  px-4 lg:pl-3 py-2 lg:border-b-0 lg:px-3 lg:py-4 lg:inline-block whitespace-nowrap ml-0">
              Porovnat nemovitosti
            </router-link>
          </div>

        </div>
        <div class="flex items-center lg:order-2 lg:relative">
          <button @click.prevent="$emit('open-ai-modal')" type="button" class="sm:inline-flex hidden lg:visible items-center justify-center text-white bg-sky-500 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 font-medium rounded-md text-xs px-3 py-1.5 mr-2">
            <font-awesome-icon icon="brain" class="mr-2"/>
            Pracovat s AI </button>
          <!-- Notifications -->
          <button v-if="notificationsAreOff" @click.prevent="toggleDropdown('notification')" type="button" data-dropdown-toggle="notification-dropdown" class="p-2 mr-1 text-gray-500 rounded-lg hover:text-gray-900 hover:bg-gray-100">
            <span class="sr-only">View notifications</span>
            <!-- Bell icon -->
            <svg class="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 14 20"><path d="M12.133 10.632v-1.8A5.406 5.406 0 0 0 7.979 3.57.946.946 0 0 0 8 3.464V1.1a1 1 0 0 0-2 0v2.364a.946.946 0 0 0 .021.106 5.406 5.406 0 0 0-4.154 5.262v1.8C1.867 13.018 0 13.614 0 14.807 0 15.4 0 16 .538 16h12.924C14 16 14 15.4 14 14.807c0-1.193-1.867-1.789-1.867-4.175ZM3.823 17a3.453 3.453 0 0 0 6.354 0H3.823Z"/></svg>
          </button>
          <!-- Dropdown menu -->
          <div v-if="dropdowns.notification && notificationsAreOff" ref="notificationDropdown" class="top-full lg:my-2.5 absolute right-0 w-full sm:w-80 z-50 bg-white rounded-lg shadow-lg">
            <div class="block py-2 px-4 text-base font-medium text-center text-gray-700 bg-gray-50">
              Notifications
            </div>
            <div>
              <a href="#" class="flex py-3 px-4 border-b hover:bg-gray-100">
                <div class="flex-shrink-0">
                  <img class="w-11 h-11 rounded-full" src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/avatars/bonnie-green.png" alt="Bonnie Green avatar">
                  <div class="flex absolute justify-center items-center ml-6 -mt-5 w-5 h-5 rounded-full border border-white bg-primary-700">
                    <svg class="w-2 h-2 text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 18 18"><path d="M15.977.783A1 1 0 0 0 15 0H3a1 1 0 0 0-.977.783L.2 9h4.239a2.99 2.99 0 0 1 2.742 1.8 1.977 1.977 0 0 0 3.638 0A2.99 2.99 0 0 1 13.561 9H17.8L15.977.783ZM6 2h6a1 1 0 1 1 0 2H6a1 1 0 0 1 0-2Zm7 5H5a1 1 0 0 1 0-2h8a1 1 0 1 1 0 2Z"/><path d="M1 18h16a1 1 0 0 0 1-1v-6h-4.439a.99.99 0 0 0-.908.6 3.978 3.978 0 0 1-7.306 0 .99.99 0 0 0-.908-.6H0v6a1 1 0 0 0 1 1Z"/></svg>
                  </div>
                </div>
                <div class="pl-3 w-full">
                  <div class="text-gray-500 font-normal text-sm mb-1.5">New message from <span class="font-semibold text-gray-900">Bonnie Green</span>: "Hey, what's up? All set for the presentation?"</div>
                  <div class="text-xs font-medium text-primary-700">a few moments ago</div>
                </div>
              </a>
              <a href="#" class="flex py-3 px-4 border-b hover:bg-gray-100">
                <div class="flex-shrink-0">
                  <img class="w-11 h-11 rounded-full" src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/avatars/jese-leos.png" alt="Jese Leos avatar">
                  <div class="flex absolute justify-center items-center ml-6 -mt-5 w-5 h-5 bg-gray-900 rounded-full border border-white">
                    <svg class="w-2 h-2 text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 18"><path d="M6.5 9a4.5 4.5 0 1 0 0-9 4.5 4.5 0 0 0 0 9ZM8 10H5a5.006 5.006 0 0 0-5 5v2a1 1 0 0 0 1 1h11a1 1 0 0 0 1-1v-2a5.006 5.006 0 0 0-5-5Zm11-3h-2V5a1 1 0 0 0-2 0v2h-2a1 1 0 1 0 0 2h2v2a1 1 0 0 0 2 0V9h2a1 1 0 1 0 0-2Z"/></svg>
                  </div>
                </div>
                <div class="pl-3 w-full">
                  <div class="text-gray-500 font-normal text-sm mb-1.5"><span class="font-semibold text-gray-900">Jese leos</span> and <span class="font-medium text-gray-900">5 others</span> started following you.</div>
                  <div class="text-xs font-medium text-primary-700">10 minutes ago</div>
                </div>
              </a>
              <a href="#" class="flex py-3 px-4 border-b hover:bg-gray-100">
                <div class="flex-shrink-0">
                  <img class="w-11 h-11 rounded-full" src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/avatars/joseph-mcfall.png" alt="Joseph McFall avatar">
                  <div class="flex absolute justify-center items-center ml-6 -mt-5 w-5 h-5 bg-red-600 rounded-full border border-white">
                    <svg class="w-2 h-2 text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 18"> <path d="M17.947 2.053a5.209 5.209 0 0 0-3.793-1.53A6.414 6.414 0 0 0 10 2.311 6.482 6.482 0 0 0 5.824.5a5.2 5.2 0 0 0-3.8 1.521c-1.915 1.916-2.315 5.392.625 8.333l7 7a.5.5 0 0 0 .708 0l7-7a6.6 6.6 0 0 0 2.123-4.508 5.179 5.179 0 0 0-1.533-3.793Z"/> </svg>
                  </div>
                </div>
                <div class="pl-3 w-full">
                  <div class="text-gray-500 font-normal text-sm mb-1.5"><span class="font-semibold text-gray-900">Joseph Mcfall</span> and <span class="font-medium text-gray-900">141 others</span> love your story. See it and view more stories.</div>
                  <div class="text-xs font-medium text-primary-700">44 minutes ago</div>
                </div>
              </a>
              <a href="#" class="flex py-3 px-4 border-b hover:bg-gray-100">
                <div class="flex-shrink-0">
                  <img class="w-11 h-11 rounded-full" src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/avatars/roberta-casas.png" alt="Roberta Casas image">
                  <div class="flex absolute justify-center items-center ml-6 -mt-5 w-5 h-5 bg-green-400 rounded-full border border-white">
                    <svg class="w-2 h-2 text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 18"><path d="M18 0H2a2 2 0 0 0-2 2v9a2 2 0 0 0 2 2h2v4a1 1 0 0 0 1.707.707L10.414 13H18a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2Zm-5 4h2a1 1 0 1 1 0 2h-2a1 1 0 1 1 0-2ZM5 4h5a1 1 0 1 1 0 2H5a1 1 0 0 1 0-2Zm2 5H5a1 1 0 0 1 0-2h2a1 1 0 0 1 0 2Zm9 0h-6a1 1 0 0 1 0-2h6a1 1 0 1 1 0 2Z"/></svg>
                  </div>
                </div>
                <div class="pl-3 w-full">
                  <div class="text-gray-500 font-normal text-sm mb-1.5"><span class="font-semibold text-gray-900">Leslie Livingston</span> mentioned you in a comment: <span class="font-medium text-primary-700">@bonnie.green</span> what do you say?</div>
                  <div class="text-xs font-medium text-primary-700">1 hour ago</div>
                </div>
              </a>
              <a href="#" class="flex py-3 px-4 hover:bg-gray-100">
                <div class="flex-shrink-0">
                  <img class="w-11 h-11 rounded-full" src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/avatars/robert-brown.png" alt="Robert image">
                  <div class="flex absolute justify-center items-center ml-6 -mt-5 w-5 h-5 bg-purple-500 rounded-full border border-white">
                    <svg class="w-2 h-2 text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 14"><path d="M11 0H2a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h9a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2Zm8.585 1.189a.994.994 0 0 0-.9-.138l-2.965.983a1 1 0 0 0-.685.949v8a1 1 0 0 0 .675.946l2.965 1.02a1.013 1.013 0 0 0 1.032-.242A1 1 0 0 0 20 12V2a1 1 0 0 0-.415-.811Z"/></svg>
                  </div>
                </div>
                <div class="pl-3 w-full">
                  <div class="text-gray-500 font-normal text-sm mb-1.5"><span class="font-semibold text-gray-900">Robert Brown</span> posted a new video: Glassmorphism - learn how to implement the new design trend.</div>
                  <div class="text-xs font-medium text-primary-700">3 hours ago</div>
                </div>
              </a>
            </div>
            <a href="#" class="block py-2 text-base font-medium text-center text-gray-900 bg-gray-50 hover:bg-gray-100">
              <div class="inline-flex items-center ">
                <svg aria-hidden="true" class="mr-2 w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10 12a2 2 0 100-4 2 2 0 000 4z"></path><path fill-rule="evenodd" d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z" clip-rule="evenodd"></path></svg>
                View all
              </div>
            </a>
          </div>
          <!-- Apps -->
          <button @click.prevent="toggleDropdown('apps')" type="button" data-dropdown-toggle="apps-dropdown" class="p-2 text-gray-500 rounded-lg hover:text-gray-900 hover:bg-gray-100">
            <span class="sr-only">View notifications</span>
            <!-- Icon -->
            <svg class="w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 18 18">
              <path d="M6.143 0H1.857A1.857 1.857 0 0 0 0 1.857v4.286C0 7.169.831 8 1.857 8h4.286A1.857 1.857 0 0 0 8 6.143V1.857A1.857 1.857 0 0 0 6.143 0Zm10 0h-4.286A1.857 1.857 0 0 0 10 1.857v4.286C10 7.169 10.831 8 11.857 8h4.286A1.857 1.857 0 0 0 18 6.143V1.857A1.857 1.857 0 0 0 16.143 0Zm-10 10H1.857A1.857 1.857 0 0 0 0 11.857v4.286C0 17.169.831 18 1.857 18h4.286A1.857 1.857 0 0 0 8 16.143v-4.286A1.857 1.857 0 0 0 6.143 10Zm10 0h-4.286A1.857 1.857 0 0 0 10 11.857v4.286c0 1.026.831 1.857 1.857 1.857h4.286A1.857 1.857 0 0 0 18 16.143v-4.286A1.857 1.857 0 0 0 16.143 10Z"/>
            </svg>
          </button>
          <!-- Dropdown menu -->
          <div v-if="dropdowns.apps" ref="appsDropdown" class="absolute lg:my-2.5 top-full right-0 min-width-400 w-full overflow-hidden z-50 my-4 mt-0 max-w-sm text-base list-none bg-white rounded divide-y divide-gray-100 shadow-lg" id="apps-dropdown">
            <div class="block py-2 px-4 text-base font-medium text-center text-gray-700 bg-gray-50">
              Kalkulačky od Rilo
            </div>
            <div class="grid grid-cols-3 gap-4 p-4">
              <router-link :to="{name: 'RentCalculator'}" class="block p-4 text-center rounded-lg hover:bg-gray-100">
                <font-awesome-icon icon="calculator" />
                <div class="text-sm font-medium text-gray-900 ">Rozmezí nájemného</div>
              </router-link>
              <router-link :to="{name: 'TimeToRentCalculator'}" class="block p-4 text-center rounded-lg hover:bg-gray-100">
                <font-awesome-icon icon="clock" />
                <div class="text-sm font-medium text-gray-900 ">Doba do pronájmu</div>
              </router-link>
              <router-link :to="{name: 'FairnessPrice'}" class="block p-4 text-center rounded-lg hover:bg-gray-100">
                <font-awesome-icon icon="house-flag" />
                <div class="text-sm font-medium text-gray-900 ">Férová cena bytu</div>
              </router-link>
            </div>
          </div>
          <button v-if="user" @click="toggleDropdown('user')" type="button" class="flex mx-3 text-sm bg-gray-800 rounded-full md:mr-0 focus:ring-4 focus:ring-gray-300 " id="user-menu-button" aria-expanded="false" data-dropdown-toggle="dropdown">
            <span class="sr-only">Open user menu</span>
            <img class="w-8 h-8 rounded-full" :src="'/images/avatars/' + user.image_name + '.svg'" alt="user photo">
          </button>
          <!-- Dropdown menu -->
          <div v-if="dropdowns.user" ref="userMenuDropdown" class="absolute lg:my-2.5 top-full z-50 right-0 w-56 text-base list-none bg-white rounded divide-y divide-gray-100 shadow  " id="dropdown">
            <div class="py-3 px-4">
              <span class="block text-sm font-semibold text-gray-900" v-if="user">
              {{user.name}}
              </span>
              <span class="block text-sm text-gray-500 truncate" v-if="user">{{ user.email }}</span>
              <span class="block text-sm font-semibold text-gray-500 truncate mt-2 pt-1" v-if="user">{{ credits }} kreditů</span>
            </div>
            <ul class="py-1 text-gray-500 " aria-labelledby="dropdown">
              <li>
                <router-link :to="{name: 'SettingPage'}" class="block py-2 px-4 text-sm hover:bg-gray-100">Nastavení</router-link>
              </li>
            </ul>
            <ul class="py-1 text-gray-500 " aria-labelledby="dropdown">
              <li>
                <router-link :to="{name: 'CreditsSetting'}" class="flex items-center py-2 px-4 text-sm hover:bg-gray-100  ">
                  <font-awesome-icon icon="coins" class="mr-2"/>
                  Dobít kredity
                </router-link>
              </li>
              <li>
                <router-link :to="{name: 'LimitsSetting'}" class="flex items-center py-2 px-4 text-sm hover:bg-gray-100  ">
                 <font-awesome-icon icon="building-circle-check" class="mr-2"/>
                  Kritéria nemovitostí
                </router-link>
              </li>
              <li>
                <router-link :to="{name: 'TemplateSetting'}" class="flex items-center py-2 px-4 text-sm hover:bg-gray-100  ">
                  <font-awesome-icon icon="file-lines" class="mr-2"/>
                  Šablony nemovitostí
                </router-link>
              </li>
              <li>
                <router-link :to="{name: 'SubscriptionSetting'}" class="flex items-center py-2 px-4 text-sm hover:bg-gray-100  ">
                  <font-awesome-icon icon="wallet" class="mr-2"/>
                  Předplatné
                </router-link>
              </li>
            </ul>
            <ul class="py-1 text-gray-500" aria-labelledby="dropdown">
              <li>
                <a @click.prevent="logout()" href="#" class="block py-2 px-4 text-sm hover:bg-gray-100">Odhlásit se</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </nav>
  </header>
</template>

<script>
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import {useUserStore} from "@/pinia/userStore";
import Cookies from "js-cookie";

export default {
  name: "MenuTemplate",
  components: {FontAwesomeIcon},

  data() {
    return {
      dropdowns: {
        notification: false,
        apps: false,
        user: false,
      },
      notificationsAreOff: false,
      isMenuOpen: false,
    }
  },

  watch: {
    $route() {
      this.dropdowns.notification = false;
      this.dropdowns.apps = false;
      this.dropdowns.user = false;
      this.isMenuOpen = false;
    },
  },

  async mounted() {
    document.addEventListener('click', this.handleClickOutside);
  },

  beforeUnmount() {
    document.removeEventListener('click', this.handleClickOutside);
  },

  computed:{
    user(){
      const userStore = useUserStore();
      return userStore.user;
    },
    credits(){
      const userStore = useUserStore();
      return userStore.credits;
    }
  },

  methods:{
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen;
    },

    logout(){
        Cookies.remove('authToken');
        window.location.reload();
    },

    toggleDropdown(dropdown) {
      if (this.dropdowns[dropdown]) {
        this.dropdowns[dropdown] = false;
      } else {
        Object.keys(this.dropdowns).forEach(key => {
          this.dropdowns[key] = false;
        });
        this.dropdowns[dropdown] = true;
      }
    },

    closeAllDropdowns() {
      Object.keys(this.dropdowns).forEach(key => {
        this.dropdowns[key] = false;
      });
    },

    handleClickOutside(event) {
      const clickedInsideDropdown = Object.keys(this.$refs).some(ref => {
        return this.$refs[ref] && this.$refs[ref].contains(event.target);
      });

      if (!clickedInsideDropdown) {
        const clickedOnDropdownToggle = event.target.closest('button[data-dropdown-toggle]');
        if (!clickedOnDropdownToggle) {
          this.closeAllDropdowns();
        }
      }
    }

  }
}
</script>

<style scoped>
  .min-width-400{
    @media screen and (min-width: 767px){
        min-width: 400px;
    }
  }

  .active-link{
    border-color: #0ea5e9 !important;
  }

  .non-active-link{
    border-bottom: 4px solid transparent;
  }
</style>
